<template>
      <section id="feedback" class="container-fluid ">
    <div class="row pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-5 pt-5 mt-5 ">
      <div class="col-11 px-0 mx-auto text-center mb-4">
        <SecTitle title="قالوا عن إي كارت"/>
      </div>
   
    </div>
    <div class="row">
      <div class="col-xx-11 col-10 max-width-v mx-auto pe-0 text-center position-relative mt--5">
        <img src="../../assets/img/feedback.svg" class="z-1 w-100 b-0 l-0" alt="شاشة لابتوب مع شخصيات إي كارت">

          <div class="d-flex align-items-center w-50 m-auto position-absolute t-0 b-0 l-0 r-0 z-2">
                <FeedbackSwiper/>
            </div>
          </div>
        </div>
     
  </section>
</template>
<script>
import FeedbackSwiper from '../items/feedback/feedbackSwiper.vue';
import SecTitle from '../items/shared/SecTitle.vue';
export default {
    name: 'Feedback',
    components: {
    SecTitle,
    FeedbackSwiper
}
}
</script>
<style scoped>
.mt--5 {
  margin-top: -5%;
}

</style>