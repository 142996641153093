<template>
<!-- option icons --> 
    <div class="d-flex">
    <a href="" target="_blank" class="d-flex my-auto">
        <img src="../../../assets/img/services_contact_icons/telegram.svg" class="ms-xxl-3 ms-2 w-sd-30px" alt="تيلغرام">
    </a>

    <a href="" target="_blank" class="d-flex my-auto">
        <img src="../../../assets/img/services_contact_icons/messanger.svg" class="ms-xxl-3 ms-2 w-sd-30px" alt="مسنجر">
    </a>
    <a href="" target="_blank" class="d-flex my-auto">
        <img src="../../../assets/img/services_contact_icons/instagram.svg" class="ms-xxl-3 ms-2 w-sd-30px" alt="انستغرام">
    </a>
    <a href="" target="_blank" class="d-flex my-auto">
        <img src="../../../assets/img/services_contact_icons/dribble.svg" class="ms-xxl-3 ms-2 w-sd-30px" alt="دريبل">
    </a>
    </div>
</template>
<script>
export default{
    name:'OptionsIcons'
}
</script>
<style scoped>
@media (max-width: 1600px) {
    .w-sd-30px {
    width: 30px;
  }

}
</style>