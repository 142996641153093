<template>
  <section  class="container-fluid">
    <div
      class="row pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-5 pt-5 mt-5"
      
    >
      <div class="col-xl-11 col-11 col-md-10 px-0 mx-auto text-center">
        <SecTitle title="آلية عمل إي كارت" />
      </div>
    </div>

    <div class="row position-relative mt-5 " id="mechanism">
      <div
        class="col-xl-10 col-10 pt-xxl-5 mt-xxl-5 pt-lg-4 mt-lg-5 pt-md-4 mt-md-5 mx-auto px-0 text-center position-relative"
        v-if="tablet || !mobile"
      >
        <img
          :src="require(`@/assets/img/${getImgSrc}`)"
          alt="إلية عمل إي كارت"
          class="w-sd-80 position-relative z-4"
          data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-anchor="#mechanism"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-duration="800"
        />
        <img
          :src="require(`@/assets/img/how-circle.svg`)"
          alt=""
          data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-delay="800"
            data-aos-duration="400"
          class="position-absolute b-0 l-0 r-0 z-1 mx-auto mt-auto"
        />
        <div
          class="position-absolute b-0 d-flex align-items-end justify-content-between w-100"
        >
          <img
            data-aos="fade-up"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-duration="400"
            data-aos-delay="1200"
            data-aos-anchor-placement="top-center"
            data-aos-anchor="#mechanism"
            :src="require(`@/assets/img/how-1.svg`)"
            alt=""
            class="position-relative b-0 r-0 z-2 mx-auto w-15"
          />
          <img
          data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-mirror="false"
            data-aos-delay="1600"
            data-aos-duration="400"
            data-aos-once="true"
            data-aos-anchor="#mechanism"

            :src="require(`@/assets/img/how-2.svg`)"
            alt=""
            class="position-relative b-0 z-2 mx-auto w-15"
          />
          <img
          data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-mirror="false"
            data-aos-duration="400"
            data-aos-once="true"
            data-aos-anchor="#mechanism"
            :src="require(`@/assets/img/how-3.svg`)"
            alt=""
            class="position-relative b-0 z-2 mx-auto w-15 delay-32"
          />
          <img
          data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-mirror="false"
            data-aos-duration="400"
            data-aos-once="true"
            data-aos-anchor="#mechanism"
            :src="require(`@/assets/img/how-4.svg`)"
            alt=""
            class="position-relative b-0 l-0 z-2 mx-auto w-15 delay-4"
          />
        </div>
        
      </div>
      <div
        class="col-xl-11 col-11 col-md-10 px-0 mx-auto text-center position-relative mt-5 pt-1"
        v-else
      >
        <MechanismSwiper />
      </div>
      <img
        src="../../assets/img/smile.svg"
        alt="إي كارت"
        class="smile position-absolute b-0 w-100 r-0 px-0"
        v-if="mobile && !tablet"
      />
    </div>
  </section>
</template>
<script>
import responsiveMixin from "@/mixins/responsiveMixin";
import SecTitle from "../items/shared/SecTitle.vue";
import MechanismSwiper from "../items/shared/mechanismSwiper.vue";
import How from "../items/svg/how.vue";
export default {
  name: "Mechanism",
  components: {
    SecTitle,
    MechanismSwiper,
    How,
  },
  mixins: [responsiveMixin],
  computed: {
    getImgSrc() {
      if (this.tablet) {
        return "how-t.svg";
      }
      return "how-d.svg";
    },
  },
};
</script>
<style>
.delay-4{
  transition-delay: 2.4s!important;
}
.delay-32{
  transition-delay: 2s!important;
}
.w-15 {
  width: 15%;
}
.swiper-pagination {
  position: relative !important;
  margin-top: 4%;
}
.m-swiper .swiper-pagination-bullet {
  background-color: white !important;
}
.m-swiper .swiper-pagination-bullet-active {
  background-color: #e95c29 !important;
}
.h-131px {
  height: 131px;
}
.m-swiper .swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
}
.smile {
  top: 39%;
}
</style>