<template>
          <swiper
    :spaceBetween="30"
    :centeredSlides="true"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
    }"
   
    :modules="modules"
    :class="'myswiper '+[styleClass]"
  >
  
      <swiper-slide class="  d-flex flex-column justify-content-center align-items-center">
        <div class="feedback-card">
                    <p class=" basic-black f-40 dark-blue mb-2">
                      فندق المدينة
                    </p>
                    <p class="m-0 basic-bold f-30 dark-blue line-4  line-height-17 mb-2">
                      من خلال تجربتنا مع إي كارت كلمة حق نقولها لقد ساعدني إي كارت في تنظيم الحجوزات في الفندق وبمكان
                      واحد وأتاح للعملاء سهولة الحجز والتواصل ووفر الوقت لكلانا وقدم لي تقارير يومية شاملة عن الحجوزات
                    </p>
                  </div>
      </swiper-slide>
      <swiper-slide class="d-flex flex-column justify-content-center align-items-center">
        <div class="feedback-card">
            <p class="basic-black f-40 dark-blue mb-2">
                جهاد الراشد
            </p>
            <p class="m-0 basic-bold f-30 dark-blue line-4 line-height-17 mb-2">
                إي كارت ساعدني في تنظيم الحجوزات في العيادة وبمكان واحد وأتاح للعملاء سهولة الحجز والتواصل ووفر
                الوقت لكلانا وقدم لي تقارير يومية شاملة عن المواعيد </p>
            </div>
      </swiper-slide>
     
    </swiper>    
  
  </template>
 
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination } from 'swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props:{
    styleClass:{
      type:String,
      default:'h-40'
    }
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
};
</script>
<style scoped>

.h-80{
  height: 77%;
}


.myswiper{
  direction: ltr!important;
} 
  .h-40{
    height: 45%;
  }
</style>