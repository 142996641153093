<template>
  <swiper
    :spaceBetween="30"
    :centeredSlides="true"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
    }"
    :modules="modules"
    class="m-swiper position-unset"
  >
    <swiper-slide class="d-flex flex-column justify-content-center align-items-center">
        <img src="../../../assets/img/m1.svg" class="h-131px" alt="الخطوة الأولى">
        <p class="white f-28 basic-light line-height-17 mb-0 mt-3">اختر المنصة/المنصة<br>التي ترغب بالبيع عليها</p>
    </swiper-slide>
    <swiper-slide class="d-flex flex-column justify-content-center align-items-center">
        <img src="../../../assets/img/m2.svg" class="h-131px" alt="الخطوة الثانية">
        <p class="white f-28 basic-light line-height-17 mb-0 mt-3">ابدأ البيع<br>مباشرة</p>
    </swiper-slide>
    <swiper-slide class="d-flex flex-column justify-content-center align-items-center">
        <img src="../../../assets/img/m3.svg" class="h-131px" alt="الخطوة الثالثة">      
        <p class="white f-28 basic-light line-height-17 mb-0 mt-3">حساب نشاطك التجاري<br>فعل إي كارت على</p>
    </swiper-slide>
    <swiper-slide class="d-flex flex-column justify-content-center align-items-center">
        <img src="../../../assets/img/m4.svg" class="h-131px" alt="الخطوة الرابعة">
        <p class="white f-28 basic-light line-height-17 mb-0 mt-3">عرف الفئات و<br>المنتجات الخاصة بك</p>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    styleClass: {
      type: String,
    },
    name:{
        type:String,
        default:'mySwiper'
    }
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
};
</script>
<style scoped>

.position-unset{
  position: unset;
}

</style>