<template>
  <div class="row" >
    <div
      class="col-lg-7 col-12 col-md-10 mx-lg-0 mx-auto px-0 pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-4 pt-1 mt-5 position-relative"
      data-aos="fade-left"
      data-aos-anchor="#blog"
     data-aos-anchor-placement="center-center"
     data-aos-mirror="false"
    data-aos-once="true"
    data-aos-duration="800"
      >
      <img
        class="w-100"
        :src="require(`@/assets/img/${imgSrc}`)"
        :alt="title"
      />
      <a class="text-decoration-none" href="">
        <p
          class="position-absolute t-30 l-16 text-center white w-50 f-45 basic-black z-1"

        >
          {{ title }}
        </p>
      </a>
    </div>
    <div
      class="col-lg-4 col-md-10 mx-auto col-11 px-0"
      :class="mobile ? 'mt-3' : 'pt-3 mt-5 mb-4'"
      data-aos="fade-up"
      data-aos-anchor="#blog"
     data-aos-anchor-placement="center-center"
     data-aos-mirror="false"
    data-aos-once="true"
    data-aos-duration="800"
    >
      <div class="d-flex mt-22 flex-column" >
        <a class="text-decoration-none" href="">
          <p class="white f-30 basic-light mb-0 line-4 w-85">{{ text }}...</p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import responsiveMixin from "@/mixins/responsiveMixin";

export default {
  name: "articleThumb",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: "reason1.svg",
    },
  },
  mixins: [responsiveMixin],
};
</script>
<style scoped>
.text-decoration-none {
  text-decoration: none;
}
.w-85{
  width: 85%;
}
.f-45 {
  font-size: 27px;
}

@media (max-width: 1199.98px) {
  .f-45 {
    font-size: 33px;
  }
}

@media (max-width: 570.98px) {
  .f-45 {
    font-size: 20px;
  }
}

@media (max-width: 400.98px) {
  .f-45 {
    font-size: 18px;
  }
}
@media (min-width: 2200px) {
  .l-16 {
    left: 20%;
  }
}
</style>