const metaMixin = {
    data(){
        return {title : 'اي كارت'};
    },
    created(){
        this.meta();
    },
      methods: {
        meta() {
           document.title =  this.title;
           console.log( this.title)
        }
      }
  };
  export default metaMixin