<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501.204 833.946">
        <g id="Group_65919" data-name="Group 65919" transform="translate(-1331 -4540)">
            <rect id="Rectangle_12085" data-name="Rectangle 12085" width="9.441" height="281.035"
                transform="translate(1777.559 4601.569)" fill="#4a576f" />
            <path id="Path_10775" data-name="Path 10775"
                d="M380.006,166.664c14.029,10.1,58.918,50.746,61.762,57.509,3.385,8.054-14.007,36.7-21.2,36.067-18.61-1.625-51.187-60.2-61.632-87.035C353.826,160.092,356.512,149.754,380.006,166.664Z"
                transform="translate(1204.46 4605.141)" fill="#d3766a" />
            <g id="Group_16338" data-name="Group 16338" transform="translate(1581.811 4651.84)">
                <g id="Group_16326" data-name="Group 16326" transform="translate(27.457 123.204)">
                    <path id="Path_10776" data-name="Path 10776"
                        d="M396.85,160.992c4.029-4.41,35.912,19.319,35.278,28.088C429.8,221.334,419.9,248.722,414.017,254.4a16.152,16.152,0,0,1-25.583-5.166C381.376,234.492,381.239,178.088,396.85,160.992Z"
                        transform="translate(-383.622 -160.451)" fill="#d3766a" />
                    <g id="Group_16325" data-name="Group 16325" transform="translate(0 27.138)">
                        <path id="Path_10777" data-name="Path 10777"
                            d="M385.085,219.109c-3.589-17.876,1.048-43.02,1.046-43.063-.007-.344.465-.467.472-.274.119,3.566-2.306,29.961-1.037,43.195C385.578,219.09,385.11,219.23,385.085,219.109Z"
                            transform="translate(-383.722 -175.681)" fill="#263238" />
                    </g>
                </g>
                <g id="Group_16337" data-name="Group 16337">
                    <path id="Path_10778" data-name="Path 10778"
                        d="M436.979,175.692c-.538,9.35-9.114,28.036-12.919,31.75S388,197.627,386.593,192.36s2.839-36.6,9.228-43.7S437.535,166.008,436.979,175.692Z"
                        transform="translate(-354.233 -47.468)" fill="#d3766a" />
                    <g id="Group_16327" data-name="Group 16327" transform="translate(10.382 97.967)">
                        <path id="Path_10779" data-name="Path 10779"
                            d="M395.9,187.749s-3.722-11.6-10.223-20.134c-5.467-7.177-14.4-17.217-10.647-20.3,7.7-6.331,29.955,18.715,29.955,18.715s8.045,12.626.975,19.574A9.846,9.846,0,0,1,395.9,187.749Z"
                            transform="translate(-374.14 -146.288)" fill="#d3766a" />
                    </g>
                    <g id="Group_16334" data-name="Group 16334" transform="translate(0 10)">
                        <g id="Group_16329" data-name="Group 16329" transform="translate(19.73 83.854)">
                            <g id="Group_16328" data-name="Group 16328" transform="translate(4.933 7.388)">
                                <path id="Path_10780" data-name="Path 10780"
                                    d="M399.259,171.8l8.336-5.142L392.7,142.515l-10.549,6.507Z"
                                    transform="translate(-382.154 -142.514)" fill="#ffc727" />
                                <path id="Path_10781" data-name="Path 10781"
                                    d="M382.156,149.022l6.522,8.69L399.262,171.8l8.336-5.143L392.7,142.514Z"
                                    transform="translate(-382.153 -142.514)" fill="#d14816" />
                                <path id="Path_10782" data-name="Path 10782"
                                    d="M382.156,149.022l6.522,8.69,9.708-5.989-5.681-9.208Z"
                                    transform="translate(-382.153 -142.514)" fill="#d14816" />
                            </g>
                            <rect id="Rectangle_8806" data-name="Rectangle 8806" width="15.421" height="11.714"
                                transform="matrix(-0.851, 0.525, -0.525, -0.851, 19.275, 9.97)" fill="#d14816" />
                        </g>
                        <g id="Group_16332" data-name="Group 16332" transform="translate(0 12.844)">
                            <g id="Group_16330" data-name="Group 16330" transform="translate(0 59.481)">
                                <path id="Path_10783" data-name="Path 10783"
                                    d="M377.009,153.3l10.823-5.27L377.88,131.9l-9.566,7.308Z"
                                    transform="translate(-368.314 -131.898)" fill="#d14816" />
                            </g>
                            <path id="Path_10784" data-name="Path 10784"
                                d="M375.519,151.009l14.687,23.809,18.371-8.5,48.8-9.9L422.105,99.233l-30.744,39.174Z"
                                transform="translate(-362.68 -97.957)" fill="#d14816" />
                            <path id="Path_10785" data-name="Path 10785"
                                d="M411.617,133.51c9.79,15.868,21.859,26.179,26.958,23.033s1.3-18.56-8.487-34.426-21.858-26.181-26.96-23.035S401.829,117.642,411.617,133.51Z"
                                transform="translate(-342.864 -98.517)" fill="#ec693c" />
                            <path id="Path_10786" data-name="Path 10786"
                                d="M412.279,130.558c8.69,14.088,19.12,23.418,23.295,20.841s.517-16.083-8.172-30.169-19.12-23.416-23.3-20.841S403.59,116.472,412.279,130.558Z"
                                transform="translate(-341.678 -97.388)" fill="#d14816" />
                            <path id="Path_10787" data-name="Path 10787"
                                d="M410.188,121.4c1.156,1.876,2.347,3.665,3.548,5.351a6.7,6.7,0,0,0,6.566-10.167l-1.663-2.7a6.7,6.7,0,0,0-12.01,1.249C407.692,117.191,408.886,119.292,410.188,121.4Z"
                                transform="translate(-338.354 -88.99)" fill="#ec693c" />
                            <g id="Group_16331" data-name="Group 16331" transform="translate(2.296 42.481)">
                                <path id="Path_10788" data-name="Path 10788"
                                    d="M386.418,163.749l20.931-10.192-19.246-31.2-18.5,14.132Z"
                                    transform="translate(-369.602 -122.357)" fill="#ffc727" />
                                <path id="Path_10789" data-name="Path 10789"
                                    d="M386.418,163.749l20.931-10.192-19.246-31.2-18.5,14.132Z"
                                    transform="translate(-369.602 -122.357)" fill="#d14816" />
                            </g>
                            <path id="Path_10790" data-name="Path 10790"
                                d="M401.594,102.358a21,21,0,0,0,.476,7.537,47.809,47.809,0,0,0,2.243,7.258c.435,1.19.976,2.338,1.477,3.5.563,1.133,1.092,2.284,1.684,3.405.608,1.108,1.176,2.242,1.819,3.332s1.267,2.192,1.935,3.266a83.119,83.119,0,0,0,9.07,12.165,43.19,43.19,0,0,0,5.592,5.144,17.988,17.988,0,0,0,6.751,3.364,18.574,18.574,0,0,1-6.534-3.646,43.178,43.178,0,0,1-5.4-5.237,88.066,88.066,0,0,1-8.868-12.174,90.126,90.126,0,0,1-7.015-13.33,47.894,47.894,0,0,1-2.414-7.129A21.885,21.885,0,0,1,401.594,102.358Z"
                                transform="translate(-342.429 -95.514)" opacity="0.2" />
                        </g>
                        <g id="Group_16333" data-name="Group 16333" transform="translate(70.724)">
                            <path id="Path_10791" data-name="Path 10791"
                                d="M416.753,94.359c-.877,1.52-1.677,3.076-2.463,4.636s-1.534,3.14-2.272,4.722c-.722,1.591-1.44,3.182-2.1,4.8l-.992,2.427c-.3.821-.634,1.63-.918,2.461.445-.756.846-1.532,1.271-2.3l1.194-2.334c.795-1.557,1.533-3.141,2.27-4.724.72-1.591,1.44-3.182,2.1-4.8S416.172,96.014,416.753,94.359Z"
                                transform="translate(-408.004 -88.924)" fill="#263238" />
                            <path id="Path_10792" data-name="Path 10792"
                                d="M418.426,125.169c1.962-.242,3.906-.572,5.846-.914s3.874-.727,5.8-1.121c1.926-.412,3.851-.827,5.764-1.3l2.869-.713c.952-.267,1.907-.506,2.853-.8-.982.114-1.955.278-2.931.415l-2.915.5c-1.942.333-3.874.725-5.8,1.121-1.924.413-3.851.829-5.764,1.3S420.32,124.606,418.426,125.169Z"
                                transform="translate(-399.855 -68.622)" fill="#263238" />
                            <path id="Path_10793" data-name="Path 10793"
                                d="M450.22,111.94c-2.771.857-5.513,1.794-8.248,2.744s-5.458,1.94-8.175,2.94c-2.71,1.019-5.42,2.039-8.111,3.115l-4.032,1.616c-1.335.567-2.676,1.108-4,1.7,1.39-.421,2.76-.889,4.139-1.331l4.111-1.411c2.739-.941,5.456-1.94,8.174-2.942,2.71-1.019,5.422-2.037,8.111-3.115S447.563,113.1,450.22,111.94Z"
                                transform="translate(-400.463 -75.178)" fill="#263238" />
                            <path id="Path_10794" data-name="Path 10794"
                                d="M429.761,91.309c-1.712,2.39-3.352,4.827-4.98,7.272s-3.222,4.913-4.8,7.386-3.134,4.968-4.649,7.486l-2.27,3.776c-.732,1.276-1.486,2.536-2.2,3.822.864-1.19,1.684-2.409,2.527-3.614l2.455-3.658c1.638-2.439,3.222-4.913,4.8-7.386,1.566-2.484,3.133-4.968,4.647-7.486S428.325,93.875,429.761,91.309Z"
                                transform="translate(-403.123 -89.309)" fill="#263238" />
                            <path id="Path_10795" data-name="Path 10795"
                                d="M414.729,122.687c2.956-1.673,5.864-3.419,8.765-5.175s5.768-3.571,8.653-5.353c2.851-1.837,5.72-3.646,8.555-5.51l4.241-2.808c1.4-.96,2.814-1.9,4.2-2.88-1.481.829-2.935,1.7-4.4,2.552l-4.36,2.621c-2.9,1.757-5.768,3.571-8.651,5.355-2.851,1.839-5.722,3.644-8.555,5.51S417.511,120.737,414.729,122.687Z"
                                transform="translate(-402.746 -83.761)" fill="#263238" />
                        </g>
                    </g>
                    <g id="Group_16336" data-name="Group 16336" transform="translate(32.753 85.835)">
                        <path id="Path_10796" data-name="Path 10796"
                            d="M387.762,167.732c2.32,4.385,6.16-.859,6.16-.859s-3.708,8.881.431,12.477c4,3.457,8.414-3.042,8.738-3.5-.193.472-3.389,8.721,1.144,11.907,4.713,3.307,8.026-3.977,8.238-4.448-.041.5-.479,7.151,3.514,8.788,6.864,2.81,8.453-14.161,13.234-17.794,0,0,7.083,7.4,7.334,7.569,0,0,1.842-16.114-3.419-22.288a5.177,5.177,0,0,0-6.354-1.311s1.169-5.132-2.32-7.751c-1.794-1.34-5.536.337-5.536.337s.764-4.146-2.484-6.285c-2.587-1.714-5.937.237-5.937.237s-1.114-4.323-4.326-5.216C399.7,137.778,382.8,158.336,387.762,167.732Z"
                            transform="translate(-386.562 -139.48)" fill="#d3766a" />
                        <g id="Group_16335" data-name="Group 16335" transform="translate(7.111 5.43)">
                            <path id="Path_10797" data-name="Path 10797"
                                d="M406.939,142.651a.07.07,0,0,0-.089-.109c-7.208,5.406-13.469,13.553-16.153,22.209-.087.283.335.551.492.257C395.66,156.614,400.3,149.522,406.939,142.651Z"
                                transform="translate(-390.685 -142.527)" fill="#263238" />
                            <path id="Path_10798" data-name="Path 10798"
                                d="M412.017,145.853c.073-.075-.05-.2-.139-.128-7.395,5.609-14.2,14.98-16.114,25.322-.066.355.23.738.337.335C397.9,164.6,406.006,152.008,412.017,145.853Z"
                                transform="translate(-386.722 -140.043)" fill="#263238" />
                            <path id="Path_10799" data-name="Path 10799"
                                d="M401.453,175.805c2.222-9.845,7.375-17.988,13.872-25.59.084-.1-.064-.209-.157-.144-4.193,2.851-7.828,8.129-10.31,12.475a26.628,26.628,0,0,0-3.751,13.182C401.105,175.914,401.4,176.016,401.453,175.805Z"
                                transform="translate(-382.537 -136.644)" fill="#263238" />
                        </g>
                        <path id="Path_10800" data-name="Path 10800"
                            d="M391.554,154.883c-.1.342.51.119.415.465-.4,1.447-2.042,10.365.823,11.869,2.3,1.2,5.5-1.058,6.4-2.407l2.242-3.395-.925,3.968a16.631,16.631,0,0,0-.406,5.376,5.949,5.949,0,0,0,2.128,4.376,3.4,3.4,0,0,0,4.28.027,10.28,10.28,0,0,0,3.008-4.187l1.891-4.432-.335,4.786a15.664,15.664,0,0,0,.223,3.984,6.457,6.457,0,0,0,1.575,3.368,3.063,3.063,0,0,0,3.136.773,6.785,6.785,0,0,0,2.787-2.568,53.281,53.281,0,0,0,3.853-7.414c.6-1.278,1.194-2.562,1.853-3.819a10.03,10.03,0,0,1,2.518-3.4,10.832,10.832,0,0,0-2.167,3.567c-.556,1.288-1.05,2.607-1.547,3.925a45.372,45.372,0,0,1-3.541,7.78,7.913,7.913,0,0,1-3.245,3.186,4.347,4.347,0,0,1-4.669-.962,7.887,7.887,0,0,1-2.067-4.125,17.109,17.109,0,0,1-.315-4.435l1.556.355a11.811,11.811,0,0,1-3.5,4.98,4.925,4.925,0,0,1-3.163,1.062,5.571,5.571,0,0,1-3.093-1.139,7.513,7.513,0,0,1-2.7-5.54A18.1,18.1,0,0,1,399.1,165l1.317.576c-1.192,1.677-4.5,4.59-8.3,3.177C387.445,166.177,390.814,156.592,391.554,154.883Z"
                            transform="translate(-384.354 -127.436)" fill="#263238" />
                        <path id="Path_10801" data-name="Path 10801"
                            d="M394.055,156.508a9.959,9.959,0,0,1-2.527,1.7,2.3,2.3,0,0,1-2.329-.157,4.7,4.7,0,0,1-1.377-2.281c-.3-.953-.57-1.969-.805-3.031a7.893,7.893,0,0,0-.251,3.272,4.631,4.631,0,0,0,1.614,3.161,3.308,3.308,0,0,0,1.887.584,3.448,3.448,0,0,0,1.773-.6A5.32,5.32,0,0,0,394.055,156.508Z"
                            transform="translate(-386.695 -129.115)" fill="#263238" />
                    </g>
                </g>
            </g>
            <g id="Group_16354" data-name="Group 16354" transform="translate(1460.709 4846.098)">
                <g id="Group_16346" data-name="Group 16346" transform="translate(0 0.017)">
                    <g id="Group_16341" data-name="Group 16341" transform="translate(0 239.22)">
                        <path id="Path_10802" data-name="Path 10802"
                            d="M378.613,522.151c-3.53-2.192-75.306-89.166-75.932-94.21-.1-.695,10.83-10.531,24.7-22.888.709-.631,52.637-50.878,52.637-50.878L424.6,407.959,375.874,457.67s12.584,45.579,12.689,51.2C388.656,514.458,382.132,524.357,378.613,522.151Z"
                            transform="translate(-298.53 -354.174)" fill="#d3766a" />
                        <path id="Path_10803" data-name="Path 10803"
                            d="M346.709,422.6c-.123-.28-.159-.454-.1-.522C346.606,422.077,346.652,422.264,346.709,422.6Z"
                            transform="translate(-264.205 -301.081)" fill="#ffc727" />
                        <path id="Path_10804" data-name="Path 10804"
                            d="M388.555,491.265c.089,5.6-6.433,15.485-9.943,13.275-3.528-2.192-75.3-89.166-75.926-94.208-.107-.7,10.816-10.531,24.7-22.879.214-.2,4.776-4.6,11.155-10.763L384.456,431.3l-8.589,8.767S388.448,485.635,388.555,491.265Z"
                            transform="translate(-298.527 -336.569)" fill="#ebebeb" />
                        <g id="Group_16340" data-name="Group 16340" transform="translate(0 44.822)">
                            <path id="Path_10805" data-name="Path 10805"
                                d="M380.416,501.574c-3.562-2.224-75.882-90.3-76.5-95.4-.269-1.933,11.951-13.22,27.106-26.432.16-.137.321-.273.479-.41l47.293,56.3-.984.77s12.6,46.094,12.7,51.778C390.583,493.827,383.967,503.813,380.416,501.574Z"
                                transform="translate(-297.569 -379.328)" fill="#e95c29" />
                            <g id="Group_16339" data-name="Group 16339" transform="translate(11.513 29.191)">
                                <path id="Path_10806" data-name="Path 10806"
                                    d="M380.742,484.965c-11.826-15.146-60.955-75.123-73.8-89.2-.105-.114-.187-.048-.094.078,11.393,15.267,61.008,74.843,73.647,89.319C380.767,485.471,380.992,485.285,380.742,484.965Z"
                                    transform="translate(-306.812 -395.71)" fill="#fff" />
                                <path id="Path_10807" data-name="Path 10807"
                                    d="M355.635,420.346c-3.706-6.737-10.47-13.147-18.3-14.195-.3-.041-.356.392-.143.533a106.213,106.213,0,0,1,17.684,14.244A.483.483,0,0,0,355.635,420.346Z"
                                    transform="translate(-283.159 -387.548)" fill="#fff" />
                                <path id="Path_10808" data-name="Path 10808"
                                    d="M356.152,423.884c-3.706-6.739-10.47-13.149-18.3-14.194-.3-.041-.356.392-.143.531a106.217,106.217,0,0,1,17.683,14.246A.483.483,0,0,0,356.152,423.884Z"
                                    transform="translate(-282.755 -384.782)" fill="#fff" />
                                <path id="Path_10809" data-name="Path 10809"
                                    d="M356.669,427.422c-3.706-6.739-10.47-13.149-18.3-14.194-.3-.041-.356.39-.141.531A106.218,106.218,0,0,1,355.912,428,.483.483,0,0,0,356.669,427.422Z"
                                    transform="translate(-282.351 -382.016)" fill="#fff" />
                            </g>
                            <path id="Path_10810" data-name="Path 10810"
                                d="M387.46,490.534c.688,2.076-4.888,6.082-6.245,5.292s-79.788-97.172-80.762-99.105,5.374-6.14,7.231-4.492S387.46,490.534,387.46,490.534Z"
                                transform="translate(-300.351 -369.534)" fill="#d14816" />
                            <path id="Path_10811" data-name="Path 10811"
                                d="M326.79,394.393c-2.049-9.007-16.051-5.836-14,3.186S328.845,403.415,326.79,394.393Z"
                                transform="translate(-290.784 -371.751)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_16345" data-name="Group 16345" transform="translate(41.061)">
                        <path id="Path_10812" data-name="Path 10812"
                            d="M399.5,404.846c-3.979,19.649-76.106,87.391-76.106,87.391l56.839,65.607S486.582,452.97,480.54,409.556c-5.255-37.764-61.792-189.629-61.792-189.629H353.52S403.412,385.525,399.5,404.846Z"
                            transform="translate(-323.395 -219.92)" fill="#37445d" />
                        <path id="Path_10813" data-name="Path 10813"
                            d="M399.5,404.846c-3.979,19.649-76.106,87.391-76.106,87.391l56.839,65.607S486.582,452.97,480.54,409.556c-5.255-37.764-61.792-189.629-61.792-189.629H353.52S403.412,385.525,399.5,404.846Z"
                            transform="translate(-323.395 -219.92)" fill="#37445d" />
                        <g id="Group_16344" data-name="Group 16344" transform="translate(17.553 6.806)">
                            <g id="Group_16343" data-name="Group 16343" transform="translate(0 261.236)">
                                <g id="Group_16342" data-name="Group 16342">
                                    <path id="Path_10814" data-name="Path 10814"
                                        d="M379,424.632c-7.552-9.4-5.251-6.809-12.91-16.126-3.745-4.558-28.4-34.494-32.639-38.132-.093-.08-.25.052-.187.159,2.842,4.813,28.01,34.312,31.844,38.8,7.835,9.166,5.684,6.449,13.623,15.527A.176.176,0,0,0,379,424.632Z"
                                        transform="translate(-333.246 -370.349)" fill="#37445d" />
                                </g>
                            </g>
                            <path id="Path_10815" data-name="Path 10815"
                                d="M398.6,392.283c-.934-4.905-1.994-9.79-3.033-14.674q-3.183-14.965-6.73-29.847-7.046-29.511-15.324-58.7c-5.561-19.521-11.438-39.006-18.207-58.145-.836-2.365-1.7-4.722-2.561-7.078a.15.15,0,0,0-.287.086c5.862,19.175,11.9,38.293,17.4,57.575q8.211,28.808,15.315,57.9c2.4,9.734,4.629,19.51,6.81,29.294q1.606,7.211,3.14,14.44c1.035,4.886,2.427,9.882,2.732,14.882.3,4.913-1.3,9.266-3.833,13.405-2.445,4-5.322,7.766-8.1,11.536-5.572,7.559-49.264,56.053-50.973,57.662-.135.127.069.315.2.194,7.126-6.4,42.772-44.49,48.492-52.035q4.247-5.6,8.3-11.342c2.534-3.575,5.078-7.226,6.479-11.42C399.927,401.519,399.474,396.868,398.6,392.283Z"
                                transform="translate(-331.95 -223.743)" fill="#37445d" />
                        </g>
                        <path id="Path_10816" data-name="Path 10816"
                            d="M394.241,219.923,382.4,375.336c-11.3-53.193-42.1-155.413-42.1-155.413Z"
                            transform="translate(-310.172 -219.923)" fill="#37445d" />
                    </g>
                </g>
                <g id="Group_16353" data-name="Group 16353" transform="translate(28.688)">
                    <g id="Group_16349" data-name="Group 16349" transform="translate(69.018 315.594)">
                        <path id="Path_10817" data-name="Path 10817"
                            d="M492.015,509.055c-3.934,1.338-116.7,1.639-121-1.078-.6-.365-1.269-15.052-2.019-33.615-.039-.948-13.815-77-13.815-77l69.857-.337,15.394,73.237s43.214,19.191,47.628,22.676C492.439,496.412,495.951,507.735,492.015,509.055Z"
                            transform="translate(-355.184 -397.026)" fill="#d3766a" />
                        <path id="Path_10818" data-name="Path 10818"
                            d="M487.082,481.543c-3.938,1.336-116.7,1.639-120.99-1.087-.606-.356-1.283-15.039-2.031-33.606-.018-.267-1.051-6.183-2.566-14.629h71.792l2.21,10.531s43.211,19.191,47.63,22.666C487.51,468.891,491.02,480.224,487.082,481.543Z"
                            transform="translate(-350.249 -369.508)" fill="#ebebeb" />
                        <path id="Path_10819" data-name="Path 10819"
                            d="M412.888,442.415q-.441-.123-.47-.251S412.592,442.246,412.888,442.415Z"
                            transform="translate(-310.433 -361.733)" fill="#ffc727" />
                        <g id="Group_16348" data-name="Group 16348" transform="translate(10.182 70.7)">
                            <path id="Path_10820" data-name="Path 10820"
                                d="M487.283,477.533c-3.986,1.324-117.95.8-122.273-1.978-1.661-1.026-2.552-17.637-3.054-37.737,0-.21-.007-.421-.009-.631l73.528-.485-.036,1.249s43.533,19.709,47.968,23.266C487.805,464.761,491.271,476.227,487.283,477.533Z"
                                transform="translate(-360.078 -436.703)" fill="#e95c29" />
                            <g id="Group_16347" data-name="Group 16347" transform="translate(10.052 0.921)">
                                <path id="Path_10821" data-name="Path 10821"
                                    d="M482.424,457.4c-19.209-.559-96.737-1-115.768-.1-.155.009-.155.114,0,.121,19.027.971,96.555.788,115.766.292C482.828,457.708,482.828,457.416,482.424,457.4Z"
                                    transform="translate(-366.54 -421.92)" fill="#fff" />
                                <path id="Path_10822" data-name="Path 10822"
                                    d="M416.613,437.8c-7.553-1.445-16.805-.328-22.607,5.032-.225.207.075.524.319.447a106.4,106.4,0,0,1,22.252-4.526A.483.483,0,0,0,416.613,437.8Z"
                                    transform="translate(-345.126 -437.22)" fill="#fff" />
                                <path id="Path_10823" data-name="Path 10823"
                                    d="M419.667,439.657c-7.555-1.445-16.8-.328-22.607,5.032-.225.208.075.526.319.449a106.372,106.372,0,0,1,22.252-4.526A.484.484,0,0,0,419.667,439.657Z"
                                    transform="translate(-342.738 -435.766)" fill="#fff" />
                                <path id="Path_10824" data-name="Path 10824"
                                    d="M422.721,441.517c-7.554-1.447-16.805-.328-22.607,5.032-.225.207.073.524.319.447a106.254,106.254,0,0,1,22.252-4.524A.484.484,0,0,0,422.721,441.517Z"
                                    transform="translate(-340.351 -434.312)" fill="#fff" />
                            </g>
                            <path id="Path_10825" data-name="Path 10825"
                                d="M489.862,457.925c2.037.8,1.564,7.644.089,8.186s-125.73-.576-127.842-1.058-1.3-8.056,1.156-8.434S489.862,457.925,489.862,457.925Z"
                                transform="translate(-360.898 -421.174)" fill="#d14816" />
                            <path id="Path_10826" data-name="Path 10826"
                                d="M376.993,444.547c-8.243-4.168-14.734,8.635-6.479,12.812S385.25,448.724,376.993,444.547Z"
                                transform="translate(-356.368 -431.213)" fill="#fff" />
                        </g>
                    </g>
                    <g id="Group_16352" data-name="Group 16352">
                        <path id="Path_10827" data-name="Path 10827"
                            d="M417.889,219.937c-.075,1.368-3.719,13.86-5.6,32.546-1.358,13.651-3.065,25.727-4.884,27.231-2.062,1.695-12.368,8.316-15.841,10.435,0,0,32.143,97.427,40.435,129.9,12.022,47.083,40.766,171.968,40.766,171.968l-78.74-.012S372.755,473.9,362.556,429.079c-8.744-38.426-42.242-153.709-42.242-153.709-12.186-29.968,8.35-55.433,8.35-55.433S417.9,219.884,417.889,219.937Z"
                            transform="translate(-316.451 -219.914)" fill="#37445d" />
                        <g id="Group_16351" data-name="Group 16351" transform="translate(7.084 2.759)">
                            <path id="Path_10828" data-name="Path 10828"
                                d="M430.06,420.46c-11.625-.071-8.288-.221-19.914-.157-5.688.03-43.074.273-48.373,1.269a.129.129,0,0,0,0,.244c5.33.832,42.7-.086,48.391-.232,11.621-.3,8.282-.342,19.9-.773A.176.176,0,0,0,430.06,420.46Z"
                                transform="translate(-288.165 -66.002)" fill="#263238" />
                            <g id="Group_16350" data-name="Group 16350" transform="translate(68.026)">
                                <path id="Path_10829" data-name="Path 10829"
                                    d="M379.877,251.344a146.4,146.4,0,0,0-21.167,14.17.261.261,0,0,0,.285.437c7.174-4.494,14.522-8.87,21.44-13.753A.51.51,0,0,0,379.877,251.344Z"
                                    transform="translate(-358.603 -198.151)" fill="#1c324c" />
                                <path id="Path_10830" data-name="Path 10830"
                                    d="M372.557,221.649c-.062-.262-.538-.249-.492.037a259.729,259.729,0,0,1,2.7,25.882c.257,4.015.469,26.39-6.645,25.859-1.19-.087-1.953-1.128-2.4-2.833-.54-2.049-.153-4.727-.13-6.851.05-4.7-.05-9.389-.376-14.077a127.1,127.1,0,0,0-4.3-25.839.224.224,0,0,0-.438.093c1.866,11.955,3.405,23.938,3.457,36.056.025,5.823-1.511,14.838,4.576,14.788,6.2-.048,7.9-15.009,8.026-19.05C376.9,244.438,375.144,232.6,372.557,221.649Z"
                                    transform="translate(-357.141 -221.462)" fill="#1c324c" />
                                <path id="Path_10831" data-name="Path 10831"
                                    d="M365.385,251.2a37.363,37.363,0,0,0-.839,4.775,10.438,10.438,0,0,0-.064,3.827c.064.237.388.21.492.027a13.262,13.262,0,0,0,1.032-3.874c.3-1.484.641-2.963.9-4.455A.777.777,0,0,0,365.385,251.2Z"
                                    transform="translate(-354.132 -198.676)" fill="#1c324c" />
                            </g>
                            <path id="Path_10832" data-name="Path 10832"
                                d="M365.011,449.4c-.2-1.777-.445-3.55-.695-5.319-.481-3.4-1.053-6.789-1.575-10.184-1.05-6.819-2.22-13.619-3.427-20.413-2.407-13.548-5.187-27.054-8.118-40.5-1.657-7.6-3.583-15.112-5.617-22.619-.141-.522-.878-.271-.788.239,1.235,6.873,2.423,13.747,3.829,20.588q2.1,10.229,4.155,20.469c2.68,13.5,5.176,27.035,7.847,40.536.754,3.81,1.438,7.634,2.181,11.445.372,1.9.755,3.8,1.165,5.7s1,3.746,1.306,5.65c.03.18.3.1.273-.082C365.254,453.1,365.222,451.233,365.011,449.4Z"
                                transform="translate(-301.38 -120.896)" fill="#1c324c" />
                            <path id="Path_10833" data-name="Path 10833"
                                d="M406.845,418.3c-2.555-12.733-6-25.3-9.464-37.808-6.973-25.176-15.182-49.989-23.37-74.793q-3.541-10.718-7.079-21.44c-1.181-3.571-6.965-22.839-7.683-24.668a.157.157,0,0,0-.3.091c1.657,6.563,3.934,16.133,5.848,22.628,1.894,6.422,3.961,12.8,5.955,19.187,3.917,12.562,7.9,25.1,11.711,37.7,7.521,24.9,14.991,49.786,21.1,75.078,1.711,7.083,3.414,14.159,4.975,21.276s2.892,14.257,4.382,21.376a.178.178,0,0,0,.348-.055C411.98,443.894,409.409,431.066,406.845,418.3Z"
                                transform="translate(-290.31 -191.735)" fill="#1c324c" />
                            <path id="Path_10834" data-name="Path 10834"
                                d="M389.264,514.344c-4.225-24.287-9.284-48.444-14.888-72.45q-8.449-36.185-18.815-71.885c-6.91-23.786-14.567-47.363-22.792-70.727-3.9-11.073-8.648-22.021-10.42-33.646a68.886,68.886,0,0,1,7.092-43.587.164.164,0,0,0-.287-.159c-6.937,10.355-14.645,31.816-1.853,66.22,4.264,11.468,7.956,23.138,11.753,34.772q5.843,17.911,11.2,35.975c7.081,23.861,13.656,47.865,19.546,72.049s11.133,48.516,15.748,72.97c4.631,24.542,6.459,32.819,10.41,57.477.513,3.207.919,6.431,1.34,9.653.023.178.307.175.284-.007C394.625,546.568,393.482,538.6,389.264,514.344Z"
                                transform="translate(-320.426 -221.186)" fill="#1c324c" />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Group_16356" data-name="Group 16356" transform="translate(1498.091 4766.608)">
                <path id="Path_10835" data-name="Path 10835"
                    d="M423.432,272.463l-5.077-33.687s9.991-11.707,3.66-25.581c-3.2-7.028-23.468-45.215-35.367-53.954-11.164-8.195-25.194-.462-30.454,6.586-15.126,20.264-26.117,39.7-34.864,106.074Z"
                    transform="translate(-321.33 -155.717)" fill="#91d1e1" />
                <g id="Group_16355" data-name="Group 16355" transform="translate(49.546 77.689)">
                    <path id="Path_10836" data-name="Path 10836"
                        d="M379.379,199.318a104.353,104.353,0,0,0-15.233,2.555c-2.48.645-4.914,1.4-7.345,2.208-1.212.4-2.429.766-3.655,1.123a16.481,16.481,0,0,1-3.924.752.092.092,0,0,0,0,.184,27.618,27.618,0,0,0,3.781-.082,26.941,26.941,0,0,0,3.6-.691c2.521-.629,5.012-1.376,7.529-2.031,5.075-1.322,10.146-2.641,15.3-3.594C379.682,199.7,379.621,199.291,379.379,199.318Z"
                        transform="translate(-349.135 -199.316)" fill="#263238" />
                    <path id="Path_10837" data-name="Path 10837"
                        d="M384.432,202.1a113.005,113.005,0,0,0-12.735-.572c-2.129.032-4.239.189-6.356.4-1.012.1-2.021.219-3.027.351-1.108.146-2.2.44-3.309.568-.116.012-.114.194,0,.184,1.051-.091,2.12-.005,3.174-.034s2.119-.066,3.177-.1c2.12-.057,4.241-.093,6.361-.171,4.244-.159,8.471-.273,12.717-.283C384.667,202.449,384.65,202.123,384.432,202.1Z"
                        transform="translate(-341.485 -197.594)" fill="#263238" />
                </g>
            </g>
            <rect id="Rectangle_12083" data-name="Rectangle 12083" width="9.441" height="281.035"
                transform="translate(1377.908 4601.569)" fill="#4a576f" />
            <g id="Group_51412" data-name="Group 51412" transform="translate(1377 4903.946)">
                <rect id="Rectangle_12087" data-name="Rectangle 12087" width="409.448" height="470"
                    transform="translate(409.448 470) rotate(180)" fill="#1c324c" />
                <rect id="Rectangle_12088" data-name="Rectangle 12088" width="409.448" height="470"
                    transform="translate(409.448 470) rotate(180)" fill="#1c324c" opacity="0.4" />
            </g>
            <rect id="Rectangle_12103" data-name="Rectangle 12103" width="468.713" height="21.342"
                transform="translate(1347.246 4882.604)" fill="#4a576f" />
            <g id="Group_57123" data-name="Group 57123" transform="translate(-32 4323.946)">
                <g id="Group_51424" data-name="Group 51424" transform="translate(1363 299.85)">
                    <path id="Path_20447" data-name="Path 20447"
                        d="M103.335,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621H64.8c-6.609,0-11.962-3.444-11.962-11.621v-23.9Z"
                        transform="translate(-52.84 -91.667)" fill="#1c324c" />
                <path id="Path_20448" data-name="Path 20448"
                    d="M133.659,91.667v23.9c0,8.177-5.353,11.621-11.945,11.621H95.124c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-32.684 -91.667)" fill="#1c324c" />
                <path id="Path_20449" data-name="Path 20449"
                    d="M163.987,91.667v23.9c0,8.177-5.353,11.621-11.945,11.621h-26.59c-6.593,0-11.943-3.444-11.943-11.621v-23.9Z"
                    transform="translate(-38.383 -91.667)" fill="#1c324c" />
                <path id="Path_20450" data-name="Path 20450"
                    d="M194.333,91.667v23.9c0,8.177-5.353,11.621-11.962,11.621H155.8c-6.609,0-11.96-3.444-11.96-11.621v-23.9Z"
                    transform="translate(-31.157 -91.667)" fill="#1c324c" />
                <path id="Path_20451" data-name="Path 20451"
                    d="M224.655,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621h-26.59c-6.609,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-23.927 -91.667)" fill="#1c324c" />
                <path id="Path_20452" data-name="Path 20452"
                    d="M254.984,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621h-26.59c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-16.7 -91.667)" fill="#1c324c" />
                <path id="Path_20453" data-name="Path 20453"
                    d="M285.313,91.667v23.9c0,8.177-5.334,11.621-11.943,11.621H246.78c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-9.473 -91.667)" fill="#1c324c" />
                <path id="Path_20454" data-name="Path 20454"
                    d="M315.661,91.667v23.9c0,8.177-5.353,11.621-11.962,11.621H277.126c-6.609,0-11.962-3.444-11.962-11.621v-23.9Z"
                    transform="translate(-2.249 -91.667)" fill="#1c324c" />
                <path id="Path_20455" data-name="Path 20455"
                    d="M345.982,91.667v23.9c0,8.177-5.353,11.621-11.943,11.621H307.447c-6.609,0-11.943-3.444-11.943-11.621v-23.9Z"
                    transform="translate(4.984 -91.667)" fill="#1c324c" />
                <path id="Path_20456" data-name="Path 20456"
                    d="M376.311,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621h-26.59c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(12.211 -91.667)" fill="#1c324c" />
                <path id="Path_20457" data-name="Path 20457"
                    d="M406.64,91.667v23.9c0,8.177-5.334,11.621-11.943,11.621h-26.59c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(19.438 -91.667)" fill="#1c324c" />
                <path id="Path_20458" data-name="Path 20458"
                    d="M436.988,91.667v23.9c0,8.177-5.353,11.621-11.962,11.621H398.453c-6.609,0-11.962-3.444-11.962-11.621v-23.9Z"
                    transform="translate(13.738 -91.667)" fill="#1c324c" />
                <path id="Path_20459" data-name="Path 20459"
                    d="M467.309,91.667v23.9c0,8.177-5.353,11.621-11.943,11.621H428.774c-6.593,0-11.943-3.444-11.943-11.621v-23.9Z"
                    transform="translate(33.895 -91.667)" fill="#1c324c" />
            </g>
            <path id="Path_20460" data-name="Path 20460"
                d="M554.044,125.115H52.84s31.1-33.91,49.112-83.794H504.931a275.437,275.437,0,0,0,30.648,59.631A227.692,227.692,0,0,0,554.044,125.115Z"
                transform="translate(1310.16 174.735)" fill="#37445d" />
            <rect id="Rectangle_12105" data-name="Rectangle 12105" width="1.699" height="119.32"
                transform="translate(1614.567 216.054)" fill="#fff" />
            <path id="Path_20461" data-name="Path 20461"
                d="M222.053,125.11c-.05.378-.068.588-.068.629l.018,34.9h-1.7V125.615q0-.065.05-.5c.561-5.782,5.829-58.945,6.644-83.79h1.7C227.9,66.061,222.682,118.741,222.053,125.11Z"
                transform="translate(1355.451 174.734)" fill="#fff" />
            <path id="Path_20463" data-name="Path 20463"
                d="M203.581,41.32c-1.376,24.909-10.364,76.705-11.6,83.79-.052.273-.1.483-.12.609v34.92h-1.7l.018-35.256c0-.022.017-.1.05-.273.764-4.331,10.211-58.5,11.639-83.79Z"
                transform="translate(1347.822 174.734)" fill="#fff" />
            <path id="Path_20465" data-name="Path 20465"
                d="M178.128,41.32c-1.7,28.126-14.731,76.832-16.617,83.79-.085.315-.153.524-.186.672v34.857h-1.7l.052-35.361a1.068,1.068,0,0,1,.05-.168c.9-3.28,14.918-54.739,16.7-83.79Z"
                transform="translate(1340.31 174.734)" fill="#fff" />
            <path id="Path_20467" data-name="Path 20467"
                d="M158.429,41.32c-2.31,31.994-22.937,76.223-26.573,83.79-.17.336-.306.609-.391.8v34.73h-1.7V125.32l.1-.21.017-.043c.255-.524,24.28-49.819,26.844-83.747Z"
                transform="translate(1332.926 174.734)" fill="#fff" />
            <path id="Path_20469" data-name="Path 20469"
                d="M139.659,41.32c-10.109,40.634-34.1,76.832-38.893,83.79-.255.4-.474.692-.611.9v34.625h-1.7V125.215l.068-.1.136-.19c.288-.421,28.034-39.289,39.214-83.6Z"
                transform="translate(1329.857 174.734)" fill="#fff" />
            <path id="Path_20471" data-name="Path 20471"
                d="M122.639,41.32C105.054,90.488,77.8,118.783,71.226,125.11c-.493.461-.849.8-1.1,1.03v34.5h-1.7V125.067l.358-.315c.34-.295,32.179-28.589,52.006-83.433Z"
                transform="translate(1320.513 174.734)" fill="#fff" />
            <path id="Path_20474" data-name="Path 20474"
                d="M286.007,125.488v35.151h-1.7V125.615c0-.022-.017-.19-.052-.5-.578-5.739-5.844-58.9-6.642-83.79h1.7c.816,25.015,6.15,78.744,6.659,83.79C285.989,125.34,286.007,125.467,286.007,125.488Z"
                transform="translate(1369.108 174.734)" fill="#fff" />
            <path id="Path_20476" data-name="Path 20476"
                d="M318.16,125.615v35.025h-1.7V125.72c-.017-.126-.068-.336-.118-.609-1.24-7.085-10.229-58.882-11.606-83.79h1.716c1.428,25.288,10.875,79.46,11.622,83.79.033.168.052.251.052.273Z"
                transform="translate(1374.719 174.734)" fill="#fff" />
            <path id="Path_20478" data-name="Path 20478"
                d="M350.727,125.615v35.025h-1.7V125.783c-.035-.148-.1-.358-.188-.672-1.954-6.9-15.036-54.13-16.634-83.79h1.7c1.683,30.732,15.835,80.678,16.735,83.79.018.083.035.125.035.146Z"
                transform="translate(1380.187 174.734)" fill="#fff" />
            <path id="Path_20480" data-name="Path 20480"
                d="M384.627,125.32v35.319h-1.7v-34.73c-.085-.19-.221-.443-.375-.8-3.585-7.609-24.228-52.427-26.59-83.79h1.716c2.616,33.234,26.573,83.223,26.828,83.747l.018.043Z"
                transform="translate(1383.496 174.734)" fill="#fff" />
            <path id="Path_20482" data-name="Path 20482"
                d="M420.957,125.235v35.4h-1.7V125.971c-.118-.188-.306-.483-.559-.86-4.657-7.295-29.938-47.97-38.959-83.79H381.5c9.956,38.763,38.959,83.16,39.266,83.622l.1.168Z"
                transform="translate(1379.761 174.734)" fill="#fff" />
            <path id="Path_20484" data-name="Path 20484"
                d="M456.257,125.13v35.509h-1.7V126.1c-.2-.21-.509-.546-.9-.987-6.642-7.317-39.162-44.377-51.838-83.79h1.819c14.135,42.987,51.923,83.075,52.331,83.5l.271.295Z"
                transform="translate(1381.614 174.734)" fill="#fff" />
            <g id="Group_51426" data-name="Group 51426" transform="translate(1463.127 249.433)" opacity="0.1">
                <path id="Path_20485" data-name="Path 20485"
                    d="M159.673,79.959s126.649-2.943,154.489-5.045S440.266,60.2,444.633,61.463s-85.706,20.022-125.012,21.36S155.917,84.8,159.673,79.959Z"
                    transform="translate(-159.59 -61.375)" />
                <path id="Path_20486" data-name="Path 20486"
                    d="M197.085,68.052c-4.828-1.761,80.52-5.885,83.523-4.2S202.271,69.944,197.085,68.052Z"
                    transform="translate(-134.811 -59.994)" />
                <path id="Path_20487" data-name="Path 20487"
                    d="M357.574,73.738c1.152,3.741-42.7,2.521-43.03-.885S356.336,69.717,357.574,73.738Z"
                    transform="translate(-56.646 -55.317)" />
            </g>
        </g>
        <g id="Group_16357" data-name="Group 16357" transform="translate(1472.878 4771.285)">
            <path id="Path_10838" data-name="Path 10838"
                d="M366.582,163.086c-31.88,30.059-61.4,79.693-59.3,105.764,2.078,25.747,10.811,22.441,13.339,22.044,15.449-2.42,45.572-77.307,54.077-107.492C383.145,153.435,374.466,155.652,366.582,163.086Z"
                transform="translate(-307.18 -158.342)" fill="#d3766a" />
        </g>
        <g id="Group_16376" data-name="Group 16376" transform="translate(1504.938 4695.177)">
            <g id="Group_16363" data-name="Group 16363" transform="translate(0 43.678)">
                <g id="Group_16358" data-name="Group 16358" transform="translate(10.737 7.669)">
                    <path id="Path_10839" data-name="Path 10839"
                        d="M355.831,144.621c-5.529-.167-18.464,6.2-21.693,14.15s-.677,19.339,1.887,20.011,12.509-2.1,18.722-7.238S358.556,144.7,355.831,144.621Z"
                        transform="translate(-330.084 -144.311)" fill="#263238" />
                    <path id="Path_10840" data-name="Path 10840"
                        d="M356.964,144.785c.169-.071.135-.371-.069-.337-4.667.761-9.348,3.27-13.533,5.383-3.377,1.705-6.814,3.621-9.241,6.6a12.687,12.687,0,0,0-2.755,10.264c.627,4.125,2.147,8.966,4.845,12.217.08.094.278.027.214-.107-3.6-7.336-7.265-16.436-.572-23.165C341.535,149.926,349.792,147.825,356.964,144.785Z"
                        transform="translate(-331.198 -144.445)" fill="#263238" />
                </g>
                <g id="Group_16359" data-name="Group 16359" transform="translate(0 39.973)">
                    <path id="Path_10841" data-name="Path 10841"
                        d="M341.455,162.581c-4.533,1.479-14.923,12.593-15.274,21.807s9.276,20.9,11.73,21.418,11.112-9.891,13.28-16.736c1.449-4.576-1.231-9.084-3.156-13.708C345.194,168.538,342.159,162.353,341.455,162.581Z"
                        transform="translate(-324.391 -162.575)" fill="#263238" />
                    <path id="Path_10842" data-name="Path 10842"
                        d="M348.3,187.4c-.323-9.255-7.1-17.2-11.705-24.743-.048-.08-.185-.014-.148.073,3.173,7.473,9.191,13.587,10.861,21.716.991,4.825.207,9.891-3.22,13.614-2.969,3.225-7.088,5.221-10.825,7.4-.248.144,0,.5.246.365C341.006,201.7,348.635,196.953,348.3,187.4Z"
                        transform="translate(-318.926 -162.54)" fill="#263238" />
                    <path id="Path_10843" data-name="Path 10843"
                        d="M341.392,163.319c.21-.157-.016-.5-.23-.34-6.012,4.373-13.1,10.437-15.365,17.792-2.457,7.967,2.815,14.825,7.455,20.72.059.073.18-.007.121-.082-4.613-6-9.72-13.567-6.7-21.449C329.266,173.184,335.744,167.551,341.392,163.319Z"
                        transform="translate(-325.172 -162.29)" fill="#263238" />
                </g>
                <g id="Group_16360" data-name="Group 16360" transform="translate(7.036 81.073)">
                    <path id="Path_10844" data-name="Path 10844"
                        d="M336.676,185.708c-4.366,6.358-9.4,15.476-5.866,21.607s14.487,13.309,15.226,13.061,10.075-11.424,9.882-20.075S337.245,184.881,336.676,185.708Z"
                        transform="translate(-328.733 -185.64)" fill="#263238" />
                    <path id="Path_10845" data-name="Path 10845"
                        d="M347.148,220.275c-6.37-2.614-14.113-6.513-16.737-13.386-2.527-6.614,2.4-15.257,6.017-20.584a.064.064,0,0,0-.1-.08c-4.162,5.148-8.9,13.621-6.609,20.449,2.27,6.766,10.748,12.177,17.361,13.843C347.221,220.553,347.278,220.328,347.148,220.275Z"
                        transform="translate(-329.121 -185.201)" fill="#263238" />
                </g>
                <g id="Group_16361" data-name="Group 16361" transform="translate(8.28 115.179)">
                    <path id="Path_10846" data-name="Path 10846"
                        d="M344.813,204.78c-1.882,3.01-3.083,10-.756,15.989,3.984,10.244-.62,10.492-9.332,19.011-7.363,7.2-3.476,17.917,3.934,19.95,0,0-6.187-4.357-2.112-10.707,4.868-7.589,25.037-9.709,27.131-24.193C366.39,206.092,344.813,204.78,344.813,204.78Z"
                        transform="translate(-329.067 -204.78)" fill="#263238" />
                    <path id="Path_10847" data-name="Path 10847"
                        d="M344.464,208.5c.037-.194-.294-.264-.342-.07a15.926,15.926,0,0,0-.4,5.716c.3,2.491,1.525,4.649,1.99,7.092,1.18,6.213-5.1,9.7-9.385,12.671-5.747,3.986-8.4,10.517-5.021,17.054a.083.083,0,0,0,.148-.075,12.422,12.422,0,0,1,.991-12.839c2.628-3.564,7.049-5.128,10.169-8.166,2.135-2.08,3.984-4.608,3.7-7.721a24.466,24.466,0,0,0-1.417-4.936A17.3,17.3,0,0,1,344.464,208.5Z"
                        transform="translate(-329.819 -202.017)" fill="#263238" />
                    <path id="Path_10848" data-name="Path 10848"
                        d="M343.584,204.889c-.082-.011-.112.123-.03.143,5.39,1.34,10.989,2.573,14.879,6.9,4.186,4.656,4.6,11.559,2.343,17.211-2.529,6.329-8.523,7.578-14.314,9.645-4.191,1.5-8.282,3.323-11.208,6.794-.089.105.084.255.183.159,5.118-4.9,11.6-6.354,17.99-8.87,5.221-2.058,7.983-5.935,8.854-11.452C364.2,213.248,354.53,206.388,343.584,204.889Z"
                        transform="translate(-325.589 -204.695)" fill="#263238" />
                </g>
                <g id="Group_16362" data-name="Group 16362" transform="translate(26.328)">
                    <path id="Path_10849" data-name="Path 10849"
                        d="M342.756,140.428c-2.072-1.262-3.99,1.981-1.915,3.245S344.83,141.691,342.756,140.428Z"
                        transform="translate(-339.948 -140.141)" fill="#ffc727" />
                    <path id="Path_10850" data-name="Path 10850"
                        d="M344.148,141.818c-2.071-1.262-3.99,1.98-1.916,3.243S346.224,143.081,344.148,141.818Z"
                        transform="translate(-338.859 -139.055)" fill="#ffc727" />
                    <path id="Path_10851" data-name="Path 10851"
                        d="M345.775,142.957c-2.072-1.262-3.99,1.98-1.915,3.245S347.849,144.22,345.775,142.957Z"
                        transform="translate(-337.587 -138.164)" fill="#ffc727" />
                    <path id="Path_10852" data-name="Path 10852"
                        d="M347.489,143.847c-2.071-1.263-3.99,1.98-1.915,3.245S349.563,145.11,347.489,143.847Z"
                        transform="translate(-336.247 -137.468)" fill="#ffc727" />
                    <path id="Path_10853" data-name="Path 10853"
                        d="M349.372,144.442c-2.071-1.262-3.99,1.981-1.916,3.245S351.446,145.7,349.372,144.442Z"
                        transform="translate(-334.775 -137.003)" fill="#ffc727" />
                    <path id="Path_10854" data-name="Path 10854"
                        d="M351.259,144.681c-2.071-1.262-3.99,1.981-1.916,3.245S353.333,145.944,351.259,144.681Z"
                        transform="translate(-333.299 -136.816)" fill="#ffc727" />
                </g>
            </g>
            <g id="Group_16364" data-name="Group 16364" transform="translate(38.596 46.17)">
                <path id="Path_10855" data-name="Path 10855"
                    d="M346.846,172.607c.515,1.9,10.93,14.2,19.139,10.67,3.042-1.308,1.9-11.279,1.9-11.279l3.266-17.434L348.968,141.54S346.639,171.8,346.846,172.607Z"
                    transform="translate(-346.833 -141.54)" fill="#d3766a" />
                <path id="Path_10856" data-name="Path 10856"
                    d="M347.57,152.844a33.815,33.815,0,0,0,19.921,18.182l3.09-16.459-22.186-13.026S348.021,146.646,347.57,152.844Z"
                    transform="translate(-346.256 -141.539)" fill="#263238" />
            </g>
            <g id="Group_16375" data-name="Group 16375" transform="translate(21.96)">
                <path id="Path_10857" data-name="Path 10857"
                    d="M392.8,146.91c5.241,28.574-11.283,34.941-17.446,36.065-5.593,1.019-24.774,3.779-32.94-24.1s3.464-39.351,16.137-42.361S387.561,118.334,392.8,146.91Z"
                    transform="translate(-335.778 -115.546)" fill="#d3766a" />
                <g id="Group_16371" data-name="Group 16371" transform="translate(27.069 26.098)">
                    <path id="Path_10858" data-name="Path 10858"
                        d="M366.738,133.223a19.2,19.2,0,0,1-2-.216,3.258,3.258,0,0,1-1.992-.609,1.033,1.033,0,0,1-.125-1.256,2.561,2.561,0,0,1,2.455-.848,3.767,3.767,0,0,1,2.484,1.091A1.1,1.1,0,0,1,366.738,133.223Z"
                        transform="translate(-345.039 -130.276)" fill="#263238" />
                    <path id="Path_10859" data-name="Path 10859"
                        d="M354.471,134.663a18.91,18.91,0,0,0,1.912-.608,3.242,3.242,0,0,0,1.834-.992,1.033,1.033,0,0,0-.128-1.254,2.564,2.564,0,0,0-2.575-.346,3.771,3.771,0,0,0-2.219,1.561A1.1,1.1,0,0,0,354.471,134.663Z"
                        transform="translate(-352.352 -129.49)" fill="#263238" />
                    <g id="Group_16366" data-name="Group 16366" transform="translate(0 10.228)">
                        <g id="Group_16365" data-name="Group 16365" transform="translate(2.815)">
                            <path id="Path_10860" data-name="Path 10860"
                                d="M357.28,137.054c-.025-.073-.142.111-.13.192.306,1.955.326,4.246-1.37,5.119-.052.027-.034.123.03.107C357.941,141.9,357.882,138.858,357.28,137.054Z"
                                transform="translate(-353.109 -135.217)" fill="#263238" />
                            <path id="Path_10861" data-name="Path 10861"
                                d="M356.43,136.018c-3.188.153-2.65,6.518.3,6.377C359.639,142.258,359.1,135.891,356.43,136.018Z"
                                transform="translate(-354.268 -136.016)" fill="#263238" />
                        </g>
                        <path id="Path_10862" data-name="Path 10862"
                            d="M356.444,136.286c-.526.406-1.018,1.089-1.682,1.212-.768.141-1.409-.476-1.971-1.074-.059-.061-.109,0-.1.084.086,1.221.659,2.432,1.983,2.5,1.206.066,1.971-1.087,2.172-2.338C356.881,136.439,356.656,136.122,356.444,136.286Z"
                            transform="translate(-352.688 -135.84)" fill="#263238" />
                    </g>
                    <g id="Group_16368" data-name="Group 16368" transform="translate(18.559 7.88)">
                        <g id="Group_16367" data-name="Group 16367" transform="translate(0 0.435)">
                            <path id="Path_10863" data-name="Path 10863"
                                d="M363.153,136.2c.009-.077.16.082.164.162.089,1.978.524,4.228,2.357,4.747.059.016.059.116-.007.11C363.465,141.079,362.919,138.085,363.153,136.2Z"
                                transform="translate(-363.103 -133.976)" fill="#263238" />
                            <path id="Path_10864" data-name="Path 10864"
                                d="M364.748,134.969c3.154-.485,3.89,5.861.968,6.31C362.84,141.72,362.1,135.375,364.748,134.969Z"
                                transform="translate(-363.095 -134.942)" fill="#263238" />
                        </g>
                        <path id="Path_10865" data-name="Path 10865"
                            d="M364.928,135.423c.542.278,1.108.832,1.711.8.7-.036,1.133-.78,1.5-1.491.039-.073.095-.025.107.059.175,1.208-.08,2.518-1.219,2.887-1.037.335-1.939-.615-2.368-1.789C364.579,135.673,364.711,135.311,364.928,135.423Z"
                            transform="translate(-361.905 -134.698)" fill="#263238" />
                    </g>
                    <path id="Path_10866" data-name="Path 10866"
                        d="M357.724,146.276c.47.467.937,1.108,1.655,1.174a5.226,5.226,0,0,0,2.131-.483.069.069,0,0,1,.084.1,2.66,2.66,0,0,1-2.518,1.055,2.1,2.1,0,0,1-1.543-1.762C357.5,146.25,357.655,146.209,357.724,146.276Z"
                        transform="translate(-348.902 -117.791)" fill="#263238" />
                    <g id="Group_16369" data-name="Group 16369" transform="translate(9.933 1.33)">
                        <path id="Path_10867" data-name="Path 10867"
                            d="M365.074,150.231s.554,2.933.624,4.325c.005.13-.328.208-.8.26-.021.009-.034.018-.054,0a6.622,6.622,0,0,1-6.55-2.152c-.1-.132.066-.283.2-.2a10,10,0,0,0,6.131,1.433c.078-.39-1.117-4.989-.9-5.018a12.167,12.167,0,0,1,3.2.559c-.977-6.139-2.931-12.072-3.813-18.2a.19.19,0,0,1,.36-.107c2.488,6.073,3.767,13.113,4.939,19.428C368.561,151.344,365.642,150.441,365.074,150.231Z"
                            transform="translate(-358.262 -131.022)" fill="#263238" />
                    </g>
                    <g id="Group_16370" data-name="Group 16370" transform="translate(9.591 22.953)">
                        <path id="Path_10868" data-name="Path 10868"
                            d="M364.643,144.648a7.775,7.775,0,0,1-2.749,2.821,3.521,3.521,0,0,1-2.09.424c-1.584-.187-1.819-1.579-1.711-2.812a8.334,8.334,0,0,1,.424-1.924A10.128,10.128,0,0,0,364.643,144.648Z"
                            transform="translate(-358.07 -143.157)" fill="#263238" />
                        <path id="Path_10869" data-name="Path 10869"
                            d="M361.894,146.626a3.521,3.521,0,0,1-2.09.424c-1.584-.187-1.819-1.579-1.711-2.812C359.754,144.178,361.528,145.06,361.894,146.626Z"
                            transform="translate(-358.07 -142.314)" fill="#ff9abb" />
                    </g>
                </g>
                <g id="Group_16372" data-name="Group 16372" transform="translate(2.124)">
                    <path id="Path_10870" data-name="Path 10870"
                        d="M372.456,123.97s1.19,4.4-.3,7.593c-3.058,6.568-10.921,4.906-13.583,7.512-2.495,2.445-1.573,7.464-3.831,9.909-3.124,3.386-7.423,2.113-7.926,4.38s-.107,7.069-2.969,8.006-17.616-44.877,20.825-45.634a32.209,32.209,0,0,1,11.835,1.292c10.946,3.1,15.025,21.573,15.025,21.573s-2.659.1-3.95-1.025c-1.825-1.591-2.536-3.07-3.872-4.1-2.1-1.616-3.236-.991-5.417-2.564C374.976,128.517,372.456,123.97,372.456,123.97Z"
                        transform="translate(-338.688 -115.629)" fill="#263238" />
                    <path id="Path_10871" data-name="Path 10871"
                        d="M368.713,119.142a25.526,25.526,0,0,1,1.142,5.756,10.6,10.6,0,0,1-.754,5.609,7.765,7.765,0,0,1-4.275,3.4c-1.78.674-3.746.877-5.657,1.662a5.85,5.85,0,0,0-2.5,1.917,7.885,7.885,0,0,0-1.08,2.9c-.381,1.935-.772,3.844-2.035,5.233a6.466,6.466,0,0,1-5.011,2.158c-1.891.018-3.984-.5-5.821.349,1.9-.7,3.835-.032,5.809.105a7.02,7.02,0,0,0,5.54-2.124c1.523-1.5,1.93-3.674,2.34-5.536a7.107,7.107,0,0,1,.982-2.58,4.982,4.982,0,0,1,2.142-1.572c1.711-.743,3.7-.989,5.588-1.725a10.09,10.09,0,0,0,2.66-1.49,6.122,6.122,0,0,0,1.962-2.386,11.113,11.113,0,0,0,.561-5.973A25.783,25.783,0,0,0,368.713,119.142Z"
                        transform="translate(-335.533 -112.883)" fill="#263238" />
                    <path id="Path_10872" data-name="Path 10872"
                        d="M357.719,120.1a20.694,20.694,0,0,0,1.86,5.182,9.663,9.663,0,0,0,3.8,4.125,13.943,13.943,0,0,0,2.6.993,4.475,4.475,0,0,1,2.037,1.349,13.843,13.843,0,0,0,1.65,2.213,6.07,6.07,0,0,0,2.391,1.376A6.8,6.8,0,0,1,370,133.652a14.127,14.127,0,0,1-1.392-2.3,4.742,4.742,0,0,0-2.325-1.766,14.927,14.927,0,0,1-2.457-.98,10.309,10.309,0,0,1-3.6-3.7A40.849,40.849,0,0,1,357.719,120.1Z"
                        transform="translate(-323.809 -112.132)" fill="#263238" />
                </g>
                <g id="Group_16374" data-name="Group 16374" transform="translate(0 37.662)">
                    <g id="Group_16373" data-name="Group 16373">
                        <path id="Path_10873" data-name="Path 10873"
                            d="M349.931,142.575s-7.6-8.042-11.34-5.2,2.9,15.144,7.944,16.217a4.914,4.914,0,0,0,6.126-3.578Z"
                            transform="translate(-337.497 -136.765)" fill="#d3766a" />
                        <path id="Path_10874" data-name="Path 10874"
                            d="M339.254,139.063a.067.067,0,0,0-.005.132c3.551.861,5.775,3.706,7.487,6.744-1.18-1.091-2.637-1.57-4.123-.139-.077.075.027.2.117.168a3.018,3.018,0,0,1,3.445.556,14.531,14.531,0,0,1,1.969,2.427c.2.276.72.036.554-.292-.013-.023-.027-.048-.039-.073C348.112,144.3,343.9,138.813,339.254,139.063Z"
                            transform="translate(-336.169 -134.976)" fill="#263238" />
                    </g>
                </g>
            </g>
        </g>
        <g id="Group_16381" data-name="Group 16381" transform="translate(1477.168 4779.588)">
            <g id="Group_16377" data-name="Group 16377" transform="translate(80.063)">
                <path id="Path_10875" data-name="Path 10875"
                    d="M373.4,165.323l-18.587,49.586c-.713,1.434-.1,2.737,1.374,2.9l26.333,2.956a4.288,4.288,0,0,0,3.956-2.3l18.587-49.585c.715-1.44.1-2.737-1.374-2.9l-26.331-2.956A4.284,4.284,0,0,0,373.4,165.323Z"
                    transform="translate(-354.519 -163.001)" fill="#37474f" />
                <path id="Path_10876" data-name="Path 10876"
                    d="M375.157,165.5l-18.585,49.586c-.713,1.436-.1,2.737,1.374,2.9l26.331,2.956a4.289,4.289,0,0,0,3.958-2.3l18.587-49.586c.715-1.438.1-2.737-1.372-2.9L379.117,163.2A4.286,4.286,0,0,0,375.157,165.5Z"
                    transform="translate(-353.146 -162.859)" fill="#455a64" />
                <path id="Path_10877" data-name="Path 10877"
                    d="M372.744,167.369a2.251,2.251,0,1,1-1.93-2.532A2.251,2.251,0,0,1,372.744,167.369Z"
                    transform="translate(-343.775 -161.582)" fill="#263238" />
            </g>
            <g id="Group_16380" data-name="Group 16380" transform="translate(0 18.773)">
                <path id="Path_10878" data-name="Path 10878"
                    d="M315.169,269.023c11.643.542,25.166-9.686,59.882-40.176,4.8-4.218-15.85-46.13-21.212-41.889-6.753,5.342-31.8,27.539-33.43,29.394C306.689,232,307.273,268.654,315.169,269.023Z"
                    transform="translate(-309.588 -163.278)" fill="#d3766a" />
                <g id="Group_16379" data-name="Group 16379" transform="translate(32.163)">
                    <path id="Path_10879" data-name="Path 10879"
                        d="M327.638,211.55s9.244-15.809,16.671-22.612c9.945-9.109,22.281-12.878,27.546-14.145,10.991-2.646,39.394-1.445,40.476,6.794.978,7.461-27.992,4.767-27.992,4.767s29.991-2.128,29.519,7.072-33.925,4.157-33.925,4.157,30.864-1.5,28.875,7.915c-1.5,7.11-32.058,4.252-32.058,4.252s26.536-1.1,25.306,6.278c-1.36,8.154-29.731,7.284-29.731,7.284-6.283,18.23-16.734,24.341-24.684,27.8C345.717,251.953,327.638,211.55,327.638,211.55Z"
                        transform="translate(-327.638 -173.537)" fill="#d3766a" />
                    <g id="Group_16378" data-name="Group 16378" transform="translate(44.216 12.018)">
                        <path id="Path_10880" data-name="Path 10880"
                            d="M353.64,181.152c3.514-.378,7.069-.784,10.6-.848s7.076,0,10.6.262a60.416,60.416,0,0,1,13.621,2.34c-8.042-1.727-20.7-1.695-24.237-1.63s-7.062-.05-10.591.011C353.549,181.288,353.556,181.161,353.64,181.152Z"
                            transform="translate(-351.575 -180.281)" fill="#263238" />
                        <path id="Path_10881" data-name="Path 10881"
                            d="M353.633,188.305c4.508-.287,8.972-.738,13.494-.827,4.5-.087,8.778.223,13.272.444.346.018.223.2-.127.2-4.5-.043-8.662.266-13.161.276-4.483.011-9-.153-13.48.02C353.562,188.418,353.564,188.311,353.633,188.305Z"
                            transform="translate(-351.57 -174.665)" fill="#263238" />
                        <path id="Path_10882" data-name="Path 10882"
                            d="M352.543,194.269c6.866-.791,13.87-.317,20.73.315.342.03.157.246-.194.233-6.889-.233-12.292-.467-20.51-.308C352.441,194.511,352.4,194.287,352.543,194.269Z"
                            transform="translate(-352.452 -169.669)" fill="#263238" />
                    </g>
                </g>
            </g>
            <path id="Path_10883" data-name="Path 10883"
                d="M309.848,251.679c.454-7.044,3.767-17.945,6.894-22.74,3.206-4.911,13.017-13.608,13.017-13.608,10.98-9.685,17.523-15.344,17.523-15.344s8.512-16.773,26.039-23.2"
                transform="translate(-309.385 -152.225)" fill="none" stroke="#263238" stroke-miterlimit="10"
                stroke-width="0.75" />
        </g>
    </g>
</svg></template>