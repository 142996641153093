<template>
    <button type="button" data-bs-toggle="modal" data-bs-target="#liveExperience" :class="[classStyle]+' btn btn-primary py-xxl-2 py-1 h-fit-content basic-bold f-18 w-min-158px' ">{{ text }}</button>
</template>
<script>
export default{
    name:'SmallButton',
    props:{
        text: {
            type:String,
            default: "placeholder",
        },
        classStyle: {
            type:String,
            default: ''
        },
    }
}
</script>
<style scoped>
.w-min-158px {
  min-width: 158px;
}

@media (max-width: 1600px) {
    .w-min-158px {
    min-width: 122px;
  }
}
</style>