<template>

<footer  :class="[{'pt-5': !flat} , {'mobile':mobile}]">
  <div class="footer container-fluid py-5 py-xl-0" :class="{'mt-5': !flat}">
 <div class="row  h-100 d-flex justify-content-center align-items-center">
      <div class="col-xl-9 col-10 mx-auto mt-4 mt-xl-5 ">
        <div class="row h-100 d-flex  justify-xl-content-center  justify-content-around " :class="mobile ? 'align-items-start':'align-items-center'">
          <div class="d-flex flex-column justify-content-end" :class="mobile ? 'w-fit-content line-height-17 align-items-start':'col-xl-4 col-6 align-items-center'">
            <p class="ms-xl-2 link white f-sm-18 basic-SemiBold mb-0" v-if="mobile">
              روابط مهمة
            </p>
            <router-link :to="{name:'our-services'}" class="white f-18 basic-light link">خدماتنا</router-link>
            <router-link :to="{name:'faq'}" class="white f-18 basic-light link">الأسئلة الشائعة</router-link>
            <router-link :to="{ name: 'blog'}" class="white f-18 basic-light link">مدونة إي كارت</router-link>
            <router-link :to="{ name: 'learn-with-us'}" class="white f-18 basic-light link">تعلم معنا</router-link>
          </div>
          <div class="col-4 d-flex flex-column justify-content-center" v-if="!mobile">
            <p class="white f-18 basic-light text-center mb-0">
              جميع الحقوق محفوظة
              <a href="https://madfox.solutions/" target="_blank" class="link">
                               لشركة الثعلب الماكر
              </a>
               <br>
               بالتعاون مع فاتورة
            </p>
            <div class="d-flex justify-content-center ">
              <router-link :to="{name:'privacy-policy'}" class="white f-18 basic-light link">سياسة الخصوصية</router-link>
              <router-link :to="{name:'terms-of-use'}" class="white f-18 basic-light link mx-2">اتفاقية الاستخدام</router-link>

            </div>
          </div>
          <div class="text-xl-center text-end" :class="mobile ? 'w-fit-content line-height-17':'col-xl-4 col-6'">
            <p class="ms-xl-2 link white f-18 basic-light mb-0" :class="{'f-sm-18 basic-SemiBold':mobile}">
              تواصل معنا
            </p>
            <div class="d-flex flex-column text-xl-center text-end">
              <a href="mailto:Info@ecart.sy" class="white f-18 basic-light link">Info@ecart.sy</a>
              <a href="tel:00963987209645" class="dir-ltr white f-18 basic-light mx-xl-2 link">+963 987 209 645</a>
            </div>
            <p class="ms-xl-2 link white f-18 basic-light mb-0" v-if="mobile">
              صفحاتنا الرسمية
            </p>
            <div class="d-flex   align-items-center" :class="mobile ? 'justify-content-start':'justify-content-center'">
              <!-- <a class="ms-2 link " href="" target="_blank">
                <img src="../assets/img/twitter.svg" alt="" class="dim-22">
              </a> -->
              
              <a class="ms-2 link" href="https://www.facebook.com/ecart.sy" target="_blank">
                <img src="../assets/img/facebook.svg" alt="فيسبوك" class="dim-22">
              </a>
              <a class="ms-2 link" href="https://www.instagram.com/ecart.sy/" target="_blank">
                <img src="../assets/img/instagram.svg" alt="انستغرام" class="dim-22">
              </a>
              <a class="ms-0 link" href="https://www.youtube.com/@ecartsy" target="_blank">
                <img src="../assets/img/youtube.svg" alt="يوتيوب" class="dim-22">
              </a>
            </div>

          </div>
          <div class="col-10 col-md-9 mt-md-3 mt-4" v-if="mobile">
            <div class="d-flex justify-content-evenly ">
              <router-link :to="{name:'privacy-policy'}" class="white f-12 basic-light link">سياسة الخصوصية</router-link>
              <router-link :to="{name:'terms-of-use'}" class="white f-12 basic-light link mx-2">اتفاقية الاستخدام</router-link>

            </div>
            <p class="white f-12 basic-light text-center mb-0">
              جميع الحقوق محفوظة
              <a href="https://madfox.solutions/" target="_blank" class="link">
                               لشركة الثعلب الماكر
              </a>
               <br>
               بالتعاون مع فاتورة
            </p>
            
          </div>
        </div>
      </div>
    </div>
  </div>
   
  </footer>
  </template>
  <script>
import responsiveMixin from '@/mixins/responsiveMixin'
  export default {
    name: 'footer',
    props:{
      flat:{
        type:Boolean ,
        default:false
      }
    },
    mixins:[responsiveMixin],
  }
  </script>
  
  <style scoped>
  .dim-22 {
  width: 22px;
  height: 22px;
}

  .footer {
  background-image: url("../assets/img/footer-bg.svg");
  height: 40vh;
}
.dir-ltr {
  direction: ltr !important;
}
 .footer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: center;

    /* background-color: #32465d; */
  }
  .footer a,
  .footer a:active {
    text-decoration: none;
    color: white;
  }

  @media (max-width: 1199.98px) {
    .footer {
    background-image: url("../assets/img/footer-bg-r.svg");
    height: auto;
  }
  .dim-22 {
    width: 32px;
    height: 32px;
  }
  }
  @media (max-width: 570.98px) {
    .dim-22 {
    width: 22px;
    height: 22px;
  }

  }
  </style>
  