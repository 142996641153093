const responsiveMixin = {
    data: function() {
        return {
          get mobile() {
            // return window.innerWidth <= 575.98;
            return window.innerWidth <= 1199.98;
          },
          get tablet() {
            // return window.innerWidth <= 575.98;
            return window.innerWidth <= 1199.98 && window.innerWidth > 599.98;
          }
        }
      },
      
};
export default responsiveMixin