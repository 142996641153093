<template>
    <div :class="'text-center mx-auto'+[column]">
        <img :src="require(`@/assets/img/logos/${imgSrc}`)" :alt="name" :class="[styleClass]"/>
        <p class="white f-20 basic-SemiBold mb-0 mt-2 pt-1 text-uppercase">{{ name }}</p>
        <p class="white f-12 basic-light text-uppercase letter-spacing-3">{{ slogn }}</p>
    </div>
</template>
<script>
export default {
    name: 'clientLogo',
    props: {
        name: {
            type: String,
            default: ''
        },
        slogn: {
            type: String,
            default: ''
        },
        imgSrc: {
            type: String,
            default: "MaskGroup_41.svg"
        },
        styleClass:{
            type:String,
            default:""
        },
        column:{
            type:String,
            default:" col-3"
        },
    }
}
</script>
<style scoped>
.letter-spacing-3 {
  letter-spacing: 3px;
}
@media (max-width: 1600px) {
     .h-sd-66px {
    height: 66px;
  }
}
</style>