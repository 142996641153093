<template>
<HomeHeader/>
<Servicess/>
<Mechanism/>
<WhyECart />
<Packages/>
<Blog/>
<!-- <Feedback />
<Clients /> -->
<ContactUs/>
<liveExpModal/>
</template>
<script>
import HomeHeader from "../components/headers/HomeHeader.vue";
import Servicess from "../components/sections/Servicess.vue";
import Mechanism from "../components/sections/Mechanism.vue";
import WhyECart from "../components/sections/WhyECart.vue";
import Packages from "../components/sections/Packages.vue";
import Blog from "../components/sections/Blog.vue";
import Feedback from "../components/sections/Feedback.vue";
import Clients from "../components/sections/Clients.vue";
import ContactUs from "../components/sections/ContactUs.vue";
import liveExpModal from "@/components/modals/liveExpModal.vue";
import metaMixin from '@/mixins/metaMixin';
import responsiveMixin from '@/mixins/responsiveMixin';
import AOS from 'aos';
export default {
  name: "Home",
  mixins:[metaMixin ,responsiveMixin],
  data(){
    return {
     title: 'الرئيسية | اي كارت' ,
    };
  },
 
  components: {
    HomeHeader,
    Servicess,
    Mechanism,
    WhyECart,
    Packages,
    Blog,
    Feedback,
    Clients,
    ContactUs,
    liveExpModal
  },
  mounted () {
  window.scrollTo(0, 0);
  window.addEventListener('load', function() { 
    if(window.innerWidth >  1199.98)
    AOS.init();});
  // $(document).scroll(function () {
  //       $('section').each(function () {
  //         var current = '';
  //         if(current != $(this).attr('id'))
  //           if($(this).position().top <= $(document).scrollTop() && ($(this).position().top + $(this).outerHeight()) > $(document).scrollTop()) {
  //             // if($(this).position().top >= $(document).scrollTop())
  //             // $(document).scrollTop($(this).position().top)
  //             $('section').removeClass('active-section')    
  //             $(this).addClass('active-section')  
  //             current = $(this).attr('id');
  //           }
  //       });
  //   });
}
};
</script>
