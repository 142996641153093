<template>
  <section id="our-service" class="container-fluid" v-if="!mobile">
    <div class="row pt-xxl-5 mt-xxl-5 pt-lg-4 mt-lg-5">
      <div class="col-11 px-0 mx-auto text-center">
        <SecTitle title="خدمات إي كارت" />
        <div class="row pt-xxl-4 mt-xxl-4 pt-1 mt-5 d-flex aligin-items-center">
          <div class="col-6 service-img-container">
            <img src="../../assets/img/basic_services.svg" alt="خدمات إي كرات" id="service-img-container" />
          </div>
          <div class="col-6 pt-4 my-auto px-0 d-flex">
            <div class="d-flex w-100 my-5 flex-column border-1px">
              <service id="sales_service" name="بيع المنتجات" shortDesc="أي نشاط تجاري يقوم ببيع البضائع"
                imgSrc="service_1.svg" />
              <service id="reservation_service" name="الحجوزات والمواعيد" shortDesc="أي نشاط تجاري يعتمد على الحجز مسبق"
                styleClass="pt-3" imgSrc="service_2.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="our-service" class="container-fluid" v-else>
    <div class="row pt-xxl-5 mt-xxl-5 pt-lg-4 mt-lg-5 pt-1 mt-5">
      <div class="col-lg-11 col-md-10 col-11 px-0 mx-auto text-center">
        <SecTitle title="خدمات إي كارت" v-if="title" />
        <div class="row pt-lg-4 mt-lg-5  pt-md-4 mt-md-5 pt-1 mt-5 d-flex aligin-items-center">
          <div class="col-lg-11 mx-auto px-0 col-md-10 col-11">
            <ServicesSwiper />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SecTitle from "../items/shared/SecTitle.vue";
import service from "../items/ourServices/service.vue";
import responsiveMixin from "@/mixins/responsiveMixin";
import ServicesSwiper from '../items/shared/servicesSwiper.vue'
export default {
  name: "Services",
  components: {
    service,
    SecTitle,
    ServicesSwiper
  },
  props: {
    title: {
      type: Boolean,
      default: true
    }
  },
  mixins: [responsiveMixin],
  mounted() {
    $(".service").hover(
      function () {
        if ($(this).attr("id") == "reservation_service") {
          $("#service-img-container").removeClass("service-img-animation  animate__animated animate__slideInRight");
          $("#service-img-container").addClass("animate__animated animate__fadeOut");
          setTimeout(function () {
            $("#service-img-container").attr(
              "src",
              require(`@/assets/img/reser.svg`)
            );
            $("#service-img-container").removeClass("animate__animated animate__fadeOut");
            $("#service-img-container").addClass("service-img-animation animate__animated animate__slideInRight");
          }, 300);
        }
        if ($(this).attr("id") == "sales_service") {
          $("#service-img-container").removeClass("service-img-animation  animate__animated animate__slideInRight");
          $("#service-img-container").addClass("animate__animated animate__fadeOut");
          setTimeout(function () {
            $("#service-img-container").attr(
              "src",
              require(`@/assets/img/sales.svg`)
            );
            $("#service-img-container").removeClass("animate__animated animate__fadeOut");
            $("#service-img-container").addClass("service-img-animation animate__animated animate__slideInRight");
          }, 300);
        }
      },
      function () {
        $("#service-img-container").removeClass("service-img-animation animate__animated animate__fadeIn  animate__slideInRight animate__fadeOut");
        setTimeout(function () {
          $("#service-img-container").attr(
            "src",
            require(`@/assets/img/basic_services.svg`)
          );
          $("#service-img-container").addClass("service-img-animation animate__animated animate__fadeIn");

        }, 300);
      }
    );
  }

};
</script>
<style scoped>
.service-img-container {
  height: 100%;
}
.border-1px {
  border-right: 1px solid #0c182c;
  padding-right: 10%;
}
.opacity-0 {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.service-img-animation {
  opacity: 1 !important;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1600px) {
  .service {
    font-size: 30px;
    transition: all 0.6s ease-in-out;
  }
  .service:hover {
    font-size: 20px;
    transition: all 0.6s ease-in-out;
  }
}
</style>
