<template>
    <swiper :spaceBetween="30" :centeredSlides="true" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
    }" :modules="modules" class="services-swiper position-unset">
        <swiper-slide class="d-flex flex-column justify-content-center align-items-center">
            <Service imgSrc="sales-r.svg" name="بيع المنتجات" shortDesc="أي نشاط تجاري يقوم ببيع البضائع" />

        </swiper-slide>
        <swiper-slide class="d-flex flex-column justify-content-center align-items-center">
            <Service imgSrc="reser-r.svg" name="الحجوزات والمواعيد" shortDesc="أي نشاط تجاري يعتمد على الحجز المسبق" />
        </swiper-slide>
    </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
import Service from '../ourServices/service.vue';

export default {
    components: {
        Swiper,
        SwiperSlide,
        Service,
        Service
    },
    props: {
        styleClass: {
            type: String,
        },
    },
    setup() {
        return {
            modules: [Autoplay],
        };
    },
};
</script>
<style scoped>
.position-unset {
    position: unset;
}
</style>