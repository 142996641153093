<template>
  <p class="white" :class="[(mobile ? 'text-center mb-3 basic-light':' mb-4 basic-bold') , styleClass]">
    نرحب دوماً بأسئلتكم واستفساراكم
  </p>
  <form class="text-xl-end text-center">
    <div class="mb-4 pb-1">
      <input type="text" class="form-control f-20 py-3 basic-light border-radius-15" placeholder="الاسم">
    </div>
    <div class="mb-4 pb-1">
      <input type="email" class="form-control f-20 py-3 basic-light border-radius-15" placeholder="البريد الالكتروني">
    </div>
    <div class="mb-4 pb-1">
      <textarea class="form-control f-20 py-3 basic-light border-radius-15" id="textAreaExample1" rows="4"
        placeholder="السؤال"></textarea>

    </div>
    <button type="submit" class="btn btn-primary submit-msg-btn py-2 btn-w-5  basic-bold f-30 w-min-200px h-60px" :class="{'mx-auto':mobile}">إرسال</button>
  </form>
</template>
<script>
import responsiveMixin from '@/mixins/responsiveMixin'

export default {
  name: 'contactUsForm',
  mixins:[responsiveMixin],
  props:{
    styleClass:{
      type:String,
      default:'f-35'
    }
  }
}
</script>
<style scoped>
.w-min-200px {
  min-width: 200px;
}
.w-57.text-center form .btn-primary.submit-msg-btn{
display: flex;
text-align: center;
    margin: auto;
    justify-content: center;
}

</style>