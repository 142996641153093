<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="telegram" :viewBox="!mobile ? '0 0 501.204 833.946':'0 0 501.204 360'">
        <g id="Group_65920" data-name="Group 65920" transform="translate(-797 -4631.771)">
            <g id="Group_49017" data-name="Group 49017" transform="translate(960.421 4942.051)">
                <g id="Group_49007" data-name="Group 49007" transform="translate(0 0.236)">
                    <g id="Group_49003" data-name="Group 49003" transform="translate(0 282.663)">
                        <path id="Path_18385" data-name="Path 18385"
                            d="M559.571,442.5c3.339,1.039,98.054-.989,101.62-3.344,1.347-.882,1.859-14.706,2-31.41q.052-5.415.026-11.119c-.01-4.479-.036-9.017-.088-13.423-.25-19.782-.849-37.2-.849-37.2l-61.663,1.142.718,37.2.262,13.42.524,11.11s-35.913,17-39.538,20.013C558.968,431.881,556.247,441.471,559.571,442.5Z"
                            transform="translate(-557.778 -346.003)" fill="#f7a9a0" />
                        <path id="Path_18386" data-name="Path 18386"
                            d="M559.571,426.733c3.339,1.039,98.054-.989,101.62-3.344,1.347-.882,1.859-14.706,2-31.41q.052-5.415.026-11.119c-.01-4.479-.036-9.017-.088-13.424l-61.794,1.144L601.6,382l.524,11.11s-35.913,17-39.538,20.013C558.968,416.118,556.247,425.708,559.571,426.733Z"
                            transform="translate(-557.778 -330.24)" fill="#fff" />
                        <g id="Group_49002" data-name="Group 49002" transform="translate(0 42.972)">
                            <path id="Path_18387" data-name="Path 18387"
                                d="M559.411,421.239c2.173.672,42.522.069,71.885-1.083,6.794-.273,13.007-.574,18.017-.885,6.907-.446,11.537-.909,12.282-1.4,1.647-1.1,2.07-21.377,2.051-42.759l-61.969,1.147.526,11.166s-36.115,17.094-39.769,20.121C558.807,410.566,556.072,420.185,559.411,421.239Z"
                                transform="translate(-557.901 -367.569)" fill="#d14816" />
                            <g id="Group_49001" data-name="Group 49001" transform="translate(2.545)">
                                <g id="Group_48999" data-name="Group 48999" transform="translate(0 15.787)">
                                    <path id="Path_18388" data-name="Path 18388"
                                        d="M559.618,400.24c15.957-.732,80.387-2.187,96.218-1.7.128.005.13.092,0,.1-15.8,1.074-80.241,2-96.214,1.862C559.286,400.5,559.281,400.256,559.618,400.24Z"
                                        transform="translate(-559.368 -366.197)" fill="#263238" />
                                    <path id="Path_18389" data-name="Path 18389"
                                        d="M582.22,380.432c6.26-1.307,13.963-.509,18.861,3.867.189.168-.054.436-.26.377a88.216,88.216,0,0,0-18.557-3.45A.4.4,0,0,1,582.22,380.432Z"
                                        transform="translate(-542.796 -379.861)" fill="#263238" />
                                    <path id="Path_18390" data-name="Path 18390"
                                        d="M579.641,382.062c6.258-1.307,13.964-.507,18.859,3.867.191.17-.054.437-.259.377a88.356,88.356,0,0,0-18.559-3.448A.4.4,0,0,1,579.641,382.062Z"
                                        transform="translate(-544.693 -378.662)" fill="#263238" />
                                    <path id="Path_18391" data-name="Path 18391"
                                        d="M577.062,383.693c6.258-1.307,13.962-.507,18.859,3.867.189.17-.054.437-.26.377a88.438,88.438,0,0,0-18.557-3.45A.4.4,0,0,1,577.062,383.693Z"
                                        transform="translate(-546.59 -377.463)" fill="#263238" />
                                    <path id="Path_18392" data-name="Path 18392"
                                        d="M574.482,385.324c6.258-1.307,13.963-.507,18.859,3.867.191.17-.054.436-.259.377a88.365,88.365,0,0,0-18.557-3.45A.4.4,0,0,1,574.482,385.324Z"
                                        transform="translate(-548.487 -376.263)" fill="#263238" />
                                </g>
                                <g id="Group_49000" data-name="Group 49000" transform="translate(26.65)">
                                    <path id="Path_18393" data-name="Path 18393"
                                        d="M578.592,381.728c4.517,3.362,10.331,4.648,15.56,6.458a.781.781,0,0,0,.97-.805.167.167,0,0,0,.142-.229,33.535,33.535,0,0,0-9.984-14.186c-2.669-2.147-7.3-3.578-9.7-.125C573.318,376.107,575.954,379.767,578.592,381.728Zm-1.708-5.021c-1.255-6.869,6.223-3.408,8.129-1.748a36.139,36.139,0,0,1,3.407,3.526,100.162,100.162,0,0,1,6.011,8.157c-2.513-1.048-5.1-1.869-7.644-2.822C583.748,382.685,577.577,380.494,576.884,376.708Z"
                                        transform="translate(-574.724 -370.764)" fill="#263238" />
                                    <path id="Path_18394" data-name="Path 18394"
                                        d="M607.984,375.723c-1.6-3.886-6.431-3.476-9.5-1.949A33.531,33.531,0,0,0,585.7,385.5a.168.168,0,0,0,.092.255.778.778,0,0,0,.774.993c5.494-.651,11.45-.666,16.58-2.985C606.142,382.41,609.5,379.4,607.984,375.723ZM594.7,384.057c-2.7.385-5.392.637-8.073,1.123a100.577,100.577,0,0,1,7.617-6.683,36.074,36.074,0,0,1,4.082-2.718c2.216-1.216,10.26-3,7.566,3.443C604.4,382.774,597.906,383.595,594.7,384.057Z"
                                        transform="translate(-566.674 -369.358)" fill="#263238" />
                                </g>
                            </g>
                            <path id="Path_18395" data-name="Path 18395"
                                d="M586.705,417.554c7.974-.2,16.039-.465,23.411-.751,6.792-.257,13-.559,18.017-.876,6.9-.446,11.535-.906,12.276-1.407,1.442-.963,1.957-16.626,2.032-34.865C628.385,389.051,600.759,407.933,586.705,417.554Z"
                                transform="translate(-536.718 -364.226)" opacity="0.2" />
                        </g>
                    </g>
                    <g id="Group_49006" data-name="Group 49006" transform="translate(37.962)">
                        <path id="Path_18396" data-name="Path 18396"
                            d="M580.07,527.895l70.367.071s-3.856-192.677-3.1-196.373c1.815-8.809,41.833-148.467,41.833-148.467l-55.569.954-15.591.333s-1.744,23.257-2.412,25.723c-9.284,33.883-27.411,91.732-31.113,113.18C578.074,360.486,580.07,527.895,580.07,527.895Z"
                            transform="translate(-579.776 -183.126)" fill="#4a576f" />
                        <g id="Group_49005" data-name="Group 49005" transform="translate(2.42 8.874)">
                            <g id="Group_49004" data-name="Group 49004" transform="translate(0 323.326)">
                                <path id="Path_18397" data-name="Path 18397"
                                    d="M581.323,375.356c10.975-.283,49.861-1.44,64.5-.347.111.007.111.193,0,.21-5.015.816-53.517.588-64.5.439A.151.151,0,1,1,581.323,375.356Z"
                                    transform="translate(-581.17 -374.547)" fill="#37474f" />
                            </g>
                            <path id="Path_18398" data-name="Path 18398"
                                d="M615.8,324.334c2.1-8.563,17.773-61.908,22.568-78.759s9.292-33.768,13.427-50.779c.524-2.157,1.055-4.309,1.63-6.454.049-.181.309-.1.276.076-1.581,8.566-3.672,17.061-5.685,25.534s-4.162,16.926-6.421,25.343q-6.812,25.37-14.866,50.385c-2.685,8.306-7.11,25.363-9.465,33.772-2.352,8.392,1.493,180.623,1.248,182.86a.5.5,0,0,1-1-.007C617.208,490.049,613.729,332.794,615.8,324.334Z"
                                transform="translate(-556.182 -188.239)" fill="#37474f" />
                        </g>
                    </g>
                    <path id="Path_18399" data-name="Path 18399"
                        d="M610.787,184.074s13.589,148.232,13.739,147.512c1.753-8.526,39.37-139.855,41.734-148.066.078-.264.108-.394.108-.394Z"
                        transform="translate(-519.007 -183.126)" fill="#37474f" />
                </g>
                <g id="Group_49016" data-name="Group 49016" transform="translate(74.785)">
                    <g id="Group_49012" data-name="Group 49012" transform="translate(0 291.017)">
                        <path id="Path_18400" data-name="Path 18400"
                            d="M602.664,447.175c3.339,1.04,98.054-.989,101.62-3.346,1.347-.88,1.859-14.7,2-31.408q.052-5.415.026-11.119c-.01-4.479-.036-9.017-.089-13.424-.25-19.784-2.28-37.2-2.28-37.2l-60.232,1.14.718,37.2.262,13.42.526,11.109s-35.915,17-39.54,20.015C602.061,436.559,599.34,446.149,602.664,447.175Z"
                            transform="translate(-600.871 -350.681)" fill="#f7a9a0" />
                        <path id="Path_18401" data-name="Path 18401"
                            d="M602.664,431.411c3.339,1.04,98.054-.989,101.62-3.346,1.347-.88,1.859-14.7,2-31.408q.052-5.415.026-11.119c-.01-4.479-.036-9.017-.089-13.424l-61.794,1.144.262,13.42.526,11.109s-35.915,17-39.54,20.015C602.061,420.8,599.34,430.386,602.664,431.411Z"
                            transform="translate(-600.871 -334.918)" fill="#fff" />
                        <g id="Group_49011" data-name="Group 49011" transform="translate(0 42.972)">
                            <path id="Path_18402" data-name="Path 18402"
                                d="M602.5,425.917c2.173.67,42.522.068,71.885-1.085,6.794-.271,13.009-.573,18.019-.883,6.905-.448,11.537-.909,12.282-1.4,1.647-1.1,2.068-21.377,2.049-42.761l-61.969,1.147L645.3,392.1s-36.116,17.094-39.771,20.119C601.9,415.244,599.165,424.864,602.5,425.917Z"
                                transform="translate(-600.994 -372.248)" fill="#d14816" />
                            <g id="Group_49010" data-name="Group 49010" transform="translate(2.545)">
                                <g id="Group_49008" data-name="Group 49008" transform="translate(0 15.787)">
                                    <path id="Path_18403" data-name="Path 18403"
                                        d="M602.711,404.918c15.957-.734,80.387-2.187,96.218-1.7.129.005.13.092,0,.1-15.8,1.073-80.241,2-96.214,1.862C602.379,405.177,602.374,404.934,602.711,404.918Z"
                                        transform="translate(-602.461 -370.874)" fill="#263238" />
                                    <path id="Path_18404" data-name="Path 18404"
                                        d="M625.315,385.11c6.258-1.309,13.962-.509,18.859,3.865.189.17-.054.437-.259.378a88.249,88.249,0,0,0-18.559-3.45A.4.4,0,0,1,625.315,385.11Z"
                                        transform="translate(-585.889 -384.539)" fill="#263238" />
                                    <path id="Path_18405" data-name="Path 18405"
                                        d="M622.736,386.74c6.256-1.307,13.962-.507,18.857,3.867.191.17-.052.437-.259.377a88.353,88.353,0,0,0-18.557-3.448A.4.4,0,0,1,622.736,386.74Z"
                                        transform="translate(-587.786 -383.34)" fill="#263238" />
                                    <path id="Path_18406" data-name="Path 18406"
                                        d="M620.155,388.371c6.258-1.307,13.963-.507,18.859,3.867.191.17-.054.437-.259.377a88.357,88.357,0,0,0-18.559-3.45A.4.4,0,0,1,620.155,388.371Z"
                                        transform="translate(-589.683 -382.14)" fill="#263238" />
                                    <path id="Path_18407" data-name="Path 18407"
                                        d="M617.576,390c6.258-1.309,13.962-.507,18.859,3.867.189.168-.054.436-.26.377a88.325,88.325,0,0,0-18.557-3.45A.4.4,0,0,1,617.576,390Z"
                                        transform="translate(-591.58 -380.941)" fill="#263238" />
                                </g>
                                <g id="Group_49009" data-name="Group 49009" transform="translate(26.651)">
                                    <path id="Path_18408" data-name="Path 18408"
                                        d="M621.685,386.406c4.517,3.362,10.333,4.647,15.562,6.458.541.187.96-.328.968-.805a.167.167,0,0,0,.142-.229,33.518,33.518,0,0,0-9.984-14.185c-2.669-2.147-7.3-3.579-9.7-.125C616.413,380.785,619.047,384.445,621.685,386.406Zm-1.708-5.021c-1.255-6.871,6.223-3.408,8.129-1.748a36.125,36.125,0,0,1,3.407,3.526,100.876,100.876,0,0,1,6.014,8.155c-2.515-1.046-5.1-1.869-7.647-2.82C626.841,387.363,620.669,385.172,619.977,381.386Z"
                                        transform="translate(-617.818 -375.442)" fill="#263238" />
                                    <path id="Path_18409" data-name="Path 18409"
                                        d="M651.076,380.4c-1.6-3.884-6.432-3.476-9.5-1.947a33.5,33.5,0,0,0-12.783,11.725.166.166,0,0,0,.09.253.78.78,0,0,0,.774.994c5.494-.651,11.45-.667,16.582-2.985C649.236,387.088,652.593,384.075,651.076,380.4Zm-13.288,8.334c-2.695.387-5.392.639-8.071,1.125a100.239,100.239,0,0,1,7.615-6.683,36.1,36.1,0,0,1,4.082-2.718c2.216-1.217,10.262-3,7.568,3.443C647.5,387.452,641,388.273,637.788,388.733Z"
                                        transform="translate(-609.767 -374.036)" fill="#263238" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Group_49015" data-name="Group 49015" transform="translate(15.455)">
                        <path id="Path_18410" data-name="Path 18410"
                            d="M634.642,535.164l68.281-1.265s-17.66-280.363-16.79-293.6c1.069-16.225.58-45.736-9.7-57.311L609.9,184.222s1.768,21.566,3.934,28.406c0,0,8.479,5.146,11.546,6.831Z"
                            transform="translate(-609.9 -182.99)" fill="#4a576f" />
                        <g id="Group_49014" data-name="Group 49014" transform="translate(1.245 12.472)">
                            <path id="Path_18411" data-name="Path 18411"
                                d="M625.3,379.624c10.353-.463,7.377-.476,17.734-.823,5.069-.17,38.379-1.255,43.134-.583a.111.111,0,0,1,.005.212c-4.719.9-38.043,1.4-43.114,1.472-10.362.147-7.389-.007-17.752.024A.151.151,0,0,1,625.3,379.624Z"
                                transform="translate(-599.924 -52.044)" fill="#37474f" />
                            <g id="Group_49013" data-name="Group 49013" transform="translate(0 1.162)">
                                <path id="Path_18412" data-name="Path 18412"
                                    d="M611.267,199.164A128.02,128.02,0,0,1,630.178,209.9a.218.218,0,0,1-.22.377c-6.393-3.384-12.929-6.661-19.123-10.38A.425.425,0,0,1,611.267,199.164Z"
                                    transform="translate(-610.618 -184.762)" fill="#37474f" />
                                <path id="Path_18413" data-name="Path 18413"
                                    d="M616.42,198.7a30.47,30.47,0,0,1,.977,3.915,8.415,8.415,0,0,1,.258,3.167c-.043.2-.321.193-.42.045a10.789,10.789,0,0,1-1.093-3.16c-.34-1.215-.708-2.424-1.012-3.646C614.928,198.208,616.162,197.909,616.42,198.7Z"
                                    transform="translate(-607.315 -185.411)" fill="#37474f" />
                                <path id="Path_18414" data-name="Path 18414"
                                    d="M619.062,197.713c-.2-1.107-.4-2.214-.62-3.318-.229-1.14-.534-2.282-.675-3.438-.023-.182-.316-.123-.283.062.2,1.109.236,2.258.345,3.379s.239,2.249.373,3.372c.248,2.091.647,4.2,1.1,6.261a7.449,7.449,0,0,1-3.082-1.213,5.88,5.88,0,0,1-1.7-3.171c-.351-1.265-.649-2.548-1.019-3.808a24.849,24.849,0,0,1-1.053-4.238c-.019-.184-.309-.123-.283.061a45.612,45.612,0,0,0,1.859,8.932c.4,1.144.682,2.3,1.728,2.933a9.565,9.565,0,0,0,3.745,1.034c.146.076.368.017.354-.184A67.115,67.115,0,0,0,619.062,197.713Z"
                                    transform="translate(-609.478 -190.846)" fill="#37474f" />
                            </g>
                            <path id="Path_18415" data-name="Path 18415"
                                d="M647.923,234.322c.386-4.9,1.094-9.767,1.536-14.661a90.813,90.813,0,0,0-2.313-29.253c-.043-.179.238-.253.276-.073,1.782,8.479,3.356,16.953,3.091,25.658-.262,8.629-1.633,17.162-1.612,25.809.023,8.755.873,17.491,1.595,26.21q1.094,13.159,2.183,26.318c2.924,35.259,5.671,104.812,8.474,140.081.783,9.843,1.522,19.692,2.275,29.539.765,10.019,1.923,34.736,2.482,44.769.049.868-1.244.826-1.331-.007-1.829-17.684-3.3-50.149-4.758-67.868-1.442-17.54-2.8-35.089-4.195-52.632s-2.775-69.365-4.144-86.911q-1.025-13.156-2.011-26.316c-.666-8.738-1.473-17.479-1.767-26.24A129.088,129.088,0,0,1,647.923,234.322Z"
                                transform="translate(-583.757 -190.136)" fill="#37474f" />
                            <path id="Path_18416" data-name="Path 18416"
                                d="M633.617,190.278a.144.144,0,0,1,.26-.031c.566.935.5,2.5.7,3.575a25.483,25.483,0,0,0,.98,3.847,15.412,15.412,0,0,0,4.118,6.642c4.26,3.842,9.894,5.187,15.326,6.412.2.045.133.319-.047.337a24.376,24.376,0,0,1-9.451-1.3,19.735,19.735,0,0,1-7.355-3.939,13.6,13.6,0,0,1-4.061-7.044,24.185,24.185,0,0,1-.529-4.3c-.04-.692-.059-1.388-.069-2.083A5.333,5.333,0,0,1,633.617,190.278Z"
                                transform="translate(-593.829 -190.177)" fill="#37474f" />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Group_49024" data-name="Group 49024" transform="translate(927.099 4774.029)">
                <g id="Group_49022" data-name="Group 49022">
                    <path id="Path_18417" data-name="Path 18417"
                        d="M670.579,156c-13.432,12.152-61.238,32.685-74.882,29.379-9.87-2.391-41.475-45.559-48.032-57.472-3.82-6.94,37.024-19.274,41.012-12.664,5.63,9.333,19.036,33.166,22.471,35.163,1.171.68,19.263-3.981,50.456-8.132C678.182,140.063,678.6,148.742,670.579,156Z"
                        transform="matrix(0.999, -0.035, 0.035, 0.999, -536.321, -43.029)" fill="#f7a9a0" />
                    <g id="Group_49021" data-name="Group 49021"
                        transform="matrix(0.999, -0.035, 0.035, 0.999, -2.042, 4.538)">
                        <g id="Group_49019" data-name="Group 49019">
                            <path id="Path_18418" data-name="Path 18418"
                                d="M554.756,148.963c-1.371-2.306-13.259-19.1-15.171-30.7-1.014-6.147-1.892-23.359,1.77-24.442,3.344-.989,4.743,3.667,4.743,3.667s-.28-7.678,4.384-9,6.621,6.659,6.621,6.659.288-7.908,5.033-8.892,5.368,7.369,5.368,7.369-.073-7.242,4.179-6.409c5.113,1,3.742,14.369,6.045,20.869.522,1.477,12.735,29.164,12.735,29.164Z"
                                transform="translate(-538.7 -86.172)" fill="#f7a9a0" />
                            <g id="Group_49018" data-name="Group 49018" transform="translate(7.132 7.173)">
                                <path id="Path_18419" data-name="Path 18419"
                                    d="M556.09,90.7c-.986,9.831.283,14.3,4.628,23,.085.17-.149.335-.253.165-5.031-8.134-7.433-13.514-4.849-23.243C555.743,90.15,556.136,90.23,556.09,90.7Z"
                                    transform="translate(-534.164 -90.306)" fill="#263238" />
                                <path id="Path_18420" data-name="Path 18420"
                                    d="M549.873,91.525c-.132,10.3,1.2,15.163,5.083,24.612.056.134-.165.241-.233.111-4.583-8.951-6.855-14.373-5.371-24.648C549.42,91.135,549.88,91.053,549.873,91.525Z"
                                    transform="translate(-538.331 -89.642)" fill="#263238" />
                                <path id="Path_18421" data-name="Path 18421"
                                    d="M543.277,93.139c.592,9.982,1.824,15.378,6.77,24.067a.185.185,0,0,1-.309.2c-5.9-8.372-7.492-15.091-6.763-24.218C543.025,92.568,543.24,92.511,543.277,93.139Z"
                                    transform="translate(-542.81 -88.548)" fill="#263238" />
                            </g>
                        </g>
                        <g id="Group_49020" data-name="Group 49020" transform="translate(32.514 11.75)">
                            <path id="Path_18422" data-name="Path 18422"
                                d="M582.731,136.228s2.253-15.532,1.177-21-12.58-24.355-16.8-22.1c-5.449,2.909-.958,15.123,2.025,20.817,0,0-12.615,6.718-11.286,16.181S582.731,136.228,582.731,136.228Z"
                                transform="translate(-557.205 -92.943)" fill="#f7a9a0" />
                            <path id="Path_18423" data-name="Path 18423"
                                d="M566.1,93.813c-3.056,6.239,1.34,13.462,3.82,19.2a.571.571,0,0,1-.458.821c-5.1,3.169-11.369,9.123-11.7,15.451a.144.144,0,0,1-.288.009c-.568-6.834,5.116-13.186,10.923-16.3-2.634-5.847-6.727-13.285-2.355-19.213C566.068,93.747,566.121,93.773,566.1,93.813Z"
                                transform="translate(-557.435 -92.338)" fill="#263238" />
                        </g>
                    </g>
                </g>
                <g id="Group_65935" data-name="Group 65935" transform="translate(43.333, -12.413)">
                    <path id="Path_18417" data-name="Path 18417"
                        d="M670.579,156c-13.432,12.152-61.238,32.685-74.882,29.379-9.87-2.391-41.475-45.559-48.032-57.472-3.82-6.94,37.024-19.274,41.012-12.664,5.63,9.333,19.036,33.166,22.471,35.163,1.171.68,12.486-1.466,37.973-.935C662.653,152.867,678.6,148.742,670.579,156Z"
                        transform="matrix(0.978, -0.208, 0.208, 0.978, -581.754, 75.107)" fill="#f7a9a0" />
                    <g id="Group_49021" data-name="Group 49021"
                        transform="matrix(0.978, -0.208, 0.208, 0.978, -47.333, 29.175)">
                        <g id="Group_49019" data-name="Group 49019">
                            <path id="Path_18418" data-name="Path 18418"
                                d="M554.756,148.963c-1.371-2.306-13.259-19.1-15.171-30.7-1.014-6.147-1.892-23.359,1.77-24.442,3.344-.989,4.743,3.667,4.743,3.667s-.28-7.678,4.384-9,6.621,6.659,6.621,6.659.288-7.908,5.033-8.892,5.368,7.369,5.368,7.369-.073-7.242,4.179-6.409c5.113,1,3.742,14.369,6.045,20.869.522,1.477,12.735,29.164,12.735,29.164Z"
                                transform="translate(-538.7 -86.172)" fill="#f7a9a0" />
                            <g id="Group_49018" data-name="Group 49018" transform="translate(7.132 7.173)">
                                <path id="Path_18419" data-name="Path 18419"
                                    d="M556.09,90.7c-.986,9.831.283,14.3,4.628,23,.085.17-.149.335-.253.165-5.031-8.134-7.433-13.514-4.849-23.243C555.743,90.15,556.136,90.23,556.09,90.7Z"
                                    transform="translate(-534.164 -90.306)" fill="#263238" />
                                <path id="Path_18420" data-name="Path 18420"
                                    d="M549.873,91.525c-.132,10.3,1.2,15.163,5.083,24.612.056.134-.165.241-.233.111-4.583-8.951-6.855-14.373-5.371-24.648C549.42,91.135,549.88,91.053,549.873,91.525Z"
                                    transform="translate(-538.331 -89.642)" fill="#263238" />
                                <path id="Path_18421" data-name="Path 18421"
                                    d="M543.277,93.139c.592,9.982,1.824,15.378,6.77,24.067a.185.185,0,0,1-.309.2c-5.9-8.372-7.492-15.091-6.763-24.218C543.025,92.568,543.24,92.511,543.277,93.139Z"
                                    transform="translate(-542.81 -88.548)" fill="#263238" />
                            </g>
                        </g>
                        <g id="Group_49020" data-name="Group 49020" transform="translate(32.514 11.75)">
                            <path id="Path_18422" data-name="Path 18422"
                                d="M582.731,136.228s2.253-15.532,1.177-21-12.58-24.355-16.8-22.1c-5.449,2.909-.958,15.123,2.025,20.817,0,0-12.615,6.718-11.286,16.181S582.731,136.228,582.731,136.228Z"
                                transform="translate(-557.205 -92.943)" fill="#f7a9a0" />
                            <path id="Path_18423" data-name="Path 18423"
                                d="M566.1,93.813c-3.056,6.239,1.34,13.462,3.82,19.2a.571.571,0,0,1-.458.821c-5.1,3.169-11.369,9.123-11.7,15.451a.144.144,0,0,1-.288.009c-.568-6.834,5.116-13.186,10.923-16.3-2.634-5.847-6.727-13.285-2.355-19.213C566.068,93.747,566.121,93.773,566.1,93.813Z"
                                transform="translate(-557.435 -92.338)" fill="#263238" />
                        </g>
                    </g>
                </g>
                <g id="Group_49023" data-name="Group 49023" transform="translate(92.365 68.476)">
                    <path id="Path_18424" data-name="Path 18424"
                        d="M605.057,166.139l-13.134-30.721s22.9-6.779,41.828-6.256,11.525,12.591.958,19.293S605.057,166.139,605.057,166.139Z"
                        transform="translate(-591.923 -123.053)" fill="#e95c29" />
                    <path id="Path_18425" data-name="Path 18425"
                        d="M640.185,139.392c-.972,3.835-5.363,8.382-10.5,11.645-10.569,6.7-29.659,17.684-29.659,17.684l-1.267-2.985L631.7,131.93l6.143-6.3s.85,4.408,1.683,9.562C639.768,136.563,639.977,137.986,640.185,139.392Z"
                        transform="translate(-586.895 -125.63)" fill="#d14816" />
                    <path id="Path_18426" data-name="Path 18426"
                        d="M596.071,132.627c4.313,13.149,10.218,24.034,11.211,26.419.049.113.245.035.189-.076-1.109-2.3-6.159-15.8-11.192-26.427A.113.113,0,0,0,596.071,132.627Z"
                        transform="translate(-588.877 -120.591)" fill="#263238" />
                </g>
            </g>
            <g id="Group_49027" data-name="Group 49027" transform="translate(1027.794 4845.125)">
                <g id="Group_49025" data-name="Group 49025" transform="translate(0)">
                    <path id="Path_18427" data-name="Path 18427"
                        d="M699.027,255.987c-.236.26-101.346-.531-102.255-1.579-1.27-1.475,22.75-110.768,33.274-124.188,2.253-2.869,9.583-3.594,15.8-2.751a27.583,27.583,0,0,1,4.783,1.031.752.752,0,0,0,.16.045,11.463,11.463,0,0,1,3.5,1.763c17.169,14.014,31.215,62.5,34.462,77.05C694.137,231.329,699.87,255.046,699.027,255.987Z"
                        transform="translate(-596.723 -127.139)" fill="#e95c29" />
                </g>
            </g>
            <g id="Group_49042" data-name="Group 49042" transform="translate(1041.669 4774.789)">
                <g id="Group_49029" data-name="Group 49029" transform="translate(17.952 50.976)">
                    <g id="Group_49028" data-name="Group 49028">
                        <path id="Path_18430" data-name="Path 18430"
                            d="M619.787,152.4c5.5,1.649,15.584-6.319,19.773-13.009.285-.46-.149-5.5-.385-11.643-.13-3.729-.186-7.886.038-11.683.049-.779-24.15,4.387-24.15,4.387s1.873,8.1,1.444,14.713a8.513,8.513,0,0,1-.255,1.548c-.012.071-.038.156-.05.255C615.546,139.9,614.47,150.8,619.787,152.4Z"
                            transform="translate(-615.063 -115.984)" fill="#f7a9a0" />
                    </g>
                    <path id="Path_18431" data-name="Path 18431"
                        d="M637.744,116.038c-1.482,6.353-6.385,21.627-19.107,21.169a14.434,14.434,0,0,1-2.428-.276,1.192,1.192,0,0,1,.045-.259,9.4,9.4,0,0,0,.26-1.543c.427-6.629-1.451-14.723-1.451-14.723S632.52,116.678,637.744,116.038Z"
                        transform="translate(-615.063 -115.944)" fill="#263238" />
                </g>
                <g id="Group_49039" data-name="Group 49039" transform="translate(2.949 1.929)">
                    <path id="Path_18432" data-name="Path 18432"
                        d="M614.754,95.361s-7.679,2.048-8.27,10.281,2.909,15.3,3.653,15.638S614.754,95.361,614.754,95.361Z"
                        transform="translate(-606.418 -82.104)" fill="#263238" />
                    <g id="Group_49030" data-name="Group 49030" transform="translate(1.984 6.518)">
                        <path id="Path_18433" data-name="Path 18433"
                            d="M646.126,103.6c3.24,8.228.717,32.935-4.851,38.841-8.07,8.559-22.243,10.439-29.759.519-7.285-9.616-3.426-41.758,2.881-47.223C623.688,87.69,641.34,91.449,646.126,103.6Z"
                            transform="translate(-607.561 -91.478)" fill="#f7a9a0" />
                </g>
                <g id="Group_49036" data-name="Group 49036" transform="translate(5.06 26.761)">
                    <g id="Group_49031" data-name="Group 49031" transform="translate(14.031 7.029)">
                        <path id="Path_18434" data-name="Path 18434"
                            d="M617.676,108.117c.019-.062.123.092.115.16-.215,1.659-.182,3.6,1.27,4.3.045.021.033.1-.023.092C617.225,112.228,617.208,109.655,617.676,108.117Z"
                            transform="translate(-617.418 -106.524)" fill="#263238" />
                        <path id="Path_18435" data-name="Path 18435"
                            d="M619.3,107.193c2.7.056,2.386,5.448-.113,5.394C616.723,112.537,617.036,107.145,619.3,107.193Z"
                            transform="translate(-617.383 -107.193)" fill="#263238" />
                    </g>
                    <g id="Group_49032" data-name="Group 49032" transform="translate(0.967 6.284)">
                        <path id="Path_18436" data-name="Path 18436"
                            d="M612.914,107.835c-.009-.064-.135.071-.137.139-.043,1.673-.377,3.582-1.918,4.049-.049.016-.049.1.007.1C612.725,111.965,613.141,109.427,612.914,107.835Z"
                            transform="translate(-609.204 -105.987)" fill="#263238" />
                        <path id="Path_18437" data-name="Path 18437"
                            d="M612.21,106.78c-2.674-.359-3.2,5.015-.72,5.349C613.928,112.457,614.452,107.081,612.21,106.78Z"
                            transform="translate(-609.89 -106.763)" fill="#263238" />
                    </g>
                    <path id="Path_18438" data-name="Path 18438"
                        d="M621.565,107.516a21.1,21.1,0,0,1-2.046-.856,3.589,3.589,0,0,1-1.92-1.272,1.14,1.14,0,0,1,.264-1.369,2.832,2.832,0,0,1,2.865-.127,4.151,4.151,0,0,1,2.289,1.937A1.217,1.217,0,0,1,621.565,107.516Z"
                        transform="translate(-603.348 -102.83)" fill="#263238" />
                    <path id="Path_18439" data-name="Path 18439"
                        d="M610.692,106.607a20.51,20.51,0,0,0,2.175-.432,3.58,3.58,0,0,0,2.135-.863,1.143,1.143,0,0,0,.016-1.4,2.832,2.832,0,0,0-2.784-.694,4.161,4.161,0,0,0-2.629,1.44A1.218,1.218,0,0,0,610.692,106.607Z"
                        transform="translate(-609.333 -103.142)" fill="#263238" />
                    <path id="Path_18440" data-name="Path 18440"
                        d="M619.364,117.156c-.422.368-.852.883-1.461.9a4.408,4.408,0,0,1-1.772-.524.058.058,0,0,0-.076.082,2.246,2.246,0,0,0,2.067,1.029,1.78,1.78,0,0,0,1.4-1.4C619.552,117.146,619.427,117.1,619.364,117.156Z"
                        transform="translate(-604.397 -92.853)" fill="#263238" />
                    <g id="Group_49034" data-name="Group 49034" transform="translate(3.895 0.657)">
                        <g id="Group_49033" data-name="Group 49033">
                            <path id="Path_18441" data-name="Path 18441"
                                d="M621.185,122.064a5.7,5.7,0,0,1-3.943,2.312,7.2,7.2,0,0,1-2.051-.026,1.959,1.959,0,0,1-.387-.068c-.122-.021-.231-.042-.363-.076a.418.418,0,0,1-.314-.394l0-.012a.72.72,0,0,1,.024-.17l0-.012.012-.148c.038-1.286.276-3.235.276-3.235-.482.208-2.942,1.147-2.863.477.654-5.543,1.381-11.706,3.2-17.082a.155.155,0,0,1,.3.061c-.418,5.35-1.774,10.591-2.281,15.961a9.982,9.982,0,0,1,2.732-.659c.179.063-.528,3.757-.5,4.337a.167.167,0,0,0,.007.062c2.145.4,3.811-.156,5.992-1.486C621.168,121.818,621.293,121.937,621.185,122.064Z"
                                transform="translate(-611.578 -103.521)" fill="#263238" />
                        </g>
                    </g>
                    <g id="Group_49035" data-name="Group 49035" transform="translate(8.295 19.025)">
                        <path id="Path_18442" data-name="Path 18442"
                            d="M614.113,116.054a6.751,6.751,0,0,0,2.676,2.135,3.034,3.034,0,0,0,1.845.141c1.34-.331,1.392-1.557,1.161-2.61a7.35,7.35,0,0,0-.576-1.614A8.743,8.743,0,0,1,614.113,116.054Z"
                            transform="translate(-614.113 -114.105)" fill="#263238" />
                        <path id="Path_18443" data-name="Path 18443"
                            d="M615.655,117.5a3.034,3.034,0,0,0,1.845.141c1.34-.332,1.392-1.557,1.161-2.61A3.143,3.143,0,0,0,615.655,117.5Z"
                            transform="translate(-612.979 -113.421)" fill="#ff98b9" />
                    </g>
                </g>
                <g id="Group_49037" data-name="Group 49037" transform="translate(3.718)">
                    <path id="Path_18444" data-name="Path 18444"
                        d="M643.971,128.2c-2.778.536-5-7.886-5.546-11.089-.477-2.825-.43-11.615-.5-12.223s-12.556,6.435-19.124,3.724-10.446-9.283-10.234-12.134,9.406-9.154,16.731-8.74,16.192,10.732,16.192,10.732-2.921-4.359-2.771-4.675,4.654,2.07,5.161,5.515c0,0-.481-4.307-.116-4.349s3.636,3.959,2.516,6.6c0,0,3.837,4.12,3.8,8.179S647.236,127.573,643.971,128.2Z"
                        transform="translate(-608.56 -87.722)" fill="#263238" />
                    <path id="Path_18445" data-name="Path 18445"
                        d="M611.79,100.491a22.881,22.881,0,0,0,6.161,2.981,15.373,15.373,0,0,0,7.436-.387,23.2,23.2,0,0,0,6.82-3.106c1.845-1.213,3.53-2.735,5.475-3.785.486-.262.941.446.618.833a24.511,24.511,0,0,1-13.278,7.735,14.071,14.071,0,0,1-13.264-4.221C611.738,100.521,611.759,100.472,611.79,100.491Z"
                        transform="translate(-606.214 -81.533)" fill="#263238" />
                </g>
                <g id="Group_49038" data-name="Group 49038" transform="translate(35.912 32.2)">
                    <path id="Path_18446" data-name="Path 18446"
                        d="M628.114,112.98s5.05-8.212,8.743-6.458.224,13.434-3.885,15.246a4.28,4.28,0,0,1-5.861-1.959Z"
                        transform="translate(-627.111 -106.276)" fill="#f7a9a0" />
                    <path id="Path_18447" data-name="Path 18447"
                        d="M634.8,108.333c.068-.01.085.083.029.111-2.876,1.368-4.266,4.191-5.185,7.086.812-1.14,1.97-1.808,3.492-.852.08.05.014.175-.071.161a2.635,2.635,0,0,0-2.837,1.088,12.688,12.688,0,0,0-1.248,2.423c-.122.271-.606.158-.524-.151l.021-.069C628.178,114.378,630.788,108.949,634.8,108.333Z"
                        transform="translate(-626.13 -104.764)" fill="#263238" />
                </g>
            </g>
            <g id="Group_49041" data-name="Group 49041">
                <path id="Path_18448" data-name="Path 18448"
                    d="M611.221,98.088c2.489-.245,15.609,2.492,20.99,6.452s14.09,15.586,14.538,16.2,8.33-24.279-8.46-31.9C619.994,80.539,611.221,98.088,611.221,98.088Z"
                    transform="translate(-599.936 -86.61)" fill="#e95c29" />
                <g id="Group_49040" data-name="Group 49040" transform="translate(0 0.175)">
                    <path id="Path_18449" data-name="Path 18449"
                        d="M650.707,119.464S642.332,99.76,636.6,94.6s-19.819-9.956-29.634-6.971c-7.655,2.329,6.708,15.763,6.708,15.763s1.8-2.909,4.243-3.544c4.741-1.232,12.752,1.737,17.143,4.384C640.582,107.564,650.707,119.464,650.707,119.464Z"
                        transform="translate(-604.719 -86.711)" fill="#ffc727" />
                    <path id="Path_18450" data-name="Path 18450"
                        d="M650.707,119.464S642.332,99.76,636.6,94.6s-19.819-9.956-29.634-6.971c-7.655,2.329,6.708,15.763,6.708,15.763s1.8-2.909,4.243-3.544c4.741-1.232,12.752,1.737,17.143,4.384C640.582,107.564,650.707,119.464,650.707,119.464Z"
                        transform="translate(-604.719 -86.711)" fill="#d14816" />
                </g>
            </g>
        </g>
        <rect id="Rectangle_12083" data-name="Rectangle 12083" width="9.441" height="281.035"
            transform="translate(843.908 4693.34)" fill="#4a576f" />
        <rect id="Rectangle_12085" data-name="Rectangle 12085" width="9.441" height="281.035"
            transform="translate(1241.854 4693.34)" fill="#4a576f" />
        <g id="Group_51412" data-name="Group 51412" transform="translate(843 4995.717)">
            <rect id="Rectangle_12087" data-name="Rectangle 12087" width="409.448" height="470"
                transform="translate(409.448 470) rotate(180)" fill="#1c324c" />
            <rect id="Rectangle_12088" data-name="Rectangle 12088" width="409.448" height="470"
                transform="translate(409.448 470) rotate(180)" fill="#1c324c" opacity="0.4" />
        </g>
        <rect id="Rectangle_12103" data-name="Rectangle 12103" width="468.713" height="21.342"
            transform="translate(813.245 4974.375)" fill="#4a576f" />
        <g id="Group_57122" data-name="Group 57122" transform="translate(49 4415.717)">
            <g id="Group_51424" data-name="Group 51424" transform="translate(748 299.85)">
                <path id="Path_20447" data-name="Path 20447"
                    d="M103.335,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621H64.8c-6.609,0-11.962-3.444-11.962-11.621v-23.9Z"
                    transform="translate(-52.84 -91.667)" fill="#1c324c" />
                <path id="Path_20448" data-name="Path 20448"
                    d="M133.659,91.667v23.9c0,8.177-5.353,11.621-11.945,11.621H95.124c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-32.684 -91.667)" fill="#1c324c" />
                <path id="Path_20449" data-name="Path 20449"
                    d="M163.987,91.667v23.9c0,8.177-5.353,11.621-11.945,11.621h-26.59c-6.593,0-11.943-3.444-11.943-11.621v-23.9Z"
                    transform="translate(-38.383 -91.667)" fill="#1c324c" />
                <path id="Path_20450" data-name="Path 20450"
                    d="M194.333,91.667v23.9c0,8.177-5.353,11.621-11.962,11.621H155.8c-6.609,0-11.96-3.444-11.96-11.621v-23.9Z"
                    transform="translate(-31.157 -91.667)" fill="#1c324c" />
                <path id="Path_20451" data-name="Path 20451"
                    d="M224.655,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621h-26.59c-6.609,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-23.927 -91.667)" fill="#1c324c" />
                <path id="Path_20452" data-name="Path 20452"
                    d="M254.984,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621h-26.59c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-16.7 -91.667)" fill="#1c324c" />
                <path id="Path_20453" data-name="Path 20453"
                    d="M285.313,91.667v23.9c0,8.177-5.334,11.621-11.943,11.621H246.78c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-9.473 -91.667)" fill="#1c324c" />
                <path id="Path_20454" data-name="Path 20454"
                    d="M315.661,91.667v23.9c0,8.177-5.353,11.621-11.962,11.621H277.126c-6.609,0-11.962-3.444-11.962-11.621v-23.9Z"
                    transform="translate(-2.249 -91.667)" fill="#1c324c" />
                <path id="Path_20455" data-name="Path 20455"
                    d="M345.982,91.667v23.9c0,8.177-5.353,11.621-11.943,11.621H307.447c-6.609,0-11.943-3.444-11.943-11.621v-23.9Z"
                    transform="translate(4.984 -91.667)" fill="#1c324c" />
                <path id="Path_20456" data-name="Path 20456"
                    d="M376.311,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621h-26.59c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(12.211 -91.667)" fill="#1c324c" />
                <path id="Path_20457" data-name="Path 20457"
                    d="M406.64,91.667v23.9c0,8.177-5.334,11.621-11.943,11.621h-26.59c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(19.438 -91.667)" fill="#1c324c" />
                <path id="Path_20458" data-name="Path 20458"
                    d="M436.988,91.667v23.9c0,8.177-5.353,11.621-11.962,11.621H398.453c-6.609,0-11.962-3.444-11.962-11.621v-23.9Z"
                    transform="translate(13.738 -91.667)" fill="#1c324c" />
                <path id="Path_20459" data-name="Path 20459"
                    d="M467.309,91.667v23.9c0,8.177-5.353,11.621-11.943,11.621H428.774c-6.593,0-11.943-3.444-11.943-11.621v-23.9Z"
                    transform="translate(33.894 -91.667)" fill="#1c324c" />
            </g>
            <path id="Path_20460" data-name="Path 20460"
                d="M554.044,125.115H52.84s31.1-33.91,49.112-83.794H504.931a275.437,275.437,0,0,0,30.648,59.631A227.692,227.692,0,0,0,554.044,125.115Z"
                transform="translate(695.16 174.735)" fill="#37445d" />
            <rect id="Rectangle_12105" data-name="Rectangle 12105" width="1.699" height="119.32"
                transform="translate(999.566 216.054)" fill="#fff" />
            <path id="Path_20461" data-name="Path 20461"
                d="M222.053,125.11c-.05.378-.068.588-.068.629l.018,34.9h-1.7V125.615q0-.065.05-.5c.561-5.782,5.829-58.945,6.644-83.79h1.7C227.9,66.061,222.682,118.741,222.053,125.11Z"
                transform="translate(740.451 174.734)" fill="#fff" />
            <path id="Path_20463" data-name="Path 20463"
                d="M203.581,41.32c-1.376,24.909-10.364,76.705-11.6,83.79-.052.273-.1.483-.12.609v34.92h-1.7l.018-35.256c0-.022.017-.1.05-.273.764-4.331,10.211-58.5,11.639-83.79Z"
                transform="translate(732.822 174.734)" fill="#fff" />
            <path id="Path_20465" data-name="Path 20465"
                d="M178.128,41.32c-1.7,28.126-14.731,76.832-16.617,83.79-.085.315-.153.524-.186.672v34.857h-1.7l.052-35.361a1.068,1.068,0,0,1,.05-.168c.9-3.28,14.918-54.739,16.7-83.79Z"
                transform="translate(725.31 174.734)" fill="#fff" />
            <path id="Path_20467" data-name="Path 20467"
                d="M158.429,41.32c-2.31,31.994-22.937,76.223-26.573,83.79-.17.336-.306.609-.391.8v34.73h-1.7V125.32l.1-.21.017-.043c.255-.524,24.28-49.819,26.844-83.747Z"
                transform="translate(717.926 174.734)" fill="#fff" />
            <path id="Path_20469" data-name="Path 20469"
                d="M139.659,41.32c-10.109,40.634-34.1,76.832-38.893,83.79-.255.4-.474.692-.611.9v34.625h-1.7V125.215l.068-.1.136-.19c.288-.421,28.034-39.289,39.214-83.6Z"
                transform="translate(714.857 174.734)" fill="#fff" />
            <path id="Path_20471" data-name="Path 20471"
                d="M122.639,41.32C105.054,90.488,77.8,118.783,71.226,125.11c-.493.461-.849.8-1.1,1.03v34.5h-1.7V125.067l.358-.315c.34-.295,32.179-28.589,52.006-83.433Z"
                transform="translate(705.513 174.734)" fill="#fff" />
            <path id="Path_20474" data-name="Path 20474"
                d="M286.007,125.488v35.151h-1.7V125.615c0-.022-.017-.19-.052-.5-.578-5.739-5.844-58.9-6.642-83.79h1.7c.816,25.015,6.15,78.744,6.659,83.79C285.989,125.34,286.007,125.467,286.007,125.488Z"
                transform="translate(754.108 174.734)" fill="#fff" />
            <path id="Path_20476" data-name="Path 20476"
                d="M318.16,125.615v35.025h-1.7V125.72c-.017-.126-.068-.336-.118-.609-1.24-7.085-10.229-58.882-11.606-83.79h1.716c1.428,25.288,10.875,79.46,11.622,83.79.033.168.052.251.052.273Z"
                transform="translate(759.719 174.734)" fill="#fff" />
            <path id="Path_20478" data-name="Path 20478"
                d="M350.727,125.615v35.025h-1.7V125.783c-.035-.148-.1-.358-.188-.672-1.954-6.9-15.036-54.13-16.634-83.79h1.7c1.683,30.732,15.835,80.678,16.735,83.79.018.083.035.125.035.146Z"
                transform="translate(765.187 174.734)" fill="#fff" />
            <path id="Path_20480" data-name="Path 20480"
                d="M384.627,125.32v35.319h-1.7v-34.73c-.085-.19-.221-.443-.375-.8-3.585-7.609-24.228-52.427-26.59-83.79h1.716c2.616,33.234,26.573,83.223,26.828,83.747l.018.043Z"
                transform="translate(768.495 174.734)" fill="#fff" />
            <path id="Path_20482" data-name="Path 20482"
                d="M420.957,125.235v35.4h-1.7V125.971c-.118-.188-.306-.483-.559-.86-4.657-7.295-29.938-47.97-38.959-83.79H381.5c9.956,38.763,38.959,83.16,39.266,83.622l.1.168Z"
                transform="translate(764.761 174.734)" fill="#fff" />
            <path id="Path_20484" data-name="Path 20484"
                d="M456.257,125.13v35.509h-1.7V126.1c-.2-.21-.509-.546-.9-.987-6.642-7.317-39.162-44.377-51.838-83.79h1.819c14.135,42.987,51.923,83.075,52.331,83.5l.271.295Z"
                transform="translate(766.614 174.734)" fill="#fff" />
            <g id="Group_51426" data-name="Group 51426" transform="translate(848.127 249.433)" opacity="0.1">
                <path id="Path_20485" data-name="Path 20485"
                    d="M159.673,79.959s126.649-2.943,154.489-5.045S440.266,60.2,444.633,61.463s-85.706,20.022-125.012,21.36S155.917,84.8,159.673,79.959Z"
                    transform="translate(-159.59 -61.375)" />
                <path id="Path_20486" data-name="Path 20486"
                    d="M197.085,68.052c-4.828-1.761,80.52-5.885,83.523-4.2S202.271,69.944,197.085,68.052Z"
                    transform="translate(-134.811 -59.994)" />
                <path id="Path_20487" data-name="Path 20487"
                    d="M357.574,73.738c1.152,3.741-42.7,2.521-43.03-.885S356.336,69.717,357.574,73.738Z"
                    transform="translate(-56.646 -55.317)" />
            </g>
        </g>
        <g id="Group_49047" data-name="Group 49047" transform="translate(1079.941 4851.461)">
            <g id="Group_49045" data-name="Group 49045" transform="translate(0.432 1.274)">
                <path id="Path_18451" data-name="Path 18451"
                    d="M634.6,134.221c22.151,24.719,53.555,86.456,57.4,95.288,2.607,5.995-28.758,20.469-29.918,16.134-1.485-5.549-25.987-74.272-32.233-100.1C626.4,131.3,630.021,129.114,634.6,134.221Z"
                    transform="translate(-628.476 -131.715)" fill="#f7a9a0" />
                <g id="Group_49044" data-name="Group 49044" transform="translate(32.287 94.243)">
                    <path id="Path_18452" data-name="Path 18452"
                        d="M654.868,224.331c.971,2.972,4.2,15.574,6.707,17.217s15.951,1.775,22.722,2.01,8.17-1.606,8.139-3.942-14.224-3.271-14.224-3.271,18.788,6.1,23.531,7.151,15.642,4.21,17.371.306c.779-1.759-2.17-3.669-2.17-3.669s4.568,1.507,5.382-.761c.926-2.583-2.691-4.147-2.691-4.147s2.863.681,3-1.444c.312-4.75-17.654-7.712-17.654-7.712s13.584,7.207,14.163,3.341c.8-5.35-27.315-12.222-30.182-14.666a20.091,20.091,0,0,1-4.522-5.995Z"
                        transform="translate(-654.868 -208.749)" fill="#f7a9a0" />
                    <g id="Group_49043" data-name="Group 49043" transform="translate(23.333 14.715)">
                        <path id="Path_18453" data-name="Path 18453"
                            d="M688.033,236.129a2.08,2.08,0,0,0-.581-1.834,5.874,5.874,0,0,0-1.612-1.128A22.378,22.378,0,0,0,682,231.774c-2.645-.771-5.365-1.343-8.064-2.142a33.121,33.121,0,0,1,8.329,1.23,15.424,15.424,0,0,1,3.938,1.665,5.419,5.419,0,0,1,1.616,1.49A2.165,2.165,0,0,1,688.033,236.129Z"
                            transform="translate(-673.94 -218.799)" fill="#263238" />
                        <path id="Path_18454" data-name="Path 18454"
                            d="M709.763,235.8c-1.935-.544-3.84-1.162-5.743-1.784s-3.791-1.277-5.683-1.919l-5.654-1.989c-1.884-.664-3.769-1.323-5.674-1.962a44.528,44.528,0,0,1,5.892,1.264c1.944.514,3.85,1.141,5.747,1.79,1.891.668,3.774,1.358,5.624,2.134S707.969,234.89,709.763,235.8Z"
                            transform="translate(-671.021 -219.131)" fill="#263238" />
                        <path id="Path_18455" data-name="Path 18455"
                            d="M690.889,224.4a38.006,38.006,0,0,1,5.448.947,51.323,51.323,0,0,1,5.308,1.565,48.637,48.637,0,0,1,5.115,2.115,39.239,39.239,0,0,1,4.826,2.7c-1.725-.662-3.4-1.391-5.116-2.03s-3.414-1.3-5.141-1.89c-1.716-.622-3.454-1.183-5.19-1.76S692.651,224.944,690.889,224.4Z"
                            transform="translate(-670.154 -219.968)" fill="#263238" />
                        <path id="Path_18456" data-name="Path 18456"
                            d="M693.3,220.777a36.767,36.767,0,0,1,16.532,6.3c-2.771-1.1-5.462-2.247-8.219-3.273C698.868,222.75,696.1,221.786,693.3,220.777Z"
                            transform="translate(-669.615 -220.777)" fill="#263238" />
                    </g>
                </g>
            </g>
            <g id="Group_49046" data-name="Group 49046">
                <path id="Path_18457" data-name="Path 18457"
                    d="M655.559,157.555l-20.96,10.74s-5.888-15.774-6.45-29.108,8.29-8.722,13.521-1.619S655.559,157.555,655.559,157.555Z"
                    transform="translate(-628.123 -130.673)" fill="#e95c29" />
                <path id="Path_18458" data-name="Path 18458"
                    d="M632.959,159.005a153.942,153.942,0,0,0,18.029-9.179c.077-.038.012-.173-.064-.13-1.56.894-10.807,5.106-18.035,9.167A.079.079,0,0,0,632.959,159.005Z"
                    transform="translate(-627.067 -126.425)" fill="#263238" />
                <path id="Path_18459" data-name="Path 18459"
                    d="M631.936,155.94c-.925-2.91-2.166-7.134-3.238-9.979-.014-.037-.068-.016-.06.024.384,1.642.839,3.275,1.319,4.9-.043-.083-.08-.171-.124-.254-.409-.777-.831-1.538-1.291-2.279-.016-.026-.05,0-.039.031.331.832.692,1.647,1.09,2.443.248.5.516.977.783,1.461.261.856,3.477,13.549,3.6,13.477.18-.108,1.829-1.095,2.633-1.663.1-.071-.013-.28-.12-.226-.662.339-1.769.8-1.923.882-.5-1.464-.827-2.908-1.272-4.394S632.4,157.407,631.936,155.94Z"
                    transform="translate(-628.039 -127.262)" fill="#263238" />
            </g>
        </g>
    </g>
</svg></template>
<script>
import responsiveMixin from '@/mixins/responsiveMixin'

export default {
    mixins:[responsiveMixin]
}
</script>
<style scoped></style>