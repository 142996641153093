<template>
  <section id="blog" class="container-fluid">
    <div class="row pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-5 pt-5 mt-5">
      <div class="col-lg-11 col-md-10 mx-auto col-11 px-0 mx-auto text-center">
        <SecTitle title="المدونة" />
      </div>
    </div>
    <articleThumb title="ما أهم 7 أنواع المنتجات لتبدأ في التجارة الإلكترونية" text="لكي تنجح في أعمال التجارة الإلكترونية، تحتاج إلى بيع المنتجات المطلوبة، إذا لم يكن لديك فكرة، سنشارك في
                هذه المقالة قائمة أنواع المنتجات التي"
      :imgSrc="mobile ? 'blog-placeholder-t.svg' : 'blog-placeholder.svg'" />
  </section>
</template>
<script>
import responsiveMixin from '@/mixins/responsiveMixin';
import articleThumb from '../items/blog/articleThumb.vue';
import SecTitle from '../items/shared/SecTitle.vue';
export default {
  name: 'Blog',
  components: {
    SecTitle,
    articleThumb
  },
  mixins: [responsiveMixin]
}
</script>