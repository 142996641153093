<template>
  <div

    class="col-lg-3 col-5 text-center reason pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-4 pt-1 mt-5"
  >
    <img :src="require(`@/assets/img/${imgSrc}`)" :alt="title" />
    <p class="f-28 basic-bold white mt-3 mb-xxl-3 mb-2">{{ title }}</p>
    <p class="f-22 basic-light white mb-0" v-if="!mobile">{{ desc }}</p>
  </div>
</template>
<script>
import responsiveMixin from "@/mixins/responsiveMixin";
export default {
  name: "reasonCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: "reason1.svg",
    },
  },
  mixins: [responsiveMixin],
};
</script>
<style scoped>
.reasons img {
  height: 158px;
}

#why-e-cart .col-lg-3 {
  flex-basis: 26%;
}

@media (max-width: 1600px) {
  .reasons img {
    height: 130px;
  }
}

@media (max-width: 1199.98px) {
  #why-e-cart .col-lg-3 {
    flex-basis: auto;
  }

  .reasons img {
    height: 170px;
  }
}

@media (max-width: 570.98px) {
  #why-e-cart .col-lg-3 {
    flex-basis: 50%;
  }

  .reasons img {
    height: 90px;
  }
}
</style>