<template>
  <section id="header" class="container-fluid bg-dark-blue" :class="{ ' mobile': mobile }">
    <div class="row h-85 position-relative text-center bg-dark-blue">
      <div class="col-11 position-relative h-100 px-0 mx-auto d-flex align-items-center align-items-lg-end justify-content-center"
        :class="{ 'col-sm-12': !table && mobile }">
        <div class="header-content position-absolute l-0 r-0 z-5  " :class="mobile ? 'w-58 mx-auto  mb-3':'w-50 animate__animated animate__fadeIn animate__delay-2s center'">
          <p class="f-40 white text-center  basic-bold ">
            منصة إلكترونية تساعدك في تحويل <br>نشاطك التجاري مهام كان نوعه <br>إلى التجارة الالكترونية
          </p>
          <div class="d-flex flex-mobile-column justify-content-center" :class="{ 'mt-4': !mobile }">
            <router-link :to="{ name: 'subscribe' }" type="button"
              class="btn btn-primary mb-mobile-3 py-2 btn-w-5 basic-bold f-30 mx-4 d-flex justify-content-center align-items-center">اشترك
              الآن</router-link>
            <button type="button" data-bs-toggle="modal" data-bs-target="#liveExperience" :class="{ 'mx-4': mobile }"
              class="btn btn-primary py-2 btn-w-5  basic-bold f-30 button-animation button-animation2">عش
              التجربة</button>
          </div>
        </div>
        <!-- <img :src="require(`@/assets/img/header-facebook.svg`)" class="position-absolute r-0 t-0 z-4 " :class="{'animate__animated animate__fadeIn mx-auto animate__delay-2s facebook' : this.startAnimation}" alt="">
        <img :src="require(`@/assets/img/header-instagram.svg`)" class="position-absolute r-0 t-0 z-4" :class="{'animate__animated animate__fadeIn mx-auto animate__delay-2s insta' : this.startAnimation}" alt="">
        <img :src="require(`@/assets/img/header-messanger.svg`)" class="position-absolute l-0 t-0 z-4" :class="{'animate__animated animate__fadeIn mx-auto animate__delay-2s messanger' : this.startAnimation}" alt="">
        <img :src="require(`@/assets/img/header-telegram.svg`)" class="position-absolute l-0 t-0 z-4" :class="{'animate__animated animate__fadeIn mx-auto animate__delay-2s telegram' : this.startAnimation}" alt=""> -->
        <div class="icons position-absolute z-4 r-0 l-0 t-0 b-0 animate__animated animate__fadeIn animate__delay-2s mx-auto" v-if="!mobile">
          <img :src="require(`@/assets/img/2.svg`)" class="position-absolute r-0 t-0 z-4"
            :class="{ 'facebook': this.startAnimation }"
            alt="">
          <img :src="require(`@/assets/img/1.svg`)" class="position-absolute r-0 t-0 z-4"
            :class="{ 'insta': this.startAnimation }" alt="">
          <img :src="require(`@/assets/img/4.svg`)" class="position-absolute l-0 t-0 z-4"
            :class="{ 'messanger': this.startAnimation }"
            alt="">
          <img :src="require(`@/assets/img/3.svg`)" class="position-absolute l-0 t-0 z-4"
            :class="{ 'telegram': this.startAnimation }"
            alt="">
        </div>
        <div class="position-relative h-100 text-center" v-if="!mobile">
          <img :src="require(`@/assets/img/header-laptop.svg`)"
            class="mx-auto position-relative b-0 z-1 mx-auto text-center" alt="إي كارت"
            :class="{ 'animate__animated animate__slideInUp': startAnimation }">
            <div class="position-absolute z-4 l-0 r-0 t-0 b-0 animate__animated animate__slideInDown  animate__delay-1s">
                  <img :src="require(`@/assets/img/header-sign.svg`)"
            class="position-absolute l-0 r-0 t-2 z-2 mx-auto w-20 ecart-sign"
            alt="">
            </div>
      
          <img :src="require(`@/assets/img/header-top.svg`)"
            class="position-absolute l-0 r-0 t-0 z-4 animate__animated animate__slideInUp mx-auto" alt="">
          <img :src="require(`@/assets/img/header-bags.svg`)"
            class="position-absolute l-0 r-0 b-0 z-2 animate__animated animate__slideInUp  animate__delay-1s mx-auto w-100"
            alt="">

        </div>
        <img :src="require(`@/assets/img/${getImgSrc}`)" v-else
          class="mx-auto position-relative b-0 z-1 w-max-100 text-center h-max-100" alt="إي كارت"
          :class="{ 'w-100': tablet }">
      </div>
      <img src="../../assets/img/curve.svg" class="w-100 px-0 position-absolute b-0 r-0 l-0 z-0 " alt="e-cart">

    </div>
  </section>
</template>
<script>
import responsiveMixin from '../../mixins/responsiveMixin.js'
export default {
  name: 'HomeHeader',
  mixins: [responsiveMixin],
  data() {
    return {
      startAnimation: false
    }
  },
  created() {
    this.startAnimation = true
  },
  computed: {
    getImgSrc() {
      if (this.tablet) {
        return 'homeHeader-t.svg'
      }
      else if (this.mobile) {
        return 'homeHeader.svg'
      }
      return 'header-laptop.svg'
    }
  }

} 
</script>
<style scoped>
.z-5
{
  z-index: 5;
}
@keyframes ecart-sign-animation {
  from {
    transform: rotateZ(0deg)
  }

  to {
    transform: rotateZ(-4deg)
  }
}

@keyframes facebook-animation {
  from {
    transform: rotateZ(0deg)
  }

  to {
    transform: rotateZ(5deg)
  }
}

@keyframes insta-animation {
  from {
    transform: rotateZ(0deg)
  }

  to {
    transform: rotateZ(-5deg)
  }
}

@keyframes messanger-animation {
  from {
    transform: rotateZ(0deg)
  }

  to {
    transform: rotateZ(4deg)
  }
}

@keyframes telegram-animation {
  from {
    transform: rotateZ(0deg)
  }

  to {
    transform: rotateZ(-5deg)
  }
}

/*  */
.ecart-sign {
  animation: ecart-sign-animation 1s ease-in-out 0s infinite alternate;
}

/* insta */
.insta {
  animation: insta-animation 0.8s ease-in-out 0s infinite alternate;
  top: 45%;
  right: 8%;
  width: 6%;
}

/* facebook */
.facebook {
  animation: facebook-animation 0.8s ease-in-out 0s infinite alternate, fadeIn;
  top: 5%;
  width: 7.5%;
}

/* telegram */
.telegram {
  animation: telegram-animation 0.8s ease-in-out 0s infinite alternate, fadeIn;
  top: 40%;
  left: 8%;
  width: 6.8%;
}

/* messanger */
.messanger {
  animation: messanger-animation 0.8s ease-in-out 0s infinite alternate, fadeIn;
  top: 2%;
  width: 8%;
}


.t-2 {
  top: 2%;
}

.w-20 {
  width: 22%;
}

.animate__delay-1s {
  --animate-delay: 1s;
}

.center {
  top: 60%;
  transform: translate(-50%, -50%);
}

.h-max-100 {
  max-height: 100%;
}

.w-max-100 {
  max-width: 100%;
}

@media (max-width: 1600px) {
  .center {
    /* top: 62%; */
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 1199.98px) {
  .w-58{
    width: 59%!important;

  }
  .center {
    bottom: -3%;
    transform: translate(-38%,-75%);
}


}
</style>