<template>
  <navbar />
  <main class="bg-circle" :class="{'bg-learn' : ($route.name=='learn-with-us'||$route.name=='privacy-policy'),'contact-us-bg' : ($route.name=='contact-us'||$route.name=='terms-of-use') ,'bg-circle' : ($route.name=='our-services'||$route.name=='subscribe' ) }">
     <router-view/>
     <Footer :flat="$route.name=='login'|| $route.name=='reset-password' " v-if="$route.name!='not-found'"/>
  </main>

</template>
<script>
import Navbar from "./layout/Navbar.vue";
import Footer from "./layout/Footer.vue";
export default {
  name: "App", 
  components: {
    Navbar,
    Footer
  },
  }
</script>
<style>
.bg-learn{
  background-image: url('assets/img/learnWithUsBg.svg');
  background-position-y: 100vh;
  background-repeat: no-repeat;
  background-position-x: left;
  background-size: contain;
}
.bg-circle{
  background-image: url('assets/img/circle-bg.svg');
  background-position-y: 100vh;
  background-repeat: no-repeat;
  background-position-x: left;
  background-size: contain;
}
.contact-us-bg{
  background-image: url('assets/img/contact-us-bg.svg');
  background-position-y: 100vh;
  background-repeat: no-repeat;
  background-position-x: left;
  background-size: auto;
}
</style>





