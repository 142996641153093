<template>
  <div class="container-fluid bg-dark-blue ">
    <div class="row h-15 d-flex align-items-center bg-dark-blue" :class="{'animate__animated  animate__slideInDown':startAnimation&&!mobile}">
      <div class="col-xl-11 col-10 px-0 mx-auto position-relative z-10">
        <nav class="navbar  mobile" v-if="mobile">
          <router-link :to="{ name: 'home' }" class="navbar-brand navbar-brand mx-0 w-103">
            <img src="../assets/img/logo.svg" alt="شعار إي كارت">
          </router-link>
          <img src="../assets/img/menuIcon.svg" class="w-42 pointer" alt="القائمة" @click="menu=!menu">
          <div class="position-fixed t-0 b-0 w-100vw  z-10 side-menu bg" :class="isMenu ? 'fadeIn':'fadeOut'"> 
            <button type="button" class="btn-close position-absolute close z-7" data-bs-dismiss="modal" aria-label="Close" v-if="menu" @click="menu=!menu"></button>

            <ul class="navbar-nav position-relative z-6 basic-bold px-0 text-center d-flex flex-column align-items-center justify-content-center h-100">
              <li class="nav-item mb-29px" @click="menu=false">
              <router-link class="nav-link basic-bold f-25 mx-xxl-4 p-xxl-2 px-1 "
                :to="{ name: 'login' }">تسجيل الدخول</router-link>
            </li>
              <li class="nav-item mb-29px" @click="menu=false">
              <router-link class="nav-link basic-bold f-25 mx-xxl-4 p-xxl-2 px-1 "
                :to="{ name: 'home' }">الرئيسية</router-link>
            </li>
            <li class="nav-item mb-29px" @click="menu=false">
              <router-link class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 px-1"
                :to="{ name: 'our-services' }">خدماتنا</router-link>
            </li>
            <li class="nav-item mb-29px" @click="menu=false">
              <router-link :to="{ name: 'blog' }"
                class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 px-1">المدونة</router-link>
            </li>
            <li class="nav-item mb-29px" @click="menu=false">
              <router-link :to="{ name: 'who-we-are' }" class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 px-1">من
                نحن</router-link>
            </li>
            <li class="nav-item mb-29px" @click="menu=false">
              <router-link :to="{ name: 'contact-us' }" class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 px-1">تواصل
                معنا</router-link>
            </li>
            <li class="nav-item mb-29px" @click="menu=false">
              <router-link class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 px-1"
                :class="{ 'router-link-active': this.$route.name == 'video' }" :to="{ name: 'learn-with-us' }">تعلم
                معنا</router-link>
            </li>
            <!-- <li class="nav-item mb-29px" @click="menu=false">
              <router-link class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 px-1"
                :to="{ name: 'our-clients' }">عملاؤنا</router-link>
            </li>
            <li class="nav-item mb-29px" @click="menu=false">
              <router-link class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 px-1"
                :to="{ name: 'our-agents' }">وكلائنا</router-link>
            </li> -->
          </ul>
          <img src="../assets/img/e-cart-menu-man-tablet.svg" alt="شخصية إي كارت" class="position-absolute l-0 b-0 w-mobile-269">
          </div>
         
        </nav>
        <nav class="navbar  navbar-expand-lg" v-else>
          
          <router-link :to="{ name: 'home' }" class="navbar-brand navbar-brand mx-0 w-9">
            <img src="../assets/img/logo.svg" alt="شعار إي كارت">
          </router-link>

          <ul class="navbar-nav basic-bold px-0">
            <li class="nav-item">
              <router-link class="nav-link basic-bold f-25 mx-xxl-4 p-xxl-2 mx-3 px-1 "
                :to="{ name: 'home' }">الرئيسية</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 ms-3 px-1"
                :to="{ name: 'our-services' }">خدماتنا</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'blog' }"
                class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 ms-3 px-1">المدونة</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'who-we-are' }" class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 ms-3 px-1">من
                نحن</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'contact-us' }" class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 ms-3 px-1">تواصل
                معنا</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 ms-3 px-1"
                :class="{ 'router-link-active': this.$route.name == 'video' }" :to="{ name: 'learn-with-us' }">تعلم
                معنا</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 ms-3 px-1"
                :to="{ name: 'our-clients' }">عملاؤنا</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link basic-bold f-25 ms-xxl-4 p-xxl-2 ms-3 px-1"
                :to="{ name: 'our-agents' }">وكلائنا</router-link>
            </li>
          </ul>
          <div class="d-flex me-auto">

            <router-link :to="{ name: 'subscribe' }" type="button"
              class="btn text-center d-flex justify-content-center btn-primary nav-btn me-4">
              <p class="white  basic-bold f-24 my-auto">
                اشترك الآن
              </p>
            </router-link>
            <router-link :to="{ name: 'login' }" type="button"
              class="btn text-center d-flex justify-content-center btn-primary nav-btn me-4">
              <p class="white  basic-bold f-24 my-auto">
                تسجيل دخول
              </p>
            </router-link>
          </div>
        </nav>

      </div>

    </div>
  </div>
</template>
<script>
import responsiveMixin from '@/mixins/responsiveMixin';
export default {
  name: 'navbar',
  mixins:[responsiveMixin],
  data() {
    return {
      menu:false,
      startAnimation:false
    }
  },
  created(){
    this.startAnimation = true ;
  },
  computed:{
    isMenu: function() {
      return this.menu;
    }
  },
}
</script>
  
<style scoped>
.z-3 {
  z-index: 3;
}
.w-9 {
  width: 9%;
}
.h-15 {
  height: 15vh;
}
.btn-primary:hover,
a.btn-primary:focus,
a.btn-primary:hover,
a.btn-primary:focus,
.btn-primary.router-link-active,
.btn-primary.router-link-exact-active {
  color: white !important;
}
.side-menu{
  left: -100vw;
  right:auto;
  background-color: #ffffff5f;
}
.side-menu.fadeIn{
  left: 0;
  background-color: #1c324c96;
  transition: all .6s ease-in-out;
}
.side-menu.fadeOut{
  left: -100vw;
  transition: all .6s ease-in-out;
  background-color: #ffffff00;
}
.bg{
  background-image: url('../assets/img/e-cart-menu-bg.svg');
  background-size: cover;
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
}
.f-24 {
  font-size: 24px;
}
a.nav-link,
a.link {
  color: white;
}
.z-10{
  z-index: 10
}
.z-7{
  z-index: 7
}
.z-6{
  z-index: 6
}
a.nav-link:hover,
a.nav-link:focus,
a.link:hover,
a.link:focus,
.router-link-active,
.router-link-exact-active {
  color: #D14816 !important;
}
@media (max-width: 1600px) {
  .f-24 {
    font-size: 16px;
  }
}
@media (max-width: 1199.98px) {
  .h-15 {
    height: 10.8vh;
  }
}
</style>
  