<template>
  <section id="contact-with-us" class="container-fluid">
    <div
      class="row pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-5 pt-5 mt-5"
    >
      <div class="col-xl-11 col-11 col-md-10 px-0 mx-auto text-center">
        <SecTitle title="تواصل معنا" />
      </div>
      <div class="col-11 px-0 mx-auto text-end">
        <div
          class="row flex-mobile-direction-column-reverse"
          id="animation-anchor"
        >
          <div
            class="col-xl-4 col-11 m-auto pt-4 px-0"
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            data-aos-duration="800"
            data-aos-anchor="#animation-anchor"
            data-aos-delay="400"
            data-aos-mirror="false"
            data-aos-once="true"
          >
            <div class="w-90" :class="{ 'mx-auto': mobile }">
              <contactUsForm />
            </div>
          </div>
          <div class="col-xl-1"></div>
          <div
            class="col-xl-7 col-11 mx-auto pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-4 pt-1 mt-5 px-0 justify-content-center d-flex align-items-center position-relative"
          >
            <img
              :src="require(`@/assets/img/${this.getImgSrc}`)"
              alt="تواصل معنا"
              class="w-90"
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              data-aos-anchor="#animation-anchor"
              data-aos-duration="800"
              data-aos-delay="400"
              data-aos-mirror="false"
              data-aos-once="true"
            />
            <img
              src="../../assets/img/contact-us-man.svg"
              alt=""
              data-aos="fade-right"
              data-aos-anchor-placement="top-center"
              data-aos-anchor="#animation-anchor"
              data-aos-duration="200"
              data-aos-delay="1200"
              data-aos-mirror="false"
              data-aos-once="true"
              class="position-absolute r-0 l-0 man mt-auto t-0 b-0 z-2"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import responsiveMixin from "@/mixins/responsiveMixin";
import contactUsForm from "../items/shared/contactUsForm.vue";
import SecTitle from "../items/shared/SecTitle.vue";
export default {
  name: "ContactUS",
  components: {
    SecTitle,
    contactUsForm,
  },
  mixins: [responsiveMixin],
  computed: {
    getImgSrc() {
      if (this.mobile) {
        return "contactus.svg";
      }
      return "contact-us-d.svg";
    },
  },
};
</script>
<style scoped>
.aos-animate{
  opacity: 1!important;
}
.man{
  left: -42%;
  width: 100%;
}
.animate{
  animation-name: slideInLeft;
  animation-duration: .4s;
  animation-delay: .8s;
  animation-fill-mode: forwards;
  transition: all .8s ease-in-out;
}
@keyframes slideInLeft {
  0% {left: -42%;}
  100% {left: 0%;}
}
</style>