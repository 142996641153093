<template>
  <section id="why-e-cart" class="container-fluid"
  data-aos-mirror="false"
    data-aos-once="true"
    data-aos="fade-down"
    data-aos-duration="800"
    data-aos-delay="500"
    >
    <div
      class="row pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-5 pt-5 mt-5"
    >
      <div class="col-xl-11 col-11 col-md-10 px-0 mx-auto text-center">
        <SecTitle title="لماذا إي كارت" />
      </div>
      <div class="col-10 mx-auto px-0">
        <div class="row reasons d-flex justify-content-around">
          <reasonCard
            title="متجرك يعمل على مدار الساعة"
            desc="افتح الفرصة أمام زبائنك لتصفح منتجاتك وخدماتك في أي وقت واستلم طلباتهم على مدار 24 ساعة"
            imgSrc="reason1.svg"
          />
          <reasonCard
            title="طرق دفع متعددة"
            desc="الدفع الإلكتروني: شبكة فاتورة الدفع عبر الحوالات: الهرم و الفؤاد الدفع عند الاستلام أو الوصول الدفع عبر الموبايل: سرياتيل و MTN"
            imgSrc="reason2.svg"
          />
          <reasonCard
            title="تقارير مبيعات تفصيلية واحترافية"
            desc="تقارير قيَمة عن المبيعات بشكل يومي وشهري لتساعدك في مراقبة أداء االمتجر واتخاذ أفضل القرارات لتطوير عملك"
            imgSrc="reason3.svg"
          />
          <reasonCard
            title="لوحة تحكم سهلة الاستخدام"
            desc="تم تصميمها بعناية لتكون بسيطة وسهلة الاستخدام لجميع المشتركيين حيث يمكنك إضافة خدماتنا لحساباتك والبدء مباشرة"
            imgSrc="reason4.svg"
          />
          <reasonCard
            title="إدارة طلبات الزبائن في مكان واحد"
            desc="يمكنك متابعة جميع طلبات زبائنك و معالجتها في مكان واحد لضمان تقديم أفضل خدمة ممكنة لزبائنك وسرعة في الأداء"
            imgSrc="reason5.svg"
          />
          <reasonCard
            title="سهولة في إدارة الفئات والمنتجات"
            desc="ستتمكن من إدراج وإدارة منتجاتك مهام كان نوع هذه المنتجات سواء منتجات بضائع أو حجوزات أو منتجات رقمية وغيرها بكل سهولة"
            imgSrc="reason6.svg"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import reasonCard from "../items/whyECart/reasonCard.vue";
import SecTitle from "../items/shared/SecTitle.vue";
import responsiveMixin from "@/mixins/responsiveMixin";

export default {
  name: "WhyECart",
  components: {
    reasonCard,
    SecTitle,
  },

  mixins: [responsiveMixin],
};
</script>