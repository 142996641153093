<template>
  <section id="subscriptions" class="container-fluid" v-if="!mobile">
    <div
      class="row pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-5 pt-5 mt-5"
    >
      <div class="col-11 px-0 mx-auto text-center">
        <SecTitle title="الاشتراكات" />
      </div>
      <div
        class="col-11 pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-4 pt-1 mt-5 mx-auto px-0"
      >
        <div class="pt-4 d-flex flex-row justify-content-around">
          <div
            class="package messanger align-items-center d-flex flex-column position-relative w-sd-30"
            data-aos="fade-up"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-duration="400"
            data-aos-delay="400"
            :class="{
              active: checkboxMode && isMessanger,
              pointer: checkboxMode,
            }"
            @click="isMessanger = checkboxMode && !isMessanger"
          >
            <MessangerPackage />
            <div
              class="text-box b-0 position-absolute w-75 py-3 align-items-center text-center position-absolute"
            >
              <p class="white f-40 basic-bold line-height-3 mb-3">مسنجر</p>
              <img
                src="../../assets/img/messanger.svg"
                class="mb-xxl-4 pb-xxl-3 h-sd-15 mb-4"
                alt="اشتراك ماسنجر"
              />
              <p class="basic-SemiBold white f-25 mb-xxl-4 mb-3 pb-2">
                35,000 ليرة سورية
                <br />لمدة شهر
              </p>
              <p class="basic-SemiBold white f-25">
                +2% من قيمة كل عملية<br />دفع إلكتروني
              </p>
            </div>
          </div>

          <div
            class="package telegram mx-3 align-items-center d-flex flex-column position-relative w-sd-30"
            data-aos="fade-up"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-duration="400"
            data-aos-delay="800"
            :class="{
              active: checkboxMode && isTelegram,
              pointer: checkboxMode,
            }"
            @click="isTelegram = checkboxMode && !isTelegram"
          >
            <TelegramPackage />
            <div
              class="text-box b-0 position-absolute w-75 py-3 align-items-center text-center"
            >
              <p class="white f-40 basic-bold line-height-3 mb-3">تليجرام</p>
              <img
                src="../../assets/img/Path_11269.svg"
                class="mb-xxl-4 pb-xxl-3 h-sd-15 mb-4"
                alt="اشتراك تلجرام"
              />
              <p class="basic-SemiBold white f-25 mb-xxl-4 mb-3 pb-2">
                35,000 ليرة سورية
                <br />لمدة شهر
              </p>
              <p class="basic-SemiBold white f-25">
                +2% من قيمة كل عملية<br />دفع إلكتروني
              </p>
            </div>
          </div>
          <div
            class="package cart align-items-center d-flex flex-column position-relative w-sd-30"
            data-aos="fade-up"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-duration="400"
            data-aos-delay="1200"
            :class="{ active: checkboxMode && isCart, pointer: checkboxMode }"
            @click="isCart = checkboxMode && !isCart"
          >
            <CartPackage />
            <div
              class="text-box b-0 position-absolute w-75 py-3 align-items-center text-center d-flex flex-column align-items-between"
            >
              <p class="white f-40 basic-bold mb-3">
                عربة تسوق<br />لموقع إلكتروني
              </p>
              <img
                src="../../assets/img/web.svg"
                class="mb-xxl-4 pb-xxl-3 h-sd-15 mb-4"
                alt="اشتراك لموقع الكتروني"
              />
              <p class="basic-SemiBold white f-25 mb-xxl-4 mb-3 pb-2">
                35,000 ليرة سورية
                <br />لمدة شهر
              </p>
              <p class="basic-SemiBold white f-25">
                +2% من قيمة كل عملية<br />دفع إلكتروني
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div id="subscriptions" class="mobile container-fluid" v-else>
    <div class="row pt-lg-4 mt-lg-5 mt-md-5 pt-md-4 pt-5 mt-5">
      <div class="col-sm-10 col-11 px-0 mx-auto text-center">
        <SecTitle title="الاشتراكات" />
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-10 col-11 px-0 mx-auto ecart-man pt-lg-4 mt-lg-5 mt-md-5 pt-md-4 pt-1 mt-5"
      >
        <!-- :class="{hoverd:checkboxMode && (isMessanger||isCart||isTelegram)}" -->
        <TelegramPackage />
      </div>
      <div class="row mx-0 packages">
        <div class="col-12 px-0">
          <div
            class="col-9 mobile-package mb-2 mb-md-3 mx-auto bg-dark-blue text-center d-flex flex-column align-items-center justify-content-center"
            :class="{
              active: checkboxMode && isMessanger,
              pointer: checkboxMode,
              'py-4': tablet,
              'py-3': mobile && !tablet,
            }"
            @click="isMessanger = checkboxMode && !isMessanger"
          >
            <img
              src="../../assets/img/messanger.svg"
              class="messanger-icon mb-1"
              alt="اشتراك ماسنجر"
            />
            <p class="white f-40 basic-bold mb-1">مسنجر</p>
            <p class="basic-light white f-30 mb-1">
              35,000 ليرة سورية لمدة شهر
            </p>
            <p class="basic-light white f-30 mb-0">
              +2% من قيمة كل عملية دفع إلكتروني
            </p>
          </div>
          <div
            class="col-9 mobile-package mb-2 mb-md-3 mx-auto bg-dark-blue text-center d-flex flex-column align-items-center justify-content-center"
            :class="{
              active: checkboxMode && isTelegram,
              pointer: checkboxMode,
              'py-4': tablet,
              'py-3': mobile && !tablet,
            }"
            @click="isTelegram = checkboxMode && !isTelegram"
          >
            <img
              src="../../assets/img/Path_11269.svg"
              class="telegram-icon mb-1"
              alt="اشتراك تلجرام"
            />
            <p class="white f-40 basic-bold mb-1">تليجرام</p>

            <p class="basic-light white f-30 mb-1">
              35,000 ليرة سورية لمدة شهر
            </p>
            <p class="basic-light white f-30 mb-0">
              +2% من قيمة كل عملية دفع إلكتروني
            </p>
          </div>
          <div
            class="col-9 mobile-package mx-auto bg-dark-blue text-center d-flex flex-column align-items-center justify-content-center"
            :class="{
              active: checkboxMode && isCart,
              pointer: checkboxMode,
              'py-4': tablet,
              'py-3': mobile && !tablet,
            }"
            @click="isCart = checkboxMode && !isCart"
          >
            <img
              src="../../assets/img/web.svg"
              class="cart-icon mb-1"
              alt="اشتراك عربة تسوق"
            />
            <p class="white f-40 basic-bold mb-1">عربة تسوق لموقع إلكتروني</p>

            <p class="basic-light white f-30 mb-1">
              35,000 ليرة سورية لمدة شهر
            </p>
            <p class="basic-light white f-30 mb-0">
              +2% من قيمة كل عملية دفع إلكتروني
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SecTitle from "../items/shared/SecTitle.vue";
import TelegramPackage from "../items/svg/telegramPackage.vue";
import CartPackage from "../items/svg/cartPackage.vue";
import MessangerPackage from "../items/svg/messangerPackage.vue";
import responsiveMixin from "@/mixins/responsiveMixin";
export default {
  name: "Packages",
  components: {
    SecTitle,
    TelegramPackage,
    CartPackage,
    MessangerPackage,
  },
  props: {
    checkboxMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTelegram: false,
      isMessanger: true,
      isCart: false,
      hover: false,
    };
  },
  mixins: [responsiveMixin],
  computed: {
    isHover() {
      return this.hover;
    },
  },
};
</script>
<style scoped>
.mobile-package.active,
.mobile-package:hover {
  background-color: #d14816;
}

.line-height-3 {
  line-height: 3;
}

.package .text-box {
  height: 56%;
}

.package {
  width: 30%;
}

.package svg {
  width: 100%;
}

@media (max-width: 1600px) {
  .h-sd-15 {
    height: 15%;
  }
}
</style>