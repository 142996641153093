<template>
  <div
    data-aos="fade-down"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    data-aos-mirror="false"
    data-aos-once="true"
    data-aos-anchor-placement="top-center"
    class="bg-dark-blue mx-auto py-2 border-dark"
    :class="{ 'w-40': !mobile }"
  >
    <h2 :class="'white basic-bold f-60 ' + [styleClass]">{{ title }}</h2>
  </div>
</template>
<script>
import responsiveMixin from "@/mixins/responsiveMixin";
export default {
  name: "SecTitle",
  mixins: [responsiveMixin],
  props: {
    title: {
      type: String,
      default: "",
    },
    styleClass: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.w-40 {
  min-width: 36%;
  width: fit-content;
}
.border-dark {
  border: 11px solid #0c182c;
}

@media (max-width: 1600px) {
  .border-dark {
    border: 8px solid #0c182c;
  }
  .w-40 {
    min-width: 33%;
    width: fit-content;
  }
}

@media (max-width: 1399.98px) {
  .border-dark {
    border: 6px solid #0c182c;
  }
}
@media (min-width: 2200px) {
  .w-40 {
    min-width: 26%;
    width: fit-content;
  }
}
</style>