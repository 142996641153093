import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/our-clients',
    name: 'our-clients',
    component: () => import(/* webpackChunkName: "about" */ '../views/OurClients.vue')
  },
  {
    path: '/who-we-are',
    name: 'who-we-are',
    component: () => import(/* webpackChunkName: "about" */ '../views/WhoWeAre.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfUse.vue')

  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')

  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQ.vue')

  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "about" */ '../views/contactUs.vue')

  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "about" */ '../views/Blog.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue')
  },
  {
    path: '/our-agents',
    name: 'our-agents',
    component: () => import(/* webpackChunkName: "about" */ '../views/OurAgents.vue')
  },
  {
    path: '/our-services',
    name: 'our-services',
    component: () => import(/* webpackChunkName: "about" */ '../views/OurServices.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/learn-with-us',
    name: 'learn-with-us',
    component: () => import(/* webpackChunkName: "about" */ '../views/learnWithUS.vue')
  },
  {
    path: '/create-your-market',
    name: 'subscribe',
    component: () => import(/* webpackChunkName: "about" */ '../views/Subscribe.vue')
  },
  {
    path: '/video/:id',
    name: 'video',
    component: () => import(/* webpackChunkName: "about" */ '../views/video.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
  },
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
