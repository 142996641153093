<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501.204 833.946">
        <g id="Group_65921" data-name="Group 65921" transform="translate(-89 -4540.054)">
            <g id="Group_49083" data-name="Group 49083" transform="translate(149.668 4849.454)">
                <g id="Group_49075" data-name="Group 49075" transform="translate(161.979 0.059)">
                    <g id="Group_49071" data-name="Group 49071" transform="translate(7.995 309.626)">
                        <path id="Path_18481" data-name="Path 18481"
                            d="M440.3,362.119s-5.023,69.675-5.026,69.724c-.069,13.647.114,25.611.786,31.79a.205.205,0,0,0,.013.1c.25,2.333.57,3.821.984,4.22.032.034.048.069.08.088,4.048,2.772,113.08,6.5,116.926,5.353,3.861-1.133.872-12.2-3.245-15.722-4.149-3.523-45.237-23.622-45.237-23.622l4.345-69.644Z"
                            transform="translate(-435.258 -362.119)" fill="#d3766a" />
                        <path id="Path_18482" data-name="Path 18482"
                            d="M435.271,390.991s0,14.6,0,14.648c-.069,13.647.114,25.611.786,31.79a.205.205,0,0,0,.013.1,18.633,18.633,0,0,0,.984,4.22c.032.034.048.069.08.088,4.048,2.772,113.08,6.5,116.926,5.353,3.861-1.133.872-12.2-3.245-15.722-4.149-3.523-45.237-23.622-45.237-23.622l.2-14.866Z"
                            transform="translate(-435.258 -335.915)" fill="#fff" />
                        <g id="Group_49070" data-name="Group 49070" transform="translate(0.008 69.123)">
                            <path id="Path_18483" data-name="Path 18483"
                                d="M554.059,440.511c-3.851,1.152-112.876-2.579-116.931-5.36-1.56-1.03-1.944-16.947-1.852-36.193l.008-.6,70.363,1.621-.067,1.192s41.091,20.095,45.233,23.622C554.921,428.3,557.912,439.374,554.059,440.511Z"
                                transform="translate(-435.262 -398.355)" fill="#71c5df" />
                            <g id="Group_49069" data-name="Group 49069" transform="translate(6.833 2.487)">
                                <path id="Path_18484" data-name="Path 18484"
                                    d="M549.718,419.837c-18.362-1.082-92.528-3.706-110.76-3.378-.149,0-.153.1,0,.116,18.177,1.467,92.36,3.491,110.755,3.561C550.1,420.138,550.106,419.858,549.718,419.837Z"
                                    transform="translate(-438.844 -384.437)" fill="#263238" />
                                <path id="Path_18485" data-name="Path 18485"
                                    d="M485.615,400.4c-7.186-1.6-16.07-.79-21.771,4.174-.221.193.055.5.292.439a101.641,101.641,0,0,1,21.418-3.7A.463.463,0,0,0,485.615,400.4Z"
                                    transform="translate(-416.229 -399.659)" fill="#263238" />
                                <path id="Path_18486" data-name="Path 18486"
                                    d="M488.295,402.142c-7.186-1.6-16.07-.79-21.771,4.174-.221.193.055.5.292.439a101.668,101.668,0,0,1,21.418-3.7A.462.462,0,0,0,488.295,402.142Z"
                                    transform="translate(-413.797 -398.077)" fill="#263238" />
                                <path id="Path_18487" data-name="Path 18487"
                                    d="M490.975,403.885c-7.186-1.6-16.07-.79-21.771,4.174-.221.193.055.5.292.437a101.786,101.786,0,0,1,21.418-3.7A.462.462,0,0,0,490.975,403.885Z"
                                    transform="translate(-411.365 -396.495)" fill="#263238" />
                            </g>
                        </g>
                    </g>
                    <g id="Group_49074" data-name="Group 49074">
                        <path id="Path_18488" data-name="Path 18488"
                            d="M434.878,582.165l76.319-1.524S520.5,443.306,518.77,397.52c-1.883-49.733-23.248-197.714-23.248-197.714H431.067s16.476,148.557,16.827,198.313C448.216,444.244,434.878,582.165,434.878,582.165Z"
                            transform="translate(-431.067 -199.806)" fill="#0c182c" />
                        <path id="Path_18489" data-name="Path 18489"
                            d="M482.859,199.807,444.141,333.762c-4.584-63.393-13.073-133.955-13.073-133.955Z"
                            transform="translate(-431.066 -199.805)" fill="#0c182c" />
                        <path id="Path_18490" data-name="Path 18490"
                            d="M437.481,203.63l4.094,44.172,3.79,44.2c2.428,29.476,4.733,58.958,6.486,88.5.429,7.382.691,14.8.586,22.221-.158,7.407-.456,14.8-.83,22.191q-1.142,22.164-2.85,44.283-1.677,22.121-3.579,44.222l-4.116,44.17,3.3-44.237q1.651-22.116,3.109-44.245c.931-14.755,1.93-29.5,2.612-44.268.349-7.38.62-14.761.752-22.139.08-7.365-.208-14.73-.656-22.109-1.61-29.51-3.941-59-6.093-88.487Z"
                            transform="translate(-425.246 -196.335)" fill="#0c182c" />
                        <g id="Group_49073" data-name="Group 49073" transform="translate(6.854 367.887)">
                            <g id="Group_49072" data-name="Group 49072">
                                <path id="Path_18491" data-name="Path 18491"
                                    d="M504.746,392.877c-11.915-.1-8.495-.242-20.411-.212-5.83.013-44.149.145-49.586,1.112a.126.126,0,0,0,0,.242c5.46.837,43.77.038,49.6-.088,11.915-.259,8.491-.315,20.4-.708A.174.174,0,0,0,504.746,392.877Z"
                                    transform="translate(-434.66 -392.661)" fill="#0c182c" />
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Group_49082" data-name="Group 49082">
                    <g id="Group_49078" data-name="Group 49078" transform="translate(0 272.312)">
                        <path id="Path_18492" data-name="Path 18492"
                            d="M397.1,342.527s-36.815,59.837-36.837,59.881c-5.98,12.266-11,23.126-13.078,28.988a.217.217,0,0,0-.032.1c-.784,2.211-1.143,3.691-.942,4.229a.462.462,0,0,0,.034.113c2.445,4.252,99.074,54.9,103.038,55.528,3.972.652,6.078-10.618,3.9-15.576-2.211-4.973-30.521-40.9-30.521-40.9l36.193-60.1Z"
                            transform="translate(-346.151 -342.527)" fill="#d3766a" />
                        <path id="Path_18493" data-name="Path 18493"
                            d="M346.22,412.29a.226.226,0,0,0,.017.105c2.453,4.252,99.078,54.895,103.05,55.53,3.97.654,6.07-10.621,3.9-15.581-2.222-4.975-30.527-40.9-30.527-40.9l6.459-10.73c-15.793-8.416-44.5-23.2-62.325-32.36-3.882,6.3-6.528,10.6-6.528,10.621-5.982,12.264-11.011,23.116-13.077,28.974a.259.259,0,0,0-.034.107C346.361,410.261,346.008,411.743,346.22,412.29Z"
                            transform="translate(-346.149 -319.088)" fill="#fff" />
                        <g id="Group_49077" data-name="Group 49077" transform="translate(0 59.345)">
                            <path id="Path_18494" data-name="Path 18494"
                                d="M449.288,463.128c-3.97-.63-100.591-51.268-103.04-55.534-.96-1.6,5.6-16.113,14.025-33.417l.269-.54,62.7,31.973-.578,1.047s28.314,35.924,30.518,40.9C455.358,452.5,453.254,463.774,449.288,463.128Z"
                                transform="translate(-346.154 -373.637)" fill="#71c5df" />
                            <g id="Group_49076" data-name="Group 49076" transform="translate(5.536 29.656)">
                                <path id="Path_18495" data-name="Path 18495"
                                    d="M447.526,442.595c-16.079-8.935-81.769-43.461-98.34-51.072-.135-.063-.183.027-.053.1,15.741,9.2,81.71,43.2,98.254,51.236C447.734,443.032,447.863,442.784,447.526,442.595Z"
                                    transform="translate(-349.056 -387.079)" fill="#263238" />
                                <path id="Path_18496" data-name="Path 18496"
                                    d="M399.23,395.48c-5.782-4.555-14.137-7.68-21.428-5.681-.282.078-.168.477.074.521a101.793,101.793,0,0,1,20.9,5.956A.462.462,0,0,0,399.23,395.48Z"
                                    transform="translate(-323.117 -389.183)" fill="#263238" />
                                <path id="Path_18497" data-name="Path 18497"
                                    d="M400.889,398.211c-5.782-4.555-14.137-7.68-21.428-5.679-.282.076-.168.477.074.521a101.61,101.61,0,0,1,20.9,5.956A.463.463,0,0,0,400.889,398.211Z"
                                    transform="translate(-321.611 -386.703)" fill="#263238" />
                                <path id="Path_18498" data-name="Path 18498"
                                    d="M402.548,400.945c-5.782-4.555-14.137-7.68-21.426-5.681-.284.078-.17.479.072.523a101.6,101.6,0,0,1,20.9,5.956A.463.463,0,0,0,402.548,400.945Z"
                                    transform="translate(-320.105 -384.223)" fill="#263238" />
                            </g>
                        </g>
                    </g>
                    <g id="Group_49081" data-name="Group 49081" transform="translate(11.272)">
                        <path id="Path_18499" data-name="Path 18499"
                            d="M560.631,199.775a.283.283,0,0,1-.015.065c-.282,1.326-4.79,22.237-9.462,40.2-3.4,13.122-6.892,24.654-8.9,25.848-2.272,1.343-13.34,6.247-17.05,7.787,0,0-24.894,124.264-38.035,162.8-12.125,35.558-62.306,130.6-62.306,130.6l-72.8-36.784s56.856-96.455,62.834-113.765c9.265-26.828,43.989-157.826,43.989-157.826s-.265-41.013,13.546-58.918Z"
                            transform="translate(-352.063 -199.775)" fill="#0c182c" />
                        <g id="Group_49080" data-name="Group 49080" transform="translate(9.744 5.523)">
                            <path id="Path_18500" data-name="Path 18500"
                                d="M419.158,400.493c-10.4-5.813-7.335-4.29-17.8-9.986-5.122-2.787-38.8-21.069-44.033-22.83a.126.126,0,0,0-.113.214c4.387,3.354,38.375,21.048,43.55,23.734,10.576,5.494,7.6,3.8,18.235,9.176A.174.174,0,0,0,419.158,400.493Z"
                                transform="translate(-357.171 -52.918)" fill="#0c182c" />
                            <path id="Path_18501" data-name="Path 18501"
                                d="M417.949,237.059c1.993-.511,4.126-.675,6.158-1,1.642-.261,3.279-.549,4.9-.908a21.44,21.44,0,0,0,8.653-3.642c2.816-2.194,4.248-5.519,5.608-8.716q1.213-2.847,2.489-5.666c.41-.912.822-1.822,1.246-2.728a31.721,31.721,0,0,0,1.538-3.275c.051-.162.313-.076.248.074a21.334,21.334,0,0,0-.693,2.358c-.278.916-.538,1.833-.822,2.747-.506,1.629-1,3.264-1.536,4.883-.965,2.909-1.826,5.957-3.468,8.575-3.006,4.784-8.38,6.713-13.752,7.392-1.539.195-3.094.3-4.643.391a33.332,33.332,0,0,1-5.87.044A.272.272,0,0,1,417.949,237.059Z"
                                transform="translate(-302.191 -195.073)" fill="#0c182c" />
                            <g id="Group_49079" data-name="Group 49079" transform="translate(163.381 4.78)">
                                <path id="Path_18502" data-name="Path 18502"
                                    d="M465.779,232.91a144.721,144.721,0,0,0-22.819,10.627.258.258,0,0,0,.214.469c7.686-3.3,15.524-6.459,23.019-10.179A.5.5,0,0,0,465.779,232.91Z"
                                    transform="translate(-442.819 -180.036)" fill="#0c182c" />
                                <path id="Path_18503" data-name="Path 18503"
                                    d="M463.528,205.4c-.021-.267-.488-.324-.486-.038a255.645,255.645,0,0,1-1.289,25.682c-.357,3.958-3.541,25.838-10.406,24.244-1.15-.267-1.736-1.4-1.917-3.132-.218-2.083.566-4.637.912-6.709.761-4.58,1.374-9.176,1.766-13.8a125.55,125.55,0,0,0-.279-25.884.222.222,0,0,0-.443.027c.01,11.955-.3,23.889-2.091,35.731-.858,5.69-3.726,14.259,2.226,15.135,6.064.891,9.984-13.46,10.725-17.386C464.312,228.315,464.394,216.488,463.528,205.4Z"
                                    transform="translate(-438.254 -205.176)" fill="#0c182c" />
                                <path id="Path_18504" data-name="Path 18504"
                                    d="M450.828,231.985a37.1,37.1,0,0,0-1.543,4.534,10.255,10.255,0,0,0-.641,3.727c.025.242.345.265.475.1a13.125,13.125,0,0,0,1.595-3.626c.523-1.4,1.074-2.8,1.557-4.212A.768.768,0,0,0,450.828,231.985Z"
                                    transform="translate(-437.555 -181.281)" fill="#0c182c" />
                            </g>
                            <path id="Path_18505" data-name="Path 18505"
                                d="M362.375,515.579c10.362-19.509,21.044-38.84,31.767-58.153,5.286-9.521,10.8-18.933,15.848-28.583,4.985-9.534,8.365-19.549,11.518-29.8q9.617-31.255,18.216-62.811t16.144-63.372c1.267-5.3,2.651-10.606,3.674-15.963a49.049,49.049,0,0,0-.025-16.34,52.285,52.285,0,0,1-.118-15.217,101,101,0,0,1,3.558-14.866c.8-2.564,1.675-5.1,2.554-7.642a.234.234,0,0,1,.448.135c-3.138,10.27-6.346,20.653-5.673,31.531.324,5.231,1.707,10.362,1.618,15.625-.09,5.234-1.467,10.368-2.642,15.438q-7.42,32.007-15.949,63.74-8.493,31.738-18.021,63.185c-3.09,10.19-5.978,20.547-10.364,30.275-4.391,9.748-9.954,18.956-15.186,28.265-10.816,19.238-21.722,38.426-32.87,57.474q-2.12,3.619-4.262,7.226C362.518,515.878,362.291,515.735,362.375,515.579Z"
                                transform="translate(-352.465 -202.671)" fill="#0c182c" />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Group_49086" data-name="Group 49086" transform="translate(348.217 4742.693)">
                <path id="Path_18506" data-name="Path 18506"
                    d="M451.772,172.028c2.188,19.024,13.239,76.324,30.167,85.18,11.108,5.809,57.722,19.81,70.846,13.683,5.118-2.388,10.034-42.734,3.3-43.119s-46.4-5.854-50.721-8.512c-4.3-2.642-28.4-52.9-38.787-66.588C448.661,129.069,450.594,161.782,451.772,172.028Z"
                    transform="translate(-449.494 -143.367)" fill="#d3766a" />
                <g id="Group_49085" data-name="Group 49085">
                    <path id="Path_18507" data-name="Path 18507"
                        d="M538.534,224.985l-8.747,48.981s-48.41-8.775-56.937-22.325c-4.985-7.92-22.073-59.212-22.609-96.854-.2-14.021,10.988-14.7,19.354-1.534s35.758,62.714,38.194,64.675S538.534,224.985,538.534,224.985Z"
                        transform="translate(-450.237 -143.809)" fill="#71c5df" />
                    <g id="Group_49084" data-name="Group 49084" transform="translate(68.369 83.802)">
                        <path id="Path_18508" data-name="Path 18508"
                            d="M486.078,229.562A82.9,82.9,0,0,1,487.554,219q.867-5.139,1.808-10.263c.609-3.346,1.276-6.682,1.978-10.011.355-1.7.731-3.386,1.106-5.076q.3-1.365.61-2.728c.231-1.019.612-2.034.74-3.067a.122.122,0,1,1,.238.044,31.186,31.186,0,0,0-.4,3.132q-.183,1.285-.374,2.568c-.261,1.78-.525,3.56-.809,5.336-.544,3.428-1.124,6.854-1.768,10.265-.631,3.346-1.269,6.688-1.938,10.024a83.423,83.423,0,0,1-2.39,10.391A.141.141,0,0,1,486.078,229.562Z"
                            transform="translate(-486.078 -187.74)" fill="#263238" />
                    </g>
                    <path id="Path_18509" data-name="Path 18509"
                        d="M510.745,270.622c-14.488-4.521-32.906-11.568-37.9-19.486-4.975-7.918-22.073-59.206-22.6-96.856-.073-5.034,1.324-8.351,3.542-9.914Z"
                        transform="translate(-450.238 -143.303)" fill="#0c182c" />
                </g>
            </g>
            <g id="Group_65935" data-name="Group 65935" transform="translate(-259.217 -202.64)">
                <g id="Group2_49086" data-name="Group 49086" transform="translate(259.217 202.64)">
                    <path id="Path_18506" data-name="Path 18506"
                        d="M451.772,166.663c2.188,15.344,13.239,61.56,30.167,68.7,11.108,4.685,57.722,15.978,70.846,11.036,5.118-1.926,10.034-34.467,3.3-34.778s-46.4-4.722-50.721-6.865c-4.3-2.131-28.4-42.666-38.787-53.707C448.661,132.014,450.594,158.4,451.772,166.663Z"
                        transform="translate(-449.494 -143.367)" fill="#d3766a" />
                    <g id="Group_49085" data-name="Group 49085">
                        <path id="Path_18507" data-name="Path 18507"
                            d="M538.534,208.033l-.95,46.355s-61.515-10.6-70.042-21.321c-4.985-6.266-16.765-50.79-17.3-80.572-.2-11.093,10.988-11.63,19.354-1.213s37.853,47.888,40.289,49.439S538.534,208.033,538.534,208.033Z"
                            transform="translate(-450.237 -143.809)" fill="#71c5df" />
                        <g id="Group_49084" data-name="Group 49084" transform="translate(67.369 60.817)">
                            <path id="Path_18508" data-name="Path 18508"
                                d="M486.078,229.562A82.9,82.9,0,0,1,487.554,219q.867-5.139,1.808-10.263c.609-3.346,1.276-6.682,1.978-10.011.355-1.7.731-3.386,1.106-5.076q.3-1.365.61-2.728c.231-1.019.612-2.034.74-3.067a.122.122,0,1,1,.238.044,31.186,31.186,0,0,0-.4,3.132q-.183,1.285-.374,2.568c-.261,1.78-.525,3.56-.809,5.336-.544,3.428-1.124,6.854-1.768,10.265-.631,3.346-1.269,6.688-1.938,10.024a83.423,83.423,0,0,1-2.39,10.391A.141.141,0,0,1,486.078,229.562Z"
                                transform="translate(-486.078 -187.74)" fill="#263238" />
                        </g>
                        <path id="Path_18509" data-name="Path 18509"
                            d="M510.745,270.622c-14.488-4.521-32.906-11.568-37.9-19.486-4.975-7.918-22.073-59.206-22.6-96.856-.073-5.034,1.324-8.351,3.542-9.914Z"
                            transform="translate(-450.238 -143.303)" fill="#0c182c" />
                    </g>
                </g>
                <g id="Group_49100" data-name="Group 49100" transform="translate(328.475 238.556)">
                    <g id="Group_49099" data-name="Group 49099" transform="translate(0 0)">
                        <g id="Group_49098" data-name="Group 49098">
                            <path id="Path_18529" data-name="Path 18529"
                                d="M527.384,180.729c2.241,7.239,9.128,16.821,8.994,22.452a6.462,6.462,0,0,1-4.523,6.244s5.126,3.55,4.162,8.639c-.5,2.621-5.116,4.13-5.116,4.13s3.985,3.023,2.85,7.5c-.906,3.565-5.4,4.544-5.4,4.544s2.47,4.681.384,8.018c-4.229,6.764-35.475,2.264-38.644-9.94-1.477-5.688,6.095-4.176,6.095-4.176s-10.362-4.805-9.6-11.27,10.484-3.9,10.484-3.9-10-4.746-8.535-11.369c1.528-6.9,10.81-2.669,10.81-2.669s-6.27-5.711-3.985-10.524c3.773-7.949,15.421,4.407,22.523,3.767l5.42-10.778Z"
                                transform="translate(-486.544 -166.217)" fill="#d3766a" />
                            <path id="Path_18530" data-name="Path 18530"
                                d="M515.448,198.377c-1.522-7.039,7.941-14.1,7.451-21.039-.662-9.389-12.2-1.94-16.378,10.955l-1.488,10.581Z"
                                transform="matrix(0.998, -0.07, 0.07, 0.998, -482.486, -135.815)" fill="#d3766a" />
                            <g id="Group_49097" data-name="Group 49097" transform="translate(9.056 31.893)">
                                <path id="Path_18531" data-name="Path 18531"
                                    d="M523.355,212.256a.083.083,0,0,1,.008.166c-10.639,1.024-22.639-1.112-31.91-6.555-.305-.179-.147-.75.227-.618C502.307,209.032,512.041,211.542,523.355,212.256Z"
                                    transform="translate(-491.292 -175.874)" fill="#263238" />
                                <path id="Path_18532" data-name="Path 18532"
                                    d="M526.361,207.137c.122.008.112.212-.019.223-10.969,1-24.419-1.78-34.367-9.313-.341-.259-.389-.832.027-.565C499,201.991,516.173,206.548,526.361,207.137Z"
                                    transform="translate(-490.917 -182.968)" fill="#263238" />
                                <path id="Path_18533" data-name="Path 18533"
                                    d="M493.1,189.878c9.818,6.854,20.844,9.87,32.616,11.341.154.019.111.235-.023.254-5.954.849-13.359-.87-19.007-2.7a31.579,31.579,0,0,1-13.826-8.556C492.715,190.059,492.895,189.731,493.1,189.878Z"
                                    transform="translate(-489.914 -189.841)" fill="#263238" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g id="Group_49089" data-name="Group 49089" transform="translate(270.393 4737.684)">
                <g id="Group_49088" data-name="Group 49088">
                    <g id="Group_49087" data-name="Group 49087">
                        <path id="Path_18510" data-name="Path 18510"
                            d="M409.461,278.053c.263.317,113.757,4.174,114.879,2.993.721-.767-10.467-112.492-27.252-132.327C491.579,142.208,475.3,138.164,466.7,144,440.051,162.1,408.522,276.9,409.461,278.053Z"
                            transform="translate(-409.44 -141.182)" fill="#71c5df" />
                    </g>
                </g>
            </g>
            <g id="Group_49100" data-name="Group 49100" transform="translate(417.475 4798.609)">
                <g id="Group_49099" data-name="Group 49099" transform="translate(0 0)">
                    <g id="Group_49098" data-name="Group 49098">
                        <path id="Path_18529" data-name="Path 18529"
                            d="M527.384,180.729c2.241,7.239,9.128,16.821,8.994,22.452a6.462,6.462,0,0,1-4.523,6.244s5.126,3.55,4.162,8.639c-.5,2.621-5.116,4.13-5.116,4.13s3.985,3.023,2.85,7.5c-.906,3.565-5.4,4.544-5.4,4.544s2.47,4.681.384,8.018c-4.229,6.764-35.475,2.264-38.644-9.94-1.477-5.688,6.095-4.176,6.095-4.176s-10.362-4.805-9.6-11.27,10.484-3.9,10.484-3.9-10-4.746-8.535-11.369c1.528-6.9,10.81-2.669,10.81-2.669s-6.27-5.711-3.985-10.524c3.773-7.949,15.421,4.407,22.523,3.767l5.42-10.778Z"
                            transform="translate(-486.544 -166.217)" fill="#d3766a" />
                        <path id="Path_18530" data-name="Path 18530"
                            d="M515.448,198.377c-1.522-7.039,7.941-14.1,7.451-21.039-.662-9.389-12.2-1.94-16.378,10.955l-1.488,10.581Z"
                            transform="translate(-469.764 -173.121)" fill="#d3766a" />
                        <g id="Group_49097" data-name="Group 49097" transform="translate(9.056 31.893)">
                            <path id="Path_18531" data-name="Path 18531"
                                d="M523.355,212.256a.083.083,0,0,1,.008.166c-10.639,1.024-22.639-1.112-31.91-6.555-.305-.179-.147-.75.227-.618C502.307,209.032,512.041,211.542,523.355,212.256Z"
                                transform="translate(-491.292 -175.874)" fill="#263238" />
                            <path id="Path_18532" data-name="Path 18532"
                                d="M526.361,207.137c.122.008.112.212-.019.223-10.969,1-24.419-1.78-34.367-9.313-.341-.259-.389-.832.027-.565C499,201.991,516.173,206.548,526.361,207.137Z"
                                transform="translate(-490.917 -182.968)" fill="#263238" />
                            <path id="Path_18533" data-name="Path 18533"
                                d="M493.1,189.878c9.818,6.854,20.844,9.87,32.616,11.341.154.019.111.235-.023.254-5.954.849-13.359-.87-19.007-2.7a31.579,31.579,0,0,1-13.826-8.556C492.715,190.059,492.895,189.731,493.1,189.878Z"
                                transform="translate(-489.914 -189.841)" fill="#263238" />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Group_49122" data-name="Group 49122" transform="translate(327.639 4669.758)">
                <g id="Group_49109" data-name="Group 49109" transform="translate(4.688 45.295)">
                    <g id="Group_49108" data-name="Group 49108">
                        <path id="Path_18543" data-name="Path 18543"
                            d="M460.4,172.587c-6.095.372-15.759-11.276-18.475-19.211-.183-.546,1.484-5.654,3.218-11.957,1.036-3.83,2.1-8.117,2.787-12.1L471.859,139.7s-3.9,7.924-5.055,14.875a8.984,8.984,0,0,0-.111,1.665c-.006.076,0,.17-.012.277C466.651,159.713,466.287,172.223,460.4,172.587Z"
                            transform="translate(-441.908 -129.319)" fill="#d3766a" />
                    </g>
                    <path id="Path_18544" data-name="Path 18544"
                        d="M463.07,155.911c-.008.071,0,.16-.01.267a16.227,16.227,0,0,1-2.589-.292c-14.4-2.823-14.748-21.106-14.547-26.2l5.98,2.592,16.337,7.09s-3.9,7.924-5.049,14.877A8.794,8.794,0,0,0,463.07,155.911Z"
                        transform="translate(-438.29 -128.983)" fill="#263238" />
                </g>
                <g id="Group_49111" data-name="Group 49111" transform="translate(0 64.132)">
                    <g id="Group_49110" data-name="Group 49110">
                        <path id="Path_18545" data-name="Path 18545"
                            d="M469.384,144.339l-6.72,14.656L445,139.194l-5.553,5.812s16.72,30.914,16.9,29.562l6.78-12.584,8.458,9.389.36-22.532Z"
                            transform="translate(-439.45 -139.194)" fill="#ebebeb" />
                        <path id="Path_18546" data-name="Path 18546"
                            d="M472.073,156.8c-.067-3.724-.027-7.474-.265-11.19-.008-.132-.212-.2-.25-.046-.918,3.546-.591,17.781-.771,21.18l-.038-.04c-.351-.406-7.859-8.281-7.943-8.166-1.435,1.982-6.406,11.144-6.755,11.982-2.644-4.908-12.819-22.784-16-27.46a.129.129,0,0,0-.229.118c2.295,5.16,15.741,29.22,15.968,29.165a144.659,144.659,0,0,0,7.094-13.309c1.253,1.7,8.79,10.541,8.844,9.99A116.694,116.694,0,0,0,472.073,156.8Z"
                            transform="translate(-439.127 -135.746)" fill="#263238" />
                    </g>
                </g>
                <g id="Group_49121" data-name="Group 49121" transform="translate(1.785)">
                    <g id="Group_49112" data-name="Group 49112" transform="translate(5.322 4.217)">
                        <path id="Path_18547" data-name="Path 18547"
                            d="M443.8,123.269c-2.539,9.254,3.06,35.618,9.752,41.343,9.7,8.3,25.216,8.678,32.172-2.9,6.741-11.222-1.162-45.456-8.6-50.618C466.159,103.489,447.55,109.6,443.8,123.269Z"
                            transform="translate(-443.176 -107.785)" fill="#d3766a" />
                    </g>
                    <g id="Group_49118" data-name="Group 49118" transform="translate(25.586 24.701)">
                        <g id="Group_49113" data-name="Group 49113" transform="translate(2.819 9.092)">
                            <path id="Path_18548" data-name="Path 18548"
                                d="M457.714,124.1c-.027-.063-.12.114-.1.187.423,1.766.612,3.859-.872,4.782-.048.029-.023.118.034.1C458.679,128.486,458.4,125.709,457.714,124.1Z"
                                transform="translate(-453.971 -122.564)" fill="#263238" />
                            <path id="Path_18549" data-name="Path 18549"
                                d="M457.046,123.3c-2.9.376-1.938,6.156.752,5.809C460.445,128.767,459.482,122.987,457.046,123.3Z"
                            transform="translate(-455.276 -123.289)" fill="#263238" />
                    </g>
                    <g id="Group_49114" data-name="Group 49114" transform="translate(16.844 6.708)">
                        <path id="Path_18550" data-name="Path 18550"
                            d="M462.632,123.2c0-.072.154.061.164.134.242,1.8.822,3.821,2.541,4.147.053.01.065.1,0,.1C463.316,127.635,462.572,124.945,462.632,123.2Z"
                            transform="translate(-462.628 -121.005)" fill="#263238" />
                        <path id="Path_18551" data-name="Path 18551"
                            d="M463.918,122.1c2.844-.7,4.033,5.04,1.4,5.688C462.724,128.425,461.534,122.685,463.918,122.1Z"
                            transform="translate(-462.627 -122.04)" fill="#263238" />
                    </g>
                    <path id="Path_18552" data-name="Path 18552"
                        d="M455.665,124.275a22.831,22.831,0,0,0,2.108-1.162,3.9,3.9,0,0,0,1.923-1.6,1.237,1.237,0,0,0-.443-1.446,3.073,3.073,0,0,0-3.107.2,4.519,4.519,0,0,0-2.245,2.356A1.322,1.322,0,0,0,455.665,124.275Z"
                        transform="translate(-453.798 -117.434)" fill="#263238" />
                    <path id="Path_18553" data-name="Path 18553"
                        d="M466.673,122.008a22.36,22.36,0,0,1-2.4-.214,3.878,3.878,0,0,1-2.4-.683,1.241,1.241,0,0,1-.179-1.5,3.077,3.077,0,0,1,2.924-1.072,4.51,4.51,0,0,1,3,1.248A1.322,1.322,0,0,1,466.673,122.008Z"
                        transform="translate(-446.789 -118.524)" fill="#263238" />
                    <path id="Path_18554" data-name="Path 18554"
                        d="M457.784,132.93c.5.349,1.024.855,1.682.8a4.8,4.8,0,0,0,1.85-.773.064.064,0,0,1,.094.08,2.444,2.444,0,0,1-2.112,1.351,1.934,1.934,0,0,1-1.673-1.349C457.581,132.942,457.711,132.881,457.784,132.93Z"
                        transform="translate(-450.333 -105.464)" fill="#263238" />
                    <g id="Group_49116" data-name="Group 49116" transform="translate(8.383 1.054)">
                        <g id="Group_49115" data-name="Group 49115">
                            <path id="Path_18555" data-name="Path 18555"
                                d="M458.248,139.813a6.188,6.188,0,0,0,4.523,2.035,7.784,7.784,0,0,0,2.211-.267,2.274,2.274,0,0,0,.41-.116c.128-.038.244-.074.382-.126a.454.454,0,0,0,.294-.462l0-.013a1,1,0,0,0-.048-.179l0-.013-.029-.156c-.193-1.387-.677-3.46-.677-3.46.547.168,3.308.895,3.148.181-1.351-5.9-2.854-12.468-5.444-18.059a.168.168,0,0,0-.318.1c1.074,5.725,3.146,11.224,4.317,16.957a10.834,10.834,0,0,0-3.023-.393c-.187.09,1.005,3.993,1.042,4.622a.22.22,0,0,1,0,.069c-2.266.681-4.13.273-6.638-.908C458.239,139.544,458.119,139.689,458.248,139.813Z"
                                transform="translate(-458.193 -119.076)" fill="#263238" />
                        </g>
                    </g>
                    <g id="Group_49117" data-name="Group 49117" transform="translate(8.254 21.812)">
                        <path id="Path_18556" data-name="Path 18556"
                            d="M464.218,131.568a7.172,7.172,0,0,1-2.644,2.5,3.232,3.232,0,0,1-1.942.313c-1.448-.231-1.612-1.524-1.461-2.659a7.693,7.693,0,0,1,.467-1.763A9.3,9.3,0,0,0,464.218,131.568Z"
                            transform="translate(-458.125 -129.958)" fill="#263238" />
                        <path id="Path_18557" data-name="Path 18557"
                            d="M461.574,133.228a3.232,3.232,0,0,1-1.942.313c-1.448-.231-1.612-1.524-1.461-2.659A3.336,3.336,0,0,1,461.574,133.228Z"
                            transform="translate(-458.125 -129.119)" fill="#ff98b9" />
                    </g>
                </g>
                <g id="Group_49119" data-name="Group 49119">
                    <path id="Path_18558" data-name="Path 18558"
                        d="M455.683,139.007c1.593-4.958-1.368-13.168,1.059-15.421,5.253-4.878,16.314,4.134,23.807,4.057,7.262-.074,9.614-4.78,8.207-10.652s-6.476-9.75-14.645-10.833c-8.451-1.124-16.079,2.4-20.213,5.143,0,0,1.786-2.97,1.085-3.874s-4.212,1.984-5.278,3.207a10.952,10.952,0,0,0-1.721,3.529,11.742,11.742,0,0,0-3.321-.29c-1.845.078-4.147,1.56-4.273,2s2.524.731,4.378.794c0,0-3.378,4.645-3.241,8.569s6.549,22.471,8.02,22.649C451.437,148.11,455.035,141.021,455.683,139.007Z"
                        transform="translate(-440.386 -105.24)" fill="#263238" />
                    <path id="Path_18559" data-name="Path 18559"
                        d="M445.31,111.313c.063-.04.153.048.092.1a12.43,12.43,0,0,0-3.838,5.616,15.44,15.44,0,0,0,.051,8.09c1.234,5.137,3.829,9.774,6.1,14.5.065.137-.166.254-.242.12-2.722-4.849-5.5-10.12-6.581-15.617C439.963,119.435,440.965,114.012,445.31,111.313Z"
                        transform="translate(-440.216 -100.376)" fill="#263238" />
                    <path id="Path_18560" data-name="Path 18560"
                        d="M448.878,114.279a17.722,17.722,0,0,1,6.593.322c2.06.486,3.838,1.736,5.692,2.7,4.16,2.175,8.712,3.838,13.475,3.468a.047.047,0,0,1,.006.093c-4.349.618-8.63-.609-12.531-2.474-1.967-.94-3.832-2.3-5.9-3a22.99,22.99,0,0,0-7.31-.729A.2.2,0,0,1,448.878,114.279Z"
                        transform="translate(-432.839 -97.833)" fill="#263238" />
                    <path id="Path_18561" data-name="Path 18561"
                        d="M449.8,109.5a34.581,34.581,0,0,1,9.265-3.23,27.218,27.218,0,0,1,9.387-.477c5.393.79,10.4,4.136,11.747,9.639a11.753,11.753,0,0,1-.961,8.4.152.152,0,0,1-.271-.135,12.137,12.137,0,0,0-2.991-14.223c-4.469-3.756-10.671-3.951-16.161-2.983a49.817,49.817,0,0,0-10,3.041C449.8,109.545,449.782,109.513,449.8,109.5Z"
                        transform="translate(-431.848 -105.575)" fill="#263238" />
                </g>
                <g id="Group_49120" data-name="Group 49120" transform="translate(0.721 34.777)">
                    <path id="Path_18562" data-name="Path 18562"
                        d="M452.267,130.168s-6.4-8.275-10.186-5.952,1.324,14.521,5.969,16a4.643,4.643,0,0,0,6.095-2.795Z"
                        transform="translate(-440.764 -123.805)" fill="#d3766a" />
                    <path id="Path_18563" data-name="Path 18563"
                        d="M442.3,125.835c-.072,0-.082.1-.017.124,3.262,1.141,5.089,4.025,6.417,7.043-1.007-1.137-2.335-1.723-3.867-.515-.08.065.008.191.1.168a2.854,2.854,0,0,1,3.189.843,13.706,13.706,0,0,1,1.627,2.468c.164.279.673.1.547-.225l-.029-.072C450.142,131.586,446.695,126.033,442.3,125.835Z"
                        transform="translate(-439.429 -121.963)" fill="#263238" />
                </g>
            </g>
        </g>
        <rect id="Rectangle_12083" data-name="Rectangle 12083" width="9.441" height="281.035"
            transform="translate(135.908 4601.623)" fill="#4a576f" />
        <rect id="Rectangle_12085" data-name="Rectangle 12085" width="9.441" height="281.035"
            transform="translate(533.854 4601.623)" fill="#4a576f" />
        <g id="Group_51412" data-name="Group 51412" transform="translate(134.878 4904)">
            <rect id="Rectangle_12087" data-name="Rectangle 12087" width="409.448" height="470"
                transform="translate(409.448 470) rotate(180)" fill="#1c324c" />
            <rect id="Rectangle_12088" data-name="Rectangle 12088" width="409.448" height="470"
                transform="translate(409.448 470) rotate(180)" fill="#1c324c" opacity="0.4" />
        </g>
        <rect id="Rectangle_12103" data-name="Rectangle 12103" width="468.713" height="21.342"
            transform="translate(105.245 4882.658)" fill="#4a576f" />
        <g id="Group_57121" data-name="Group 57121" transform="translate(-30 4324)">
            <g id="Group_51424" data-name="Group 51424" transform="translate(119 299.85)">
                <path id="Path_20447" data-name="Path 20447"
                    d="M103.335,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621H64.8c-6.609,0-11.962-3.444-11.962-11.621v-23.9Z"
                    transform="translate(-52.84 -91.667)" fill="#1c324c" />
                <path id="Path_20448" data-name="Path 20448"
                    d="M133.659,91.667v23.9c0,8.177-5.353,11.621-11.945,11.621H95.124c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-32.684 -91.667)" fill="#1c324c" />
                <path id="Path_20449" data-name="Path 20449"
                    d="M163.987,91.667v23.9c0,8.177-5.353,11.621-11.945,11.621h-26.59c-6.593,0-11.943-3.444-11.943-11.621v-23.9Z"
                    transform="translate(-38.383 -91.667)" fill="#1c324c" />
                <path id="Path_20450" data-name="Path 20450"
                    d="M194.333,91.667v23.9c0,8.177-5.353,11.621-11.962,11.621H155.8c-6.609,0-11.96-3.444-11.96-11.621v-23.9Z"
                    transform="translate(-31.157 -91.667)" fill="#1c324c" />
                <path id="Path_20451" data-name="Path 20451"
                    d="M224.655,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621h-26.59c-6.609,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-23.927 -91.667)" fill="#1c324c" />
                <path id="Path_20452" data-name="Path 20452"
                    d="M254.984,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621h-26.59c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-16.7 -91.667)" fill="#1c324c" />
                <path id="Path_20453" data-name="Path 20453"
                    d="M285.313,91.667v23.9c0,8.177-5.334,11.621-11.943,11.621H246.78c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(-9.473 -91.667)" fill="#1c324c" />
                <path id="Path_20454" data-name="Path 20454"
                    d="M315.661,91.667v23.9c0,8.177-5.353,11.621-11.962,11.621H277.126c-6.609,0-11.962-3.444-11.962-11.621v-23.9Z"
                    transform="translate(-2.249 -91.667)" fill="#1c324c" />
                <path id="Path_20455" data-name="Path 20455"
                    d="M345.982,91.667v23.9c0,8.177-5.353,11.621-11.943,11.621H307.447c-6.609,0-11.943-3.444-11.943-11.621v-23.9Z"
                    transform="translate(4.984 -91.667)" fill="#1c324c" />
                <path id="Path_20456" data-name="Path 20456"
                    d="M376.311,91.667v23.9c0,8.177-5.351,11.621-11.943,11.621h-26.59c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(12.211 -91.667)" fill="#1c324c" />
                <path id="Path_20457" data-name="Path 20457"
                    d="M406.64,91.667v23.9c0,8.177-5.334,11.621-11.943,11.621h-26.59c-6.593,0-11.945-3.444-11.945-11.621v-23.9Z"
                    transform="translate(19.438 -91.667)" fill="#1c324c" />
                <path id="Path_20458" data-name="Path 20458"
                    d="M436.988,91.667v23.9c0,8.177-5.353,11.621-11.962,11.621H398.453c-6.609,0-11.962-3.444-11.962-11.621v-23.9Z"
                    transform="translate(13.738 -91.667)" fill="#1c324c" />
                <path id="Path_20459" data-name="Path 20459"
                    d="M467.309,91.667v23.9c0,8.177-5.353,11.621-11.943,11.621H428.774c-6.593,0-11.943-3.444-11.943-11.621v-23.9Z"
                    transform="translate(33.894 -91.667)" fill="#1c324c" />
            </g>
            <path id="Path_20460" data-name="Path 20460"
                d="M554.044,125.115H52.84s31.1-33.91,49.112-83.794H504.931a275.437,275.437,0,0,0,30.648,59.631A227.692,227.692,0,0,0,554.044,125.115Z"
                transform="translate(66.16 174.735)" fill="#37445d" />
            <rect id="Rectangle_12105" data-name="Rectangle 12105" width="1.699" height="119.32"
                transform="translate(370.566 216.054)" fill="#fff" />
            <path id="Path_20461" data-name="Path 20461"
                d="M222.053,125.11c-.05.378-.068.588-.068.629l.018,34.9h-1.7V125.615q0-.065.05-.5c.561-5.782,5.829-58.945,6.644-83.79h1.7C227.9,66.061,222.682,118.741,222.053,125.11Z"
                transform="translate(111.451 174.734)" fill="#fff" />
            <path id="Path_20463" data-name="Path 20463"
                d="M203.581,41.32c-1.376,24.909-10.364,76.705-11.6,83.79-.052.273-.1.483-.12.609v34.92h-1.7l.018-35.256c0-.022.017-.1.05-.273.764-4.331,10.211-58.5,11.639-83.79Z"
                transform="translate(103.822 174.734)" fill="#fff" />
            <path id="Path_20465" data-name="Path 20465"
                d="M178.128,41.32c-1.7,28.126-14.731,76.832-16.617,83.79-.085.315-.153.524-.186.672v34.857h-1.7l.052-35.361a1.068,1.068,0,0,1,.05-.168c.9-3.28,14.918-54.739,16.7-83.79Z"
                transform="translate(96.31 174.734)" fill="#fff" />
            <path id="Path_20467" data-name="Path 20467"
                d="M158.429,41.32c-2.31,31.994-22.937,76.223-26.573,83.79-.17.336-.306.609-.391.8v34.73h-1.7V125.32l.1-.21.017-.043c.255-.524,24.28-49.819,26.844-83.747Z"
                transform="translate(88.926 174.734)" fill="#fff" />
            <path id="Path_20469" data-name="Path 20469"
                d="M139.659,41.32c-10.109,40.634-34.1,76.832-38.893,83.79-.255.4-.474.692-.611.9v34.625h-1.7V125.215l.068-.1.136-.19c.288-.421,28.034-39.289,39.214-83.6Z"
                transform="translate(85.857 174.734)" fill="#fff" />
            <path id="Path_20471" data-name="Path 20471"
                d="M122.639,41.32C105.054,90.488,77.8,118.783,71.226,125.11c-.493.461-.849.8-1.1,1.03v34.5h-1.7V125.067l.358-.315c.34-.295,32.179-28.589,52.006-83.433Z"
                transform="translate(76.513 174.734)" fill="#fff" />
            <path id="Path_20474" data-name="Path 20474"
                d="M286.007,125.488v35.151h-1.7V125.615c0-.022-.017-.19-.052-.5-.578-5.739-5.844-58.9-6.642-83.79h1.7c.816,25.015,6.15,78.744,6.659,83.79C285.989,125.34,286.007,125.467,286.007,125.488Z"
                transform="translate(125.108 174.734)" fill="#fff" />
            <path id="Path_20476" data-name="Path 20476"
                d="M318.16,125.615v35.025h-1.7V125.72c-.017-.126-.068-.336-.118-.609-1.24-7.085-10.229-58.882-11.606-83.79h1.716c1.428,25.288,10.875,79.46,11.622,83.79.033.168.052.251.052.273Z"
                transform="translate(130.719 174.734)" fill="#fff" />
            <path id="Path_20478" data-name="Path 20478"
                d="M350.727,125.615v35.025h-1.7V125.783c-.035-.148-.1-.358-.188-.672-1.954-6.9-15.036-54.13-16.634-83.79h1.7c1.683,30.732,15.835,80.678,16.735,83.79.018.083.035.125.035.146Z"
                transform="translate(136.187 174.734)" fill="#fff" />
            <path id="Path_20480" data-name="Path 20480"
                d="M384.627,125.32v35.319h-1.7v-34.73c-.085-.19-.221-.443-.375-.8-3.585-7.609-24.228-52.427-26.59-83.79h1.716c2.616,33.234,26.573,83.223,26.828,83.747l.018.043Z"
                transform="translate(139.495 174.734)" fill="#fff" />
            <path id="Path_20482" data-name="Path 20482"
                d="M420.957,125.235v35.4h-1.7V125.971c-.118-.188-.306-.483-.559-.86-4.657-7.295-29.938-47.97-38.959-83.79H381.5c9.956,38.763,38.959,83.16,39.266,83.622l.1.168Z"
                transform="translate(135.761 174.734)" fill="#fff" />
            <path id="Path_20484" data-name="Path 20484"
                d="M456.257,125.13v35.509h-1.7V126.1c-.2-.21-.509-.546-.9-.987-6.642-7.317-39.162-44.377-51.838-83.79h1.819c14.135,42.987,51.923,83.075,52.331,83.5l.271.295Z"
                transform="translate(137.614 174.734)" fill="#fff" />
            <g id="Group_51426" data-name="Group 51426" transform="translate(219.127 249.433)" opacity="0.1">
                <path id="Path_20485" data-name="Path 20485"
                    d="M159.673,79.959s126.649-2.943,154.489-5.045S440.266,60.2,444.633,61.463s-85.706,20.022-125.012,21.36S155.917,84.8,159.673,79.959Z"
                    transform="translate(-159.59 -61.375)" />
                <path id="Path_20486" data-name="Path 20486"
                    d="M197.085,68.052c-4.828-1.761,80.52-5.885,83.523-4.2S202.271,69.944,197.085,68.052Z"
                    transform="translate(-134.811 -59.994)" />
                <path id="Path_20487" data-name="Path 20487"
                    d="M357.574,73.738c1.152,3.741-42.7,2.521-43.03-.885S356.336,69.717,357.574,73.738Z"
                    transform="translate(-56.646 -55.317)" />
            </g>
        </g>
        <g id="Group_49107" data-name="Group 49107" transform="translate(261.409 4741.15)">
            <g id="Group_49104" data-name="Group 49104" transform="translate(0.762 1.441)">
                <path id="Path_18534" data-name="Path 18534"
                    d="M458.792,151.478c-19.5,21.863-61.764,72.027-52.3,85.651,15.564,22.4,56.949,44.952,70.083,50.448,5.667,2.373,31.117-37.946,25.968-42.026-6.179-4.9-46.826-26.04-46.816-28.116.009-2.281,11.028-30.539,16.064-53.227C478.043,136.048,466.928,142.356,458.792,151.478Z"
                    transform="translate(-405.131 -143.756)" fill="#d3766a" />
                <g id="Group_49103" data-name="Group 49103" transform="translate(60.826 97.875)">
                    <g id="Group_49102" data-name="Group 49102">
                        <path id="Path_18535" data-name="Path 18535"
                            d="M464.971,195.064s18.225,8.762,29.9,12.764c6.516,2.232,17.344,7.056,21.378,10.885,6.038,5.727,27.626,33.537,21,38.917-6,4.87-21.516-21.378-21.516-21.378s18,25.1,9.692,29.636-21.89-27.45-21.89-27.45,17.912,26.2,8.609,29.526c-7.031,2.512-20.968-25.766-20.968-25.766s15.24,22.63,8.121,25.523c-7.867,3.2-22.368-22.1-22.368-22.1-19.333,4.309-32.528-6.636-39.834-11.732C435.325,232.657,464.971,195.064,464.971,195.064Z"
                            transform="translate(-437.017 -195.064)" fill="#d3766a" />
                        <g id="Group_49101" data-name="Group 49101" transform="translate(51.653 33.549)">
                            <path id="Path_18536" data-name="Path 18536"
                                d="M475.936,212.681c8.391,10.625,14.309,23.24,15.822,28.726.185.668.328,1.267.3,1.221-3.363-6.461-14.292-26.821-16.245-29.877C475.768,212.674,475.882,212.613,475.936,212.681Z"
                                transform="translate(-453.468 -212.651)" fill="#263238" />
                            <path id="Path_18537" data-name="Path 18537"
                                d="M469.5,215.506c7.506,7.125,12.51,19.164,14.734,23.215.172.313-.051.3-.237-.006-2.384-3.958-12.031-19.328-14.593-23.152C469.366,215.5,469.45,215.456,469.5,215.506Z"
                                transform="translate(-459.284 -210.08)" fill="#263238" />
                            <path id="Path_18538" data-name="Path 18538"
                                d="M464.307,218.211a48.049,48.049,0,0,1,10.23,17.336c.118.332-.13.269-.309-.044-3.5-6.156-5.559-10.034-10.116-17.14C464.042,218.253,464.2,218.1,464.307,218.211Z"
                                transform="translate(-464.095 -207.64)" fill="#263238" />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Group_49106" data-name="Group 49106">
                <path id="Path_18539" data-name="Path 18539"
                    d="M503.9,243.755l-30.613,50.629s-65.665-44.4-68.439-59.28,42.635-74.159,55.759-85.847,16.382-5.986,14.488,8.733-18.349,59.475-18.158,60.364S503.9,243.755,503.9,243.755Z"
                    transform="translate(-404.731 -143)" fill="#71c5df" />
                <g id="Group_49105" data-name="Group 49105" transform="translate(16.228 33.435)">
                    <path id="Path_18540" data-name="Path 18540"
                        d="M480.134,227.471c.16-.275-44.643-24.015-47.457-25.678,2.766-6.659,5.04-13.552,7.262-20.409.719-2.215,1.4-4.443,2.083-6.665.536-1,1.064-2,1.534-3.043.811-1.791,1.513-3.609,2.152-5.471a.068.068,0,0,0-.126-.051c-.845,1.77-1.694,3.537-2.52,5.315-.029.059-.052.12-.08.179.067-.219.139-.437.206-.658.5-1.667.994-3.334,1.446-5.017.221-.82.444-1.641.656-2.463a15.828,15.828,0,0,1,.454-1.6c.261-.7.023-.341.223-1.284.021-.1-.133-.135-.168-.046-.544,1.425-.179.1-.588,1.595-.111.406-.288.8-.423,1.2-.275.8-.542,1.612-.816,2.419-.593,1.747-1.154,3.506-1.723,5.263q-1.643,5.07-3.352,10.118c-2.323,6.873-4.853,13.681-7.068,20.589a.379.379,0,0,0,.12.412.337.337,0,0,0,.162.238c2.764,1.721,44.516,24.232,46.982,25.644-.082.128-.444.763-.54.956q-.289.578-.6,1.145c-.084.153.13.311.225.158.235-.378.464-.757.683-1.145A17.08,17.08,0,0,0,480.134,227.471Z"
                        transform="translate(-396.384 -160.527)" fill="#263238" />
                    <path id="Path_18541" data-name="Path 18541"
                        d="M448.344,215.328c2.167-3.5,10.936-17.443,13.378-20.736.04-.053.137-.01.107.055-1.715,3.6-9.965,17.613-12.128,21.123-4.315,7-8.512,14.128-13.576,20.621-.09.113-.311.017-.238-.128C439.564,229.019,444.071,222.228,448.344,215.328Z"
                        transform="translate(-392.697 -129.632)" fill="#263238" />
                    <path id="Path_18542" data-name="Path 18542"
                        d="M449.772,229.938c-1.6-1.106-3.077-2.381-4.628-3.548s-3.1-2.346-4.653-3.508q-4.584-3.425-9.078-6.963t-8.935-7.125q-2.263-1.826-4.5-3.68a32.806,32.806,0,0,0-4.546-3.6.123.123,0,0,0-.166.177,27.173,27.173,0,0,0,3.989,3.722q2.2,1.909,4.422,3.787,4.447,3.757,9.042,7.335t9.28,7.035c1.562,1.152,3.151,2.27,4.748,3.375s3.287,2.087,4.866,3.214A.135.135,0,0,0,449.772,229.938Z"
                        transform="translate(-413.238 -123.347)" fill="#263238" />
                </g>
            </g>
        </g>
    </g>
</svg></template>