<template>
    <section id="clients" class="container-fluid">
        <div class="row">
            <div class="col-11 px-0 mx-auto text-center pt-xxl-4 mt-xxl-5 pt-lg-4 mt-lg-4 mt-md-5 pt-md-5 pt-5 mt-5">
                <SecTitle title="عملاؤنا" />
            </div>
        </div>
        <div class="row">
            <div class="col-10 pt-xxl-4 mt-xxl-4 pt-1 mt-5 px-0 position-relative mx-auto">

                <div class="row position-relative justify-content-evenly z-2">
                    <div class="col-6 col-6 d-flex flex-column justify-content-evenly">
                        <div class="row">
                            <clientLogo name="COMPANY NAME" slogn="Your slogan" imgSrc="MaskGroup_41.svg" />
                            <clientLogo name="COMPANY NAME" slogn="Your slogan" imgSrc="MaskGroup_42.svg" />
                            <clientLogo name="COMPANY NAME" slogn="Your slogan" imgSrc="MaskGroup_43.svg" />
                        </div>
                        <div class="row">
                            <clientLogo name="COMPANY NAME" slogn="Your slogan" imgSrc="MaskGroup_40.svg" />
                            <clientLogo name="COMPANY NAME" slogn="Your slogan" imgSrc="MaskGroup_45.svg" />
                            <clientLogo name="COMPANY NAME" slogn="Your slogan" imgSrc="MaskGroup_44.svg" />
                        </div>
                        <div class="row">
                            <clientLogo name="COMPANY NAME" slogn="Your slogan" imgSrc="MaskGroup_37.svg" />
                            <clientLogo name="COMPANY NAME" slogn="Your slogan" imgSrc="MaskGroup_39.svg" />
                            <clientLogo name="COMPANY NAME" slogn="Your slogan" imgSrc="MaskGroup_38.svg" />
                        </div>
                    </div>
                    <div class="col-xxl-6 col-5 d-flex align-items-end text-start">
                        <img src="../../assets/img/agents.svg" class="w-xxl-75 mx-auto" alt="">
                    </div>
                </div>
                <img src="../../assets/img/shadow.svg" class="position-absolute b-0 l-0 r-0  z-1" alt="">

            </div>
        </div>
    </section>
</template>
<script>
import SecTitle from '../items/shared/SecTitle.vue';
import clientLogo from '../items/clients/clientLogo.vue';
export default {
    name: 'Clients',
    components: {
        SecTitle,
        clientLogo
    }
}
</script>