<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :class="[{ 'animate__animated animate__fadeIn': mobile },{'desktop':!mobile},{'tablet':tablet}]"
    id="liveExperience"
    tabindex="-1"
    aria-labelledby="liveExperienceLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen bg-dark-blue">
      <div class="modal-content bg-dark-blue">
        <button
          type="button"
          class="btn-close z-2 abc"
          data-bs-dismiss="modal"
          aria-label="Close"
          
          :class="demoOn && mobile  ? 'd-none' : ''"
        ></button>
        <!-- <img src="../../assets/img/x.svg" class="position-absolute l-16" alt=""> -->
        <div class="modal-body" :class="{ 'p-0 w-100vw h-100vh': mobile }">
          <div class="container-fluid h-100">
            <div class="row mx-0 h-100">
              <div class="col-lg-11 col-12 mx-auto">
                <div class="row mx-0 h-100">
                  <div
                    class="col-lg-8 col-12 d-flex flex-mobile-column flex-tablet-column"
                    :class="
                      mobile
                        ? (isReservation || isSales) &&
                          (isMessanger || isTelegram)
                          ? 'animate__animated animate__fadeOutLeft'
                          : changeAnimationdirection
                          ? 'animate__animated animate__fadeInLeft'
                          : 'animate__animated animate__fadeInRight'
                        : ''
                    "
                    v-if="!isLaunch || !mobile"
                  >
                    <div
                      class="text-center d-flex flex-column justify-content-center"
                      :class="mobile ? 'w-100' : 'w-45'"
                    >
                      <p class="f-70 basic-bold white mb-md-3 mb-0 mb-lg-0">
                        يا أهلاً بك
                      </p>
                      <p class="white f-40 basic-SemiBold mb-lg-3 mb-md-5 mb-3">
                        اختر المنصة المطلوبة
                      </p>
                      <div
                        class="d-flex align-items-center justify-content-center platform-buttons mb-lg-3 mb-md-5 mb-3"
                      >
                        <div
                          class="type-button radio-button telegram text-center dim-120 d-flex flex-column justify-content-center align-items-center ms-5"
                          :class="{ active: isTelegram }"
                          @click="
                            {
                              isTelegram = !isTelegram;
                              isMessanger = false;
                            }
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 72.237 59.592"
                          >
                            <path
                              id="Path_11249"
                              data-name="Path 11249"
                              d="M408.93,1034.24a1.541,1.541,0,0,0-1.051-1.145,5.4,5.4,0,0,0-2.843.2s-63.294,22.75-66.909,25.269c-.779.543-1.039.858-1.169,1.228-.625,1.794,1.322,2.585,1.322,2.585l16.314,5.315a1.778,1.778,0,0,0,.825-.049c3.711-2.345,37.338-23.579,39.278-24.293.307-.091.533.014.473.224-.777,2.724-29.984,28.685-29.984,28.685a1.43,1.43,0,0,0-.182.3l-.038-.023-1.522,16.181s-.638,4.957,4.319,0c3.5-3.5,6.882-6.423,8.575-7.848,5.609,3.872,11.646,8.157,14.251,10.4a4.523,4.523,0,0,0,3.3,1.274c2.473-.092,3.164-2.807,3.164-2.807s11.534-46.423,11.919-52.642c.038-.609.089-1,.094-1.416A5.241,5.241,0,0,0,408.93,1034.24Z"
                              transform="translate(-336.835 -1032.957)"
                              fill="#1b324c"
                            />
                          </svg>
                          <p class="mb-0 f-18 basic-SemiBold dark-blue">
                            متجر تلغرام
                          </p>
                        </div>
                        <div
                          class="type-button radio-button messanger text-center dim-120 d-flex flex-column justify-content-center align-items-center"
                          :class="{ active: isMessanger }"
                          @click="
                            {
                              isMessanger = !isMessanger;
                              isTelegram = false;
                            }
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 60.591 60.592"
                          >
                            <path
                              id="Subtraction_35"
                              data-name="Subtraction 35"
                              d="M6089.954-328.408a2.373,2.373,0,0,1-2.381-2.314l-.163-5.3a2.389,2.389,0,0,0-.8-1.7,28.244,28.244,0,0,1-9.356-21.377,28.369,28.369,0,0,1,8.6-20.679A30.286,30.286,0,0,1,6107.048-388a30.286,30.286,0,0,1,21.192,8.223,28.37,28.37,0,0,1,8.6,20.679,28.371,28.371,0,0,1-8.6,20.678,30.287,30.287,0,0,1-21.192,8.224,32.536,32.536,0,0,1-8.625-1.146,2.391,2.391,0,0,0-.632-.085,2.363,2.363,0,0,0-.96.2l-5.914,2.611A2.371,2.371,0,0,1,6089.954-328.408Zm11.74-38.211a4.454,4.454,0,0,0-3.787,2.086l-8.751,13.887a1.361,1.361,0,0,0-.058,1.419,1.465,1.465,0,0,0,1.258.763,1.408,1.408,0,0,0,.853-.3l9.4-7.135a1.778,1.778,0,0,1,1.082-.363,1.77,1.77,0,0,1,1.072.357l6.961,5.221a4.429,4.429,0,0,0,2.676.894,4.451,4.451,0,0,0,3.786-2.086l8.753-13.886a1.363,1.363,0,0,0,.057-1.421,1.466,1.466,0,0,0-1.257-.762,1.409,1.409,0,0,0-.853.3l-9.4,7.135a1.778,1.778,0,0,1-1.082.363,1.77,1.77,0,0,1-1.072-.357l-6.961-5.221A4.433,4.433,0,0,0,6101.694-366.619Z"
                              transform="translate(-6076.752 388.5)"
                              fill="#1b324c"
                              stroke="rgba(0,0,0,0)"
                              stroke-miterlimit="10"
                              stroke-width="1"
                            />
                          </svg>
                          <p class="mb-0 f-18 basic-SemiBold dark-blue">
                            متجر مسنجر
                          </p>
                        </div>
                      </div>
                      <p class="white f-40 basic-SemiBold mb-lg-3 mb-md-5 mb-3">
                        اختر الخدمة المطلوبة
                      </p>
                      <div
                        class="services-button d-flex flex-column justify-content-center align-items-center"
                      >
                        <div
                          class="sevice-button radio-button text-center d-flex flex-column h-66px w-400px justify-content-center align-items-center f-30 basic-SemiBold mb-lg-3 mb-md-5 mb-3 dark-blue"
                          :class="{ active: isReservation }"
                          @click="
                            {
                              isReservation = !isReservation;
                              isSales = false;
                            }
                          "
                        >
                          الحجوزات والمواعيد
                        </div>
                        <div
                          class="sevice-button radio-button text-center d-flex flex-column h-66px w-400px justify-content-center align-items-center f-30 basic-SemiBold dark-blue"
                          :class="{ active: isSales }"
                          @click="
                            {
                              isSales = !isSales;
                              isReservation = false;
                            }
                          "
                        >
                          بيع المنتجات
                        </div>
                      </div>
                    </div>
                    <div
                      class="w-55 d-flex justify-content-end flex-column position-relative"
                      v-if="!mobile"
                    >
                    <Loader v-if="isLoader"/>
                      <img
                        v-if="this.image"
                        :key="this.image.id"
                        :src="
                          require(`@/assets/img/demos/salesTelegram/${this.image.src}`)
                        "
                        alt="ecart service demo"
                        v-on:click="this.changeImg"
                        id="demo-mage-container"
                        class="image"
                      />
                      <!-- <SalesTelegram/> -->
                    </div>
                    <img
                      :src="
                        require(`@/assets/img/${
                          tablet
                            ? 'reset-password-girl-t.svg'
                            : 'reset-password-girl-r.svg'
                        }`)
                      "
                      class="mt-4 mt-md-5"
                      :class="{'w-100 px-3':tablet}"
                      alt="demo"
                      v-else
                    />
                  </div>
                  <div
                    class="position-absolute l-0 r-0 t-0 b-0 d-flex align-items-center align-items-md-end justify-content-center"
                    :class="
                      isLaunch
                        ? 'animate__animated animate__fadeIn'
                        : 'animate__animated animate__fadeOutLeft'
                    "
                    v-if="mobile"
                  >
                    <img :src="require(`@/assets/img/${
                          this.tablet
                            ? 'live-exp-char-t.svg'
                            : 'live-exp-char-r.svg'
                        }`)" class="mx-auto" :class="mobile&&!tablet ? 'w-90':{'w-100 px-3':tablet}" alt="شخصية إي كارت" />
                    <p class="mb-0 basic-light white f-sm-18 position-absolute w-50 l-5 b-27">
                      هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربي،  
                    </p>
                  </div>
                  <div
                    class="l-0 r-0 px-0 position-absolute t-0 b-0"
                    :class="[
                      (isReservation || isSales) && (isMessanger || isTelegram)
                        ? 'animate__animated animate__fadeInRight'
                        : 'animate__animated animate__fadeOutRight',
                      {
                        'd-flex align-items-end justify-content-center':
                          tablet,
                      },
                    ]"
                    v-if="
                      (isReservation || isSales) &&
                      (isMessanger || isTelegram) &&
                      mobile
                    "
                  >
                    <img
                      class="mb-auto position-fixed z-4 l-0 r-0 mx-auto"
                      :src="
                        require('@/assets/img/demos/salesTelegram/header.svg')
                      "
                      alt="تلغرام"
                      v-if="mobile && !tablet"
                    />
                    <img
                      :src="require('@/assets/img/demos/salesTelegram/x.svg')"
                      alt="إغلاق"
                      class="position-absolute z-4 r-0 pointer dim-24"
                      @click="this.subClear() ,changeAnimationdir()"
                      v-if="mobile"
                    />
                    <img
                      :key="this.image.id"
                      v-if="tablet && this.image"
                      :src="
                        require(`@/assets/img/demos/salesTelegram/${this.image.src}`)
                      "
                      alt="ecart service demo"
                      v-on:click="this.changeImg"
                      id="demo-mage-container"
                      class="image h-90"
                    />
                    <Loader v-if="isLoader&&tablet" />
                    <div
                      v-if="mobile && !tablet && this.image"
                      v-on:click="this.changeImg"
                      id="demo-mage-container"
                      class="h-100 w-100 background-property"
                      v-bind:style="{
                        'background-image':
                          'url(' +
                          require(`@/assets/img/demos/salesTelegram/${
                            mobile && !tablet
                              ? 'mobile-' + this.image.src
                              : this.image.src
                          }`) +
                          ')',
                      }"
                    >
                      <Loader v-if="isLoader" />
                    </div>
                  </div>
                  <div
                    class="col-4 flex-column justify-content-center"
                    :class="mobile ? 'd-none' : 'd-flex'"
                  >
                    <p class="f-50 basic-bold white mb-4">
                      هيا لتعيش تجربة متجرك الالكتروني مع إي كارت
                    </p>
                    <p class="f-28 basic-light white mb-0 line-height-17">
                      هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                      توليد هذا النص من مولد النص العربي، حيث يمكنك أن تولد مثل
                      هذا النص أو العديد من النصوص الأخرى
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import responsiveMixin from "@/mixins/responsiveMixin";
import SalesTelegram from "../items/svg/sales-telegram.vue";
import Loader from "../sections/loader.vue";

export default {
  mixins: [responsiveMixin],
  data() {
    return {
      duration : '3000',
      timeOut: null ,
      launch: true,
      isTelegram: false,
      isMessanger: false,
      isSales: false,
      isReservation: false,
      loader: false,
      changeAnimationdirection: false,
      index: 0,
      image: null,
      placeHolder: "2",
      images: [
        {
          id: 1,
          src: "2.png",
        },
        {
          id: 2,
          src: "3.png",
        },
        {
          id: 3,
          src: "4.png",
        },
        {
          id: 4,
          src: "5.png",
        },
        {
          id: 5,
          src: "6.png",
        },
        {
          id: 6,
          src: "7.png",
        },
        {
          id: 7,
          src: "8.png",
        },
        {
          id: 8,
          src: "9.png",
        },
        {
          id: 9,
          src: "10.png",
        },
      ],
    };
  },
  components: { SalesTelegram, Loader },
  mounted() {
    var here = this;
    var myModalEl = document.getElementById("liveExperience");
    
    myModalEl.addEventListener("shown.bs.modal", function (event) {
      here.timeOut = setTimeout(function () {
        here.launch = false;
      }, here.duration);
    });
    myModalEl.addEventListener("hidden.bs.modal", function (event) {
      here.clear();
    });
    this.changeImg();
  },
  methods: {
    changeImg() {
      var prefix = this.mobile ? "mobile-" : "";
      if (this.isTelegram && this.isSales && this.loader == false) {
        this.loader = true;
        var here = this;
        var img = new Image();
        img.src = require(`@/assets/img/demos/salesTelegram/${
          prefix + this.images[this.index].src
        }`);
        img.onload = function () {
          here.image = here.images[here.index];
          here.index = (here.index + 1) % here.images.length;
          here.loader = false;
        };
      } else {
        this.image = this.images[0];
        this.index = 1;
        this.loader = false;
      }
    },
    clear() {
      this.subClear();
      this.launch = true;
      this.changeAnimationdirection = false;
      clearTimeout(this.timeOut)
    },
    subClear() {
      (this.isTelegram = false),
        (this.isMessanger = false),
        (this.isSales = false),
        (this.isReservation = false),
        (this.image = this.images[0]);
      this.index = 1;
    },
    changeAnimationdir() {
      this.changeAnimationdirection = true;
    },
  },
  computed: {
    isLaunch() {
      return this.launch;
    },
    isLoader() {
      return this.loader;
    },
    demoOn() {
      return (
        (this.isReservation || this.isSales) &&
        (this.isMessanger || this.isTelegram)
      );
    },
  },
};
</script>
<style scoped>
.h-90 {
  height: 90%;
}
.btn-close:focus {
  box-shadow: 0 0 0 0.25rem rgb(108 108 108 / 0%);
}

.image {
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.dim-24 {
  width: 24px;
  top: 3%;
  right: 5%;
}

.image:hover {
  transition: all 0.3s ease-in;
}

.w-55 {
  width: 55%;
}

.w-45 {
  width: 45%;
}

.l-5{
  left: 5%;
}
.b-27{
  bottom: 27%;
}
.background-property {
  background-size: 100%;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #ccddf1;
  background-position-x: center;
}

.radio-button.type-button {
  border-radius: 0px 15px 15px 15px;
}

.radio-button.sevice-button {
  border-radius: 15px 15px 15px 15px;
}

.radio-button {
  background-color: white;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.radio-button.active {
  background-color: #e95c29;
  color: white;
  transition: all 0.1s ease-in-out;
}

.radio-button svg {
  height: 46%;
  margin-bottom: 10%;
}

.btn-close,
.btn-close:hover {
  color: #1c324c;
  border: 2px solid #1c324c;
  background: transparent url("../../assets/img/x.svg") center/1em auto
    no-repeat;
  position: absolute;
  text-decoration: none;
  background-color: white;
  opacity: 1;
  border-radius: 0px 15px 15px 15px;
  padding: 1%;
  margin-top: 4%;
  left: 4%;
}

.radio-button.active.telegram #Path_11249,
.radio-button.active.telegram p {
  fill: white;
  color: white;
  transition: all 0.1s ease-in-out;
}

.radio-button.active.messanger #Subtraction_35,
.radio-button.active.messanger p {
  fill: white;
  color: white;
  transition: all 0.1s ease-in-out;
}
@media (min-width: 2200px) {

  .l-16 {
    left: 20%;
  }
}
</style>