<template>
    <div class="mobile w-100" v-if="mobile">
    <div class="mobile-service d-flex flex-column align-items-center">
      <img :src="require(`@/assets/img/${imgSrc}`)" :alt="name" class="h-225px mb-4"/>
      <p class="white title basic-black mb-1">{{ name }}</p>
        <div class="text-center w-100">
          <p class="white f-22 basic-SemiBold mb-xxl-3 mb-2">{{ shortDesc }}</p>
          <div :class="{'d-flex justify-content-center':tablet}">
            <router-link
                :to="{ name: 'subscribe' }"
                class="btn btn-primary  min-height-55px  py-xxl-2 py-1 basic-bold f-18 d-flex justify-content-center align-items-center"
                :class="{'w-75 mx-auto':!tablet , 'ms-2':tablet}">اشترك الآن</router-link
              >   
          <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#liveExperience"
                class="btn btn-primary min-height-55px custom py-xxl-2 py-1 h-fit-content basic-bold f-185 "
                :class="{'mt-3  w-75 mx-auto':!tablet}"
              >
                عش التجربة
              </button>
          </div>
          
           
        </div>
    </div>
  </div>
  <div class="desktop" v-else>
    <div
      :class="'service d-flex align-items-center w-fit-content' + [styleClass]"
      :id="id"
      v-if="id != ''"
    >
      <img
        :src="require(`@/assets/img/${imgSrc}`)"
        :alt="name"
        class="ms-xxl-5 ms-3"
      />
      <div
        class="d-flex flex-column align-items-start justify-content-center w-fit-content min-height-18vh"
      >
        <p class="white title basic-black mb-0">{{ name }}</p>
        <div class="additional-info text-end w-100">
          <p class="white f-22 basic-SemiBold mb-xxl-3 mb-1">{{ shortDesc }}</p>
          <div class="d-flex w-100 flex-row justify-content-between">
            <div class="d-flex">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#liveExperience"
                class="btn btn-primary py-xxl-2 py-1 h-fit-content basic-bold f-18 w-min-158px"
              >
                عش التجربة
              </button>
              <router-link
                :to="{ name: 'subscribe' }"
                class="btn btn-primary py-xxl-2 py-1 basic-bold f-18 w-min-158px d-flex justify-content-center align-items-center me-xxl-4 me-2"
                >اشترك الآن</router-link
              >
            </div>
            <!-- <OptionsIcons/> -->
          </div>
        </div>
      </div>
    </div>

    <div
      :class="'service row mx-0 d-flex align-items-center ' + [styleClass]"
      v-else
    >
      <div class="col-6 text-center">
        <img :src="require(`@/assets/img/${imgSrc}`)" :alt="name" class="mx-auto" />
      </div>
      <div class="col-6">
        <div class="w-75 mx-auto">
          <p class="white f-60 basic-black mb-2">{{ name }}</p>
          <p class="white f-28 basic-light mb-2 line-height-17">
            {{ shortDesc }}
          </p>
          <div class="d-flex">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#liveExperience"
              class="btn btn-primary py-xxl-2 py-1 h-fit-content basic-bold f-18 w-min-158px"
            >
              عش التجربة
            </button>
            <router-link
              :to="{ name: 'subscribe' }"
              class="btn btn-primary py-xxl-2 py-1 h-fit-content basic-bold f-18 w-min-158px d-flex justify-content-center align-items-center mx-xxl-4 mx-2"
              >اشترك الآن</router-link
            >

            <OptionsIcons />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import responsiveMixin from '@/mixins/responsiveMixin';
import OptionsIcons from "../shared/OptionsIcons.vue";
import SmallButton from "../shared/SmallButton.vue";

export default {
  name: "service",
  mixins:[responsiveMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    shortDesc: {
      type: String,
      default: "",
    },
    styleClass: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: "service_1.svg",
    },
  },
  components: {
    SmallButton,
    OptionsIcons,
  },
};
</script>
<style scoped>
.min-height-18vh {
  min-height: 18vh;
}
.additional-info {
  max-height: 0px !important;
  overflow: hidden !important;
  transition: max-height 0.6s ease-in-out;
}
.service:hover .additional-info {
  max-height: 18vh !important;
  transition: max-height 0.6s ease-in-out;
}
.service {
    font-size: 30px;
    transition: all 0.6s ease-in-out;
  }

  .service:hover {
    font-size: 20px;
    transition: all 0.6s ease-in-out;
  }
.service {
  font-size: 50px;
  transition: all 0.6s ease-in-out;
  cursor: pointer;
}
.service:hover {
  font-size: 40px;
  transition: all 0.6s ease-in-out;
}
.w-min-158px {
  min-width: 158px;
}

@media (max-width: 1600px) {
  .w-min-158px {
    min-width: 122px;
  }
  .service {
    font-size: 30px;
    transition: all 0.6s ease-in-out;
  }
  .service:hover {
    font-size: 20px;
    transition: all 0.6s ease-in-out;
  }
}
</style>